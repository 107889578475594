import React, { useState, useEffect } from 'react'
import ScreenCard from '../Cards/ScreenCard'
import { Form, FormControl, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import c from './login.module.css'
import { ArrowRightIcon } from '../Icons'
//import { motion } from 'framer-motion/dist/framer-motion';
import { motion} from 'framer-motion/dist/framer-motion'
//import {AnimatePresence} from 'framer-motion'
import { useNavigate } from "react-router-dom";
import { resetPassword } from '../../Functions';
import AlertDangerRound from '../Modal/AlertDangerRound';

const RestorePassword = () => {

    
    const [email, setEmail] = useState('');    
    const [submitBlocked, setSubmitBlocked] = useState(true)
    const [shiftScreens, setShiftScreens] = useState(true)
    const [loader, setLoader] = useState(false)
    const [serverRes, setServerRes] = useState('')
    const [error, setError] = useState('')

    const navigate = useNavigate();

    useEffect(() => {
        if(email) {
            setSubmitBlocked(false)
        } else {
            setSubmitBlocked(true)
        }
        // eslint-disable-next-line 
     },[email])

     useEffect(() => {      
        if(serverRes === 201) {
            setLoader(false)
            setShiftScreens(prev => !prev) 
            setEmail('') 
        } else if(serverRes === 500) {
            setLoader(false)
            setError('Server Error. Please check your internet connection')
            setShiftScreens(prev => !prev)
        } else if(serverRes === 'Cannot read properties of undefined (reading \'json\')') {
            setLoader(false)
            setError('Server Error. Please check your internet connection')
            setShiftScreens(prev => !prev)
        } else if(serverRes) {
            setLoader(false)
            setError(serverRes)
            setShiftScreens(prev => !prev)
        }
        // eslint-disable-next-line 
     },[serverRes])


     const handleFormSubmit = () => {// Add actions to pass data to the backend
        let emailObj = {
            email
        }
         setSubmitBlocked(true)
        setLoader(true)
        resetPassword(emailObj, setServerRes)        
      
        // Later, Clear input fields when New User Created Confirmation will be rcvd
     }

  return (
    <ScreenCard >
     <div className={c.login_wrapper}>
        <div className={c.header_txt}>
            Password Recovery
        </div>
    {shiftScreens &&
    <>
        <div className={c.txt_box}>
            Please enter your email address and we will send you a password recovery link.
        </div>

   
     <motion.div 
            className={c.inputs_section}
            key='div_1'
            animate={{x:"0%", y: "0%", opacity: 1, scale: 1}}
            initial={{ x:"-100%", y: "0%", opacity: 0, scale: 1}}
            exit={{x:"100%", y: "0%", transition: { duration: 0.8}}}
            transition={{ duration: 0.8, ease: "easeOut"}}
     >
        <Form.Group className={`${c.form_group} ${c.mgn_btm}`}>
            <Form.Label className={c.lable_txt}>
            User email
            </Form.Label>
            <FormControl 
                placeholder="email" 
                name="email"
                value={email} 
                type="email"
                className={c.form_input}
                onChange={(event) => {setEmail(event.target.value)}}
            />
        </Form.Group>

       <Button variant='success' 
               className={c.login_btn} 
               disabled={submitBlocked}
               onClick={handleFormSubmit}
        >
           {!loader ? 'Send a Request' : <Spinner animation="border" variant="secondary" size="md" />}
       </Button>
    </motion.div>
  </> 
    }
  

 {!shiftScreens &&
     <motion.div 
            className={c.inputs_section__screen_two}
            key='div_2'
            initial={{ x:"-100%", y: "0%", opacity: 0, scale: 1}}
            animate={{x:"0%", y: "0%", opacity: 1, scale: 1}}
          exit={{x:"100%", y: "0%", transition: { duration: 0.8}}}
            transition={{ duration: 0.8, ease: "easeOut"}}
     >
    {!error ? (
        <>
            <div className='my-5 '>
                Your request Has Been Sent. <br/>
                Please, Check your email.
             </div>

            <Button variant='success' 
                    className={c.login_btn} 
                    // disabled={submitBlocked}
                    onClick={() => navigate('/login')}
                >
                    Go to Login
            </Button>
        </> ) : (
            <div className={c.alert}>
             <AlertDangerRound triger={error} alertHeader={error} />
            </div>
        )}
    </motion.div> 
    } 

    <div className={c.horiz_line} />

     <div className={c.login_lowerblock}>
         <div>
            <div className={c.lable_txt}>
            Remember the Password?
            </div>
            <motion.div
                className={c.link}
                whileHover={{
                originX: 0,
                scale: 1.03,
                textShadow: '0px 0px 2px rgb(255,255,255)',
                color: '#f8e112',                        
                }}
                onClick={() => navigate('/login')}
                transition={{ type: 'spring', stiffness: 300 }}               
            >
                Login into your account  <span className='ml-3'>{ArrowRightIcon()}</span>
            </motion.div>

         </div>

         <div>
             <div className={c.lable_txt}>
              Do not have an account yet?
            </div>
            <motion.div
                className={c.link}
                whileHover={{
                originX: 0,
                scale: 1.03,
                textShadow: '0px 0px 2px rgb(255,255,255)',
                color: '#f8e112',                        
                }}
                onClick={() => navigate('/new_user')}
                transition={{ type: 'spring', stiffness: 300 }}
            >
                Register a new User  <span className='ml-3'>{ArrowRightIcon()}</span>
            </motion.div>
            <div className={`${c.link} ${c.pdg_btm}`}>
                Why to Register?
            </div>
         </div>
        
       
      </div> 

 </div>
 
</ScreenCard>
  )
}

export default RestorePassword