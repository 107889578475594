import { createSlice } from "@reduxjs/toolkit";

const soundOff = localStorage.getItem('soundOff')
console.log(soundOff)
const initialState = {
    soundOn: soundOff === 'yes' ? false : true,
}

export const soundSlice = createSlice({
    name: 'sound',
    initialState,
    reducers: {
        toggleSound: (state) => {
            state.soundOn = state.soundOn === true ? false : true;
           localStorage.setItem('soundOff', state.soundOn === true ? 'no' : 'yes' );
        },
        // update: (state, action) => {
        //     state.soundOff = action.payload.sound;
        //    // localStorage.setItem('soundOff', action.payload.sound);
        // },
    },
});

export const { toggleSound } = soundSlice.actions;
export default soundSlice.reducer;