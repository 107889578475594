import React from 'react';
import c from './rotateLtr.module.css';
import './rotateLtr.css'
import { nanoid } from '@reduxjs/toolkit';
import useBg from '../../Hooks/useBg';


const CircularText = ({text, cla}) => {
  
 const { starColr, yellowTxt } = useBg();

  return (

   <div className={cla}>
    {text.map((ltr, index) => 
    <div className={c.rotate} key={nanoid()}>
      <span key={nanoid()} className={`char${index + 1} `}>
        {ltr}
      </span>    
    </div>      
    )}    
   </div>
  )
}

export default CircularText