import { Form, FormControl } from 'react-bootstrap';
import c from './Form.module.css';

import { motion } from 'framer-motion/dist/framer-motion';

const MailScreen1 = (props) => {

    const { name, setName, email, setEmail, message, setMessage } = props;

  return (
    <motion.div
        key='screen_1'
        animate={{x:"0%", y: "0%", opacity: 1, scale: 1}}
        initial={{ x:"-100%", y: "0%", opacity: 0, scale: 1}}
        exit={{x:"100%", y: "0%", transition: { duration: 0.8}}}
        transition={{ duration: 0.8, ease: "easeOut"}}
        className={c.screen_box}
    >
            <Form.Group className={`${c.rel_div} ${c.form_group}`}>                
                <Form.Label className={c.lable_txt}>
                  Your Name
                </Form.Label>
                <FormControl 
                    placeholder="Your Name" 
                    name="name"                 
                    value={name} 
                    type="text"
                    className={c.form_input}
                    onChange={(event) => {setName(event.target.value)}}
                    required
                /> 
            </Form.Group>

            <Form.Group className={c.form_group}>            
                <Form.Label className={c.lable_txt}>
                 Contact Email:
                </Form.Label>
                <FormControl 
                    placeholder="email" 
                    name="email"
                    value={email} 
                    type="email"
                    className={c.form_input}
                    onChange={(event) => {setEmail(event.target.value)}}
                />
            </Form.Group>

            <Form.Group className={c.form_group}>            
                <Form.Label className={c.lable_txt}>
                 Message:
                </Form.Label>
                <FormControl 
                    placeholder="Message:" 
                    name="message"
                    value={message} 
                    as="textarea" 
                    rows={3}
                    type="text"
                    className={c.form_input}
                    onChange={(event) => {setMessage(event.target.value)}}
                />
            </Form.Group>
    </motion.div>
  )
}

export default MailScreen1