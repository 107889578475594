import React, {useContext} from 'react'
import { Context } from '../../Lang/LanguageProvider';
import  { useDispatch } from 'react-redux'
import { toggleTriger } from '../../Redux/trigerSlice'
import c from '../../views/home_screen.module.css'

const LangSelectHomeScreen = () => {

    const dispatch = useDispatch()
    const context = useContext(Context); 

    const toggleLang = value => {
      context.selectLangString(value)
      dispatch(toggleTriger(value))
    }

  return (
    <div className={c.abs_lang_select}>
       <div className={c.flags_container}>
            <div className={c.flag_item1} onClick={() => toggleLang('en')}/>
            <div className={c.flag_item2} onClick={() => toggleLang('ua')} />
            <div className={c.flag_item3} onClick={() => toggleLang('ar')}/>
        </div>
    </div>
  )
}

export default LangSelectHomeScreen