import { useState } from 'react';



const useUnPause = () => {
    const [paused, setPaused] = useState(false);

    // const handleUnPause = (cursPos, setTimeCount) => {
    //   setPaused(false);      
    //   cursPos > 0 && setTimeCount(true);           
    // };
    const handleUnPause = () => {
      setPaused(false);     
                 
    };
  
    return {
      paused,     
      handleUnPause,
      setPaused
    };

};
export default useUnPause;