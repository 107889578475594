import React from 'react';
import GoHome from './GoHome';
import Settings from './Settings';
import PageTitle from '../../Components/PageTitle';
import SoundControll from './SoundControll';
//import UserIcon from './UserIcon';
import { useLocation } from 'react-router-dom'
//import LoginBtn from './Login/LoginBtn';
import LangSelectHomeScreen from './LangSelectHomeScreen';
import Logo from './Logo';
import k from './settings.module.css'
//import LogOut from './Login/LogOut';
//import useAuth from '../Hooks/useAuth'

import  { useSelector } from 'react-redux'
//import MailButton from './Mail/MailButton';
import RepeatIcon from './RepeatIcon';
import GraphIcon from './GraphIcon';
import AllPowersIcon from './AllPowers';
import AllUsersIcon from './AllUsers';


const PageRegControllers = ( ) => {

  const pageHeader = useSelector((state) => state.pageHeader.pageHeader)
  const rpt = useSelector((state) => state.rpt.rpt)
  const { pathname } = useLocation()
  const noHomeIcon = ['/', '/training', '/exercise_rpt']
  const noSoundIcon = ['/all_lessons', 
                    //   '/'
                      ]
  const settingsDisplayNone = ['/exercise_completed', '/exercise_repited']
  const reDisplayNone = ['/users', '/exercise_rpt', '/exercise_completed', '/exercise_repited', '/all_lessons', '/training']
  const langIcons = ['/']

// const { username, 
//         status, 
//        roles
//       }  = useAuth()
//const username = 'DenysGl'
 
  return (
    <>
      <PageTitle
       title={`NO Typo | ${pageHeader}`}
      />      
      {/* {langIcons.includes(pathname) ? (!username ? <LoginBtn /> : <UserIcon user={username} />) : ('') } */}
      {noHomeIcon.includes(pathname) ? null : <GoHome />}      
      {noSoundIcon.includes(pathname) ? null : <SoundControll />}
      {/* {langIcons.includes(pathname) ? <MailButton /> : ''} */}
      {rpt > 0 ? (reDisplayNone.includes(pathname) ? null : <RepeatIcon cla={k.re_icon}/>) : (null)} 
      {settingsDisplayNone.includes(pathname) ? null : <Settings/>} 
      {langIcons.includes(pathname) ? (
                      <>
                          <LangSelectHomeScreen />
                          <AllPowersIcon cla={k.pwr_icon} />
                          <GraphIcon cla={k.graph_icon}  />
                          <AllUsersIcon />
                      </>
                          ) : null}
      {/* <Logo /> */}
    </>
  )
}

export default PageRegControllers    
//{/* {!atHome ? (!noHome && <GoHome />) : <UserIcon />} */}