
import c from '../views/post_lesson.module.css';
import { useNavigate } from "react-router-dom";
import { PwrIcon, GraphUpIcon, ReIcon, KeyBoardIcon } from '../Components/Icons'
import useBg from '../Hooks/useBg';
import { useMemo } from 'react';
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/languages/messages";


const AllLessonsLeftSide = () => {

    const navigate = useNavigate();

    

    const { yellowTxt: yellowTxtValue, starColr: starColrValue } = useBg();
    const yellowTxtVal = useMemo(() => yellowTxtValue, [yellowTxtValue])
    const starColrVal  = useMemo(() => starColrValue, [starColrValue])
    const active = localStorage.getItem("AllLessDirection")

    const lessonExercPassed = localStorage.getItem("lessonExcercPassed");

    const link = (data, AllLessDirection) => {   
        localStorage.setItem("AllLessDirection", AllLessDirection);
        navigate(data);
      };
    const linkToHighest = (data, load) => {
        localStorage.setItem(
          "exerciseCount",
          parseInt(lessonExercPassed?.slice(1, 3))
        );
        localStorage.setItem("lessonCount", lessonExercPassed?.slice(0, 1));
        localStorage.setItem("lessonComplete", "no");
        localStorage.setItem("toHighest", load);
        navigate(data);
      };

  return (
    <div className={` ${c.left_col} ${yellowTxtVal}`}>  

      <div className={`${c.leftside_link_tab} ${active === "AllLess" && c.active_tab}`} onClick={() => link(`/all_lessons`, "AllLess")}>
        <div>
          <FormattedMessage id='allSuperPowers' defaultMessage={defineMessages.allSuperPowers}/>          
        </div>
        <div>
          {PwrIcon(42, `${starColrVal}`)} 
        </div>
            
      </div>

      <div className={`${c.leftside_link_tab} ${active === "Graph" && c.active_tab}`} onClick={() => link(`/all_lessons`, "Graph")}>
        <div>
        <FormattedMessage id='analitics' defaultMessage={defineMessages.analitics}/>     
        </div>
        <div>
            {GraphUpIcon(42, `${starColrVal}`)}
        </div>
       
      </div>

      <div className={`${c.leftside_link_tab} ${active === "HUB" && c.active_tab}`} onClick={() => link(`/all_lessons`, "HUB")}>
        <div>
          <FormattedMessage id='reTrain' defaultMessage={defineMessages.reTrain}/>
        </div>
        <div>
            {ReIcon(42, `${starColrVal}`)}
        </div>        
      </div>

      <div className={`${c.leftside_link_tab} `} 
       onClick={() => linkToHighest(`/training`, "yes")}
        >
        <div>
           <FormattedMessage id='headerStartBtn2' defaultMessage={defineMessages.headerStartBtn2}/>
        </div>
        <div>
            {KeyBoardIcon(48, `${starColrVal}`)}
        </div>        
      </div>

    </div> 
  )
}

export default AllLessonsLeftSide