import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';



import English from './languages/en.json';
import Ukrainian from './languages/ua.json';
import Arabian from './languages/ar.json';


export const Context = React.createContext();

const local = localStorage.getItem('lang') || 'en';
  let lang;
  if (local === 'en') {
    lang = English;
  } else if (local === 'ua') {
    lang = Ukrainian;
  } else if (local === 'ar') {
    lang = Arabian;
  }

 

export default function LanguageProvider(props) {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);

  function selectLang(e) {
    const newLocale = e.target.value;
    setLocale(newLocale);
    if (newLocale ==="en") {
      setMessages(English);
    } else if (newLocale ==="ua") {
      setMessages(Ukrainian);
    } else if (newLocale ==="ar") {
      setMessages(Arabian);
    } 
    localStorage.setItem( 'lang', newLocale);
  }

  function selectLangString(string) {
    const newLocale = string;
    setLocale(newLocale);
    if (newLocale ==="en") {
      setMessages(English);
    } else if (newLocale ==="ua") {
      setMessages(Ukrainian);
    } else if (newLocale ==="ar") {
      setMessages(Arabian);
    } 
    localStorage.setItem( 'lang', newLocale);
  }

  return (
    <Context.Provider value={{ locale, selectLang, selectLangString }}>
      <IntlProvider locale={locale} messages={messages}>
        <>
          {props.children}      
        </>
      </IntlProvider>
    </Context.Provider>
    
    
  )
}

 



