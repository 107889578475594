import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    layout: 'Lat'
}

export const keyBoardLayoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        toggleLayout: (state, action) => {
            state.layout = action.payload;
          // localStorage.setItem('bg', action.payload);
        },
        // update: (state, action) => {
        //     state.soundOff = action.payload.sound;
        //    // localStorage.setItem('soundOff', action.payload.sound);
        // },
    },
});
 
export const { toggleLayout } = keyBoardLayoutSlice.actions;
export default keyBoardLayoutSlice.reducer;