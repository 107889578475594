import React, {useState, useEffect, useContext } from 'react';
import PreLessonNotification from '../views/PreLessonNotification';
import TrainingScreen from '../views/TrainingScreen';
import { useNavigate } from "react-router-dom";
import { ExercContext } from '../Context/ExercContext';
import { 
        lessonHeader, 
        exerciseDesc, 
        lessonHeaderEN, 
        exerciseDescEN,
        lessonHeaderAR, 
        exerciseDescAR ,
 //       AllLessons
      } from '../models/Tasks';
import { checkLang, checkLangForMsg } from '../Functions';
import useUnPause from '../Hooks/useUnPause';
import  { 
  useSelector,
  useDispatch 
} from 'react-redux'
//import {lettersRpt} from '../models/TasksContent'

const TextInputController = () => {

  // let len = lettersRpt[0].exerc.length
  // console.log(+lettersRpt[0].exerc[len - 1].id)
  
  // const calcFunc = (arr, ltr) => {
  //   for(let i = 1; i < 11; i++) {
  //   let acc = []
  //   let num = arr[i - 1].filter(item => item === ltr)
  //   acc.push({id:i, rpt:num.length})
  //   return acc   }
  // }

  // const lessonNum = 10;
  // const ltr = 'u'
  
  // const sumOfJs = AllLessons[lessonNum - 1].map((lesson, index) => {
  //   const rpt = lesson.filter(letter => letter === ltr).length;
  //   if(!rpt) return
  //   return { id:+`${lessonNum}0${index + 1}`, rpt: rpt };
  // });
  // function calculateTotalRpt(sumOfJs) {
  //   return sumOfJs?.reduce((total, obj) => total + obj.rpt, 0);
  // }
  
  // Calculate sumOfJs and totalRpt
  
  //const totalRpt = calculateTotalRpt(sumOfJs);
  
  //console.log(ltr, sumOfJs);
 // console.log(totalRpt)
//')
const { 
        mistakesCount, setMistakesCount, 
        cursPos, setCursPos,
        lessonCount, setLessonCount,
        exerciseCount, setExerciseCount,
        setTypesPerMinute,
        lessonStarted, setLessonStarted,
        numberOfExerc,
        lesson
      }  = useContext(ExercContext);

const navigate = useNavigate();

useEffect(() => {
    if(localStorage.getItem('exerciseCount') > 0) {
        setExerciseCount(localStorage.getItem('exerciseCount'));       
    }  
    if(localStorage.getItem('lessonCount') > 0) {      
      setLessonCount(localStorage.getItem('lessonCount'));      
    } 
  // eslint-disable-next-line  
}, []);

const lessonCompleted = localStorage.getItem('lessonComplete');  

const [timeCount, setTimeCount] = useState(false);
const { paused, handleUnPause, setPaused } = useUnPause();
 
const handleLessonStart = () => {  
    setCursPos(0);
    setMistakesCount(0);
    localStorage.setItem('lessonStarted', true);
    setLessonStarted(true);
//////////////////////////////////////////
   localStorage.setItem('lessonComplete', 'no');
   localStorage.setItem('exerciseCount', exerciseCount);                            
};

/////////////////////////////////////
const handleLessonComplete = () => {
    setCursPos(100);
    localStorage.setItem('mistakeCounter', mistakesCount);
    localStorage.setItem('lessonStarted', false);
    setLessonStarted(false);
    localStorage.setItem('lessonComplete', 'yes');  
    navigate('/exercise_completed'); 
};

const handleForceCompleted = () => {
  localStorage.setItem('lessonStarted', false);
  setLessonStarted(false);
  setTimeCount(false);   
  setMistakesCount(0);  
};
  
const handlePause = () => {
  setTimeCount(false);
  setPaused(true);
};

/////////// Перевірка мови  ///////
const pageHeader = useSelector((state) => state.pageHeader.pageHeader)
const dispatch = useDispatch()

const [trigerErrorMsg, setTrigerErrorMsg] = useState(false);
const triger = useSelector((state) => state.triger.triger)

const [desc, setDesc] = useState(exerciseDesc);
const [header, setHeader] = useState(exerciseDesc);

useEffect(() => { 
  const rpt = false;
  checkLangForMsg(
              triger, setDesc, setHeader, dispatch,
               exerciseDescEN, setTrigerErrorMsg, lessonHeaderEN,
               exerciseDesc, lessonHeader, exerciseDescAR,
               lessonHeaderAR, trigerErrorMsg, rpt, lessonStarted
              );
  // eslint-disable-next-line   
}, [triger, trigerErrorMsg]);

useEffect(() => {
  //console.log( paused);
  function handleKeyPress(event) {    
    if(event.key === 'Enter' && paused) {  
      console.log(cursPos);         
      //handleUnPause(cursPos, setTimeCount);   
      handleUnPause();      
    } 
    const charCode = event.which || event.keyCode;      
    const { key } = event;
//console.log(charCode, key, cursPos) 
    // Check if the pressed key corresponds to an English letter
    checkLang(key, charCode, timeCount, setTimeCount, setTrigerErrorMsg, cursPos);
  }
  window.addEventListener('keydown', handleKeyPress);
  return () => {
    window.removeEventListener('keydown', handleKeyPress);
  };
  // eslint-disable-next-line
}, []);
//////////////////////////////////////

if(!lessonStarted) {
  return (
     <PreLessonNotification
                lessonCount={lessonCount} 
                exerciseCount={exerciseCount}
                handleStart={handleLessonStart} 
                numberOfExerc={numberOfExerc}
                lessonStarted={lessonStarted}
                desc={desc}
                header={header}
                pageHeader={pageHeader} 
                /> 
  ) 
} else if(lessonStarted && lessonCompleted !== 'yes') {
  return (
    <TrainingScreen 
                 lessonCount={lessonCount} 
                 exerciseCount={exerciseCount}                
                 lesson={lesson[+lessonCount - 1][+exerciseCount - 1]}
                 mistakesCount={mistakesCount}
                 setMistakesCount={setMistakesCount} 
                 cursPos={cursPos}
                 setCursPos={setCursPos}
                 setTypesPerMinute={setTypesPerMinute}  
                 timeCount={timeCount} 
                 setTimeCount={setTimeCount}  
                 paused={paused}
                 numberOfExerc={numberOfExerc} 

                 handleLessonComplete={handleLessonComplete}
                 handleForceCompleted={handleForceCompleted}
                 handlePause={handlePause}
                 handleUnPause={handleUnPause} 
                 
                 desc={desc}
                 header={header} 
                 pageHeader={pageHeader}  
                 
                 rpt={false}
                />     
  )
} 
}

export default TextInputController


//let number = 1;
//   let findLesson = `lesson_${number}`;

// const test = (arr, item) => { 

//   let data = arr.lesson_1;
//   // let data = arr.filter(function(i) {    
//   //  return i  
//   // }); 
//   return data;
// };
