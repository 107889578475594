import React, { useState, useEffect } from 'react'
import { useUpdateUserMutation, useDeleteUserMutation } from './usersApiSlice'
import { useNavigate } from 'react-router-dom'
//import { ROLES } from '../../../config/roles'
import { nanoid } from '@reduxjs/toolkit'

const EditUserForm = ({ user }) => {
    console.log(user)
    // {
    //     _id: '64d014d31d28f69f235dd5f6',
    //     username: 'TestUser',
    //     email: 'denysgl1@zoho.com',
    //     roles: [ 'User' ],
    //     active: [],
    //     createdAt: '2023-08-06T21:46:59.096Z',
    //     updatedAt: '2023-08-06T21:46:59.096Z',
    //     __v: 0,
    //     id: '64d014d31d28f69f235dd5f6'
    //   }

    const navigate = useNavigate()

    const [ updateUser, {
        //   isLoading,
           isSuccess,
        //   isError,
        //   error
       }] = useUpdateUserMutation()

    const [ deleteUser, {
    //   isLoading,
        isSuccess: isDelSuccess,
    //   isError: isDelError,
    //   error: delerror
    }] = useDeleteUserMutation()

    const [username, setUserName] = useState(user.name)
    const [email, setEmail] = useState(user.email)
    const [password, setPassword] = useState('')
    const [roles, setRoles] = useState(user.roles)
    const [active, setActive] = useState(user.active)

    useEffect(() => {
        if(isSuccess || isDelSuccess) { cleanUp() }
// eslint-disable-next-line
    }, [isSuccess, isDelSuccess])

    function cleanUp() {
        setUserName('')
        setEmail('')
        setPassword('')
        setRoles([])
        setActive('')
        navigate('/users') 
    }

   const onUserUpdate = async (e) => {
    if (password) {
        await updateUser({ id: user.id, username, password, roles, active})
    } else {
        await updateUser({ id: user.id, username, roles, active})
    }
   }
  const onUserDelete = async () => {
     await deleteUser({ id: user.id })
  }

  return (
    <div>
        {user.id}<br/>
        {user.username}<br/>
        {user.email}<br/>
        {user.roles?.map(role => 
         <div key={nanoid()}>
            {role} ,
         </div> )           
        }<br/>
        {user.active?.map(status => 
         <div key={nanoid()}>
            {status} ,
         </div> )           
        }<br/>

    </div>
  )
}

export default EditUserForm
