import s from './css/graph.module.css'
import { XIcon } from '../Components/Icons';
import { lettersContent } from '../models/TasksContent';
import { nanoid } from '@reduxjs/toolkit';
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';

const OpenCardView = ( { ltr, handleCloseView, index, exercToRe, exerciseDesc, handleGoToLesson }) => {

  const transformUpTo = (upTo) => {
    const strId = upTo.toString();
    let idTr;
  
    if (strId[1] === '0') {
      idTr = parseInt(strId[0] + strId[2], 10);
    } else if (strId[1] === '1') {
      idTr = (parseInt(strId[0], 10) + 1) * 10;
    } else {
      idTr = upTo;
    }
    return idTr
  }

//// 0. Highest allowed exercise
    const upToExerc = +localStorage.getItem("lessonExcercPassed");
    const upToTr = transformUpTo(upToExerc) // transform upTo to index format   
//// 1. Finding subject letter data in Results Catallogue Array
    const rawLtrArray = lettersContent.filter(item => item.ltr === ltr.ltr);
///  2. Filtering the letter data array up to highest allowed exerc number
    const ltrArray = rawLtrArray[0].exerc.filter(item => +item.id <= upToExerc);
//// 3. Finding coExisting exerc between ExercToRepeat Zrrzy and the letter data Array
    const coExisting = ltrArray.filter(objTwo => exercToRe?.some(objOne => +objOne.id === +objTwo.id)); 
//   4. Sorting data found by 'rpt' property Highest to Lowest
    coExisting?.sort((a, b) => b.rpt - a.rpt);
//   5. Finding exerc's in the letter array besides those coExist in ExercToRepeat array
    const remaining = ltrArray.filter(objTwo => !coExisting?.some(co => co.id === objTwo.id));
    console.log(remaining)
//   6. Adding wt and idTr to remaining
const remainingTr = remaining.map(item => {
  const { id, rpt } = item;
  const strId = id.toString();
  let idTr;

  if (strId[1] === '0') {
    idTr = parseInt(strId[0] + strId[2], 10);
  } else if (strId[1] === '1') {
    idTr = (parseInt(strId[0], 10) + 1) * 10;
  } else {
    idTr = id;
  }

  // Calculate 'wt' based on the conditions provided
  const delta = upToTr - idTr - 15;
  let wt;
  if (delta <= 0) {
    wt = ((delta) * -1) * rpt;
    // If the result is -0, ensure wt is set to rpt
    if (wt === 0) wt = rpt;
  } else {
    wt = rpt;
  }

  return { id, rpt, idTr, wt };
});
////////////////////////////////
//  7. Adding wt and idTr to coExisting
const coExistingTr = coExisting?.map(item => {
  const { id, rpt } = item;
  const strId = id.toString();
  let idTr;

  if (strId[1] === '0') {
    idTr = parseInt(strId[0] + strId[2], 10);
  } else if (strId[1] === '1') {
    idTr = (parseInt(strId[0], 10) + 1) * 10;
  } else {
    idTr = id;
  }
  // Calculate 'wt' based on the conditions provided
  const delta = upToTr - idTr - 15;
  let wt;
  if (delta <= 0) {
    wt = ((delta) * -1) * rpt;
    // If the result is -0, ensure wt is set to rpt
    if (wt === 0) wt = rpt;
  } else {
    wt = rpt;
  }
  return { id, rpt, idTr, wt };
});
// 8. Combining 2 arrays
 const combined = [...coExistingTr, ...remainingTr]
 const combinedSorted = combined?.sort((a, b) => b.wt - a.wt)
 const combinedTrimmed = combinedSorted?.slice(0, 7);


  return (
    <div className={`${s.open_item} ${s.open_view_item} `}  key={nanoid()}>
 
      <div className={s.x_icon} onClick={() => handleCloseView(index)}>
        {XIcon(42)}
      </div>
      <div className={s.open_card_header}>
        <div className='pl-4'>
          Letter
        </div>
        <div>
            Correct types
        </div>
        <div>
            Faults
        </div>
        <div>
            Percentage of success
        </div>
      </div>

   <div className={s.line}/>

   <div className={s.open_card_body}>
      <div className={s.card_item_letter}>
        <b>{ltr.ltr}</b>
      </div> 
      <div className={s.card_item_result}>                   
          <div>
           {ltr.t}
          </div>
          <div className={s.card_item_result_f}>
             {ltr.f}
          </div>
          <div >
            {ltr.successRate} %
            {/* {(100 - (+ltr.f / (+ltr.t + +ltr.f))*100).toFixed(1)} % */}
          </div>
      </div>      
   </div>

   <div className={s.line}/>
    <div className={s.open_card_header_line}>
        <div>
            Recommended exercise
        </div>
        <div>
            Letter Repeted<br/>{'(times)'}
        </div> 
    </div>
   
   <div>
    
   </div>
     {combinedTrimmed?.map((item) => 
     <div key={nanoid()} className={s.open_card_recom_cont}
     onClick={() => {handleGoToLesson(
              +item.id.toString().charAt(2) === 0 ? 10 : +item.id.toString().charAt(2), 
              +item.id - 1,
              +item.id.toString().charAt(0) - 1,
              'rptd')
              }
            }
      >

        <div className={s.open_card_recom_exerc}> 
           <span>
              <FormattedMessage id='superPower' defaultMessage={defineMessages.superPower} /><span> {item.id.toString().charAt(0)}. </span> 
              <FormattedMessage id='exerc' defaultMessage={defineMessages.exerc} /> {item.id.toString().charAt(2) === '0' ? '10' : item.id.toString().charAt(2)}. 
               <span> {exerciseDesc[+(item.id.toString().charAt(0)) - 1][+(item.id.toString().charAt(2) === '0' ? '10' : item.id.toString().charAt(2)) - 1][1]}</span>
              <span> {exerciseDesc[+(item.id.toString().charAt(0)) - 1][+(item.id.toString().charAt(2) === '0' ? '10' : item.id.toString().charAt(2)) - 1][2]}</span>
           </span>
        </div>

        <div className={s.open_card_recom_rpt}>
           
            {item.rpt}
        </div>

     </div>
        
     )}
  </div>
  )
}

export default OpenCardView