import c from './login.module.css'
import { motion } from 'framer-motion/dist/framer-motion';
import Captcha from '../Captcha/Captcha';

const NewUserScreen3 = ({ setNoCaptcha, serverError }) => {
  return (
    <motion.div
        key='screen_3'
        animate={{x:"0%", y: "0%", opacity: 1, scale: 1}}
        initial={{ x:"-100%", y: "0%", opacity: 0, scale: 1}}
        exit={{x:"100%", y: "0%", transition: { duration: 0.8}}}
        transition={{ duration: 0.8, ease: "easeOut"}}
        className={c.screen_box}
     >
     <div className={c.captcha_wrapper}>
         <Captcha action={setNoCaptcha} />
     </div>
     {serverError && 
      <div>
        Sorry. <br/>Internal Error occured while attempting to create a new user<br/>        
      </div>
    }
    </motion.div>
  )
}

export default NewUserScreen3