
import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";
import c from './Form.module.css';
import { Form, Button } from 'react-bootstrap';
import { changePageHeader, sendMail } from '../../Functions';
import { motion } from 'framer-motion/dist/framer-motion';
import ScreenCard from '../Cards/ScreenCard';
import MailScreen1 from './MailScreen1';
import NewUserScreen3 from '../Login/NewUserScreen3';
import MailScreen3 from './MailScreen3';
import { useSelector, useDispatch } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner';

// import { FormattedMessage } from 'react-intl';
// import defineMessages from '../Lang/languages/messages';

const ContactForm = () => {

    const dispatch = useDispatch()
    const triger = useSelector((state) => state.triger.triger)
    useEffect(() => {
      changePageHeader(
                   triger, dispatch, 
                   'Contact Us', 'Зв\'яжитесь з нами', 'Contact Us -AR-'
                   )
// eslint-disable-next-line
    },[triger])
   

    const navigate = useNavigate()


 const [name, setName] = useState('');
 const [email, setEmail] = useState('');
 const [message, setMessage] = useState('');
 const [screenSwitch, setScreenSwitch] = useState(1);
 const [disableNext, setDisableNext] = useState(true);
 const [noCaptcha, setNoCaptcha] = useState(true)
 const [sentWithSuccess, setSentWithSuccess] = useState(false);
 const [loader, setLoader] = useState(false)


 useEffect(() => {
    setDisableNext(true)
    if(name !== '' && email !== '' && message !== ''){
        setDisableNext(false)
    }
// eslint-disable-next-line
 },[name, email, message])

 useEffect(() => {
    if(sentWithSuccess){
        setLoader(false)
        setName('');
        setEmail('');       
        setMessage('');
        setScreenSwitch(3)
    }
// eslint-disable-next-line
 },[sentWithSuccess])


 const sendMsg = (e) => {
    e.preventDefault()
    let msg = { 
        sendBy: process.env.REACT_APP_MAIL_SECRET,
        sendTo: email,
        subjectTxt: `Message from No Typo User`,
        messageBody: `User ${name} has send you following message: <br/><br/> ${message}`
    }
    sendMail(msg, setSentWithSuccess)
 }
 

  return (
<ScreenCard>
 <div className={`${c.login_wrapper} ${c.min_margins}`}>
    <div className={`${c.header_txt} ${c.min_margins}`}>
        Contact Us
    </div>

  <Form 
     className={`${c.inputs_section} ${c.mgn_btm}`}
       //action="feedback.php" 
       //method="post" 
       //onSubmit={(event) => disableBtn(event)}
       //onSubmit={(e) => sendMsg(e)}
   >
    {screenSwitch === 1 &&
        <MailScreen1 
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        message={message}
        setMessage={setMessage}
        />
    }
    {screenSwitch === 2 &&
        <NewUserScreen3 
         setNoCaptcha={setNoCaptcha}
        />
    }
    {screenSwitch === 3 &&
        <MailScreen3 />
    }
              
    <div className={c.btn_next_prev}>
    {screenSwitch !== 1 && screenSwitch !== 3 &&
        <motion.div 
            className={c.btn_box} 
            key='screen_1'
            animate={{x:"0%", y: "0%", opacity: 1, scale: 1}}
            initial={{ x:"-120%", y: "0%",  scale: 1}}
            exit={{x:"100%", y: "0%"}}
            transition={{ duration: 0.1 }}
         >
         
                <Button 
                    variant='outline-light' 
                    className={`${c.login_btn} ${c.btn_prev}`}
                    disabled={screenSwitch === 1}
                    onClick={() => setScreenSwitch(prev => prev - 1)}
                >
                    Previous
                </Button>
        </motion.div> 
     }
         <div className={c.btn_box}>
         {screenSwitch === 1 ? (
                <Button 
                    variant='success' 
                    className={`${c.login_btn} ${c.btn_prev}`}
                    disabled={disableNext}
                    onClick={() => setScreenSwitch(prev => prev + 1)}
                >
                    Next
                </Button> 
                ) : screenSwitch === 2 ? (
                    <Button variant='success' 
                        className={`${c.login_btn} ${c.btn_prev}`} 
                        disabled={noCaptcha}
                        onClick={(e) => sendMsg(e)}
                    >
                        {!loader ? 'Submit' : <Spinner animation="border" variant="secondary" size="md" />}
                    </Button> 
                ) : (
                     <Button variant='success' 
                        className={`${c.login_btn} ${c.btn_prev}`} 
                        onClick={() => navigate('/')}
                    >
                     To Main
                    </Button>
                )}
  
            </div>   
         </div>
     
    </Form>
   </div>
 
</ScreenCard>
  )
}

export default ContactForm




// const disableBtn = () => {
//    // event.preventDefault();  
//     setNotChecked(true);
//     setBtnColor('outline-light');
//     // setBtnText('Please enter CAPTCHA symbols into the field');
//        setBtnTextSmall('Please enter CAPTCHA symbols');
       
//     // setTimeout(() => {
//     //     setName('');
//     //     setEmail('');       
//     //     setMessage('');
//     // }, 2000);
    
//  };
//   {/*           
//             <Button 
//                 variant={btnColor}
//                 id='send-btn-modal'
//                 type='submit'
//                 // onClick={disableBtn}
//                 className={`mb-4 mt-3 ${c.rounded_btn}`}
//                 disabled={notChecked}
//             >
//                 {notChecked ? (
//                 <span className={c.btn_text_small}>{btnTextSmall}</span>
//                 ):(
//                 <span className={c.btn_text}>{btnText}</span>
//                 )}               
//             </Button> */}


//  const allowSending = (data) => {
//      if (data === false) {
//          setNotChecked(false);
//          setBtnText('S E N D');
//        //  setBtnTextSmall('S E N D');
//          setBtnColor('outline-success');
//      } else if (data === true) {
//         disableBtn();
//      }
//  }; 
 // console.log(process.env)
  //const [notChecked, setNotChecked] = useState(true);
// const [btnText, setBtnText] = useState('Please enter CAPTCHA symbols');
 //const [btnTextSmall, setBtnTextSmall] = useState('Please enter CAPTCHA symbols');
 //const [btnColor, setBtnColor] = useState('outline-light');