
import { defineMessages } from 'react-intl';

export default defineMessages({
  
  logo: 'Здобудь Cупер Здібність!',
  startTraining: 'Розпочати Тренерування',
  goToTraining: 'До Мого Тренерування',
  allSuperPowers: 'Усі Здібності',
  schedric: 'Щ Е Д Р И К у - 100 років!',
  schedric2: 'Послухати',
  schedric3: 'Увімкніть звук щоб Послухати',
  chooseColour: 'Оберить колір до вподоби',
  startBtn: 'Р о з п о ч а т и',
  contBtn: 'П р о д о в ж и т и',
  contBtn2: 'Продовжити',
  oneMoreTBtn: 'Ще раз',

  superPower: 'Здібність',
  exerc: 'Вправа',
  of: 'з',
  finishg: 'З а к і н ч и т и',
  exerc2: 'В п р а в у',
  pause: 'П А У З А',
  youAreTraining: 'Ви тренеруєте :',
  continue: 'П р о д о в ж и т и',
  errors: 'Кількість Помилок',
  time: 'Використано часу -',
  keyBoard: 'Сховати клавіатуру',
  ShowKeyBoard: 'Показати клавіатуру',
  completed: 'З а к і н ч е н о !',
  errors2: 'ПОМИЛОК',
  stars: 'ЗІРОЧОК',
  speed: 'ШВИДКІСТЬ',
  pcs: 'од.',
  simb: 'знаків на хв.',
  sec: 'c.',
  sorry: 'Пробачте',
  reqPage: 'Запитуєма сторінка',
  notFnd: 'не знайдена',
  toMain:'на головну',
  and: 'та',
  chooseLesAndEx: 'Оберить Здібність та Вправу для тренерування',

  contactUs: 'Зв\'язатись з нами',
  gainPower: 'ХОЧЕТЕ ОВОЛОДІТИ СУПЕРСИЛОЮ СЕНСОРНОГО НАБОРУ ТЕКСТУ?',
  rightPlace: 'ВИ В ПОТРІБНОМУ МІСЦІ!',
  howTo: 'Як друкувати текст сенсорно',
  forTouchType: 'При сенсорному наборі тексту у кожного пальця є своя область на клавіатурі. Таким чином, ви можете друкувати, не дивлячись на клавіші.',       
  practiceReg: 'Регулярно тренуйтеся, і ваші пальці вивчать своє розташування на клавіатурі завдяки м\'язової пам\'яті.',
  homeRow: 'Основна позиція пальців',
  yourFingures: 'Злегка зігніть пальці і покладіть їх на ASDF і JKL; клавіші, які розташовані в середньому ряду буквених клавіш. Цей рядок називається',
  homeRowPos: 'ОСНОВНА ПОЗИЦІЯ',
  alwaysStart: 'тому що ви завжди починаєте з цих ключів і завжди повертаєтесь до них. Клавіші F і J під вказівними пальцями повинні мати рельєфну лінію, щоб полегшити пошук цих клавіш, не дивлячись.',
  finMo: 'Рух пальців',
  finMoPar : 'Не дивіться на клавіші, коли друкуєте. Просто рухайте пальцями по колу, поки вони не знайдуть розмітку початкового ряду. Обмежте рух вашої руки і пальців тільки тим, що необхідно для натискання певної клавіші. Тримайте руки і пальці близько до початкового положення. Це підвищує швидкість набору тексту і знижує навантаження на руки.',
  tySpd: 'Швидкість друку',
  tySpdPar1: 'Не поспішайте, коли ви тільки почали вчитися. Прискорюйтеся тільки тоді, коли ваші пальці за звичкою натискають на потрібні клавіші.',
  tySpdPar2: 'Не поспішайте вводити текст, щоб уникнути помилок. Швидкість буде збільшуватися в міру вашого просування.',
  tySpdPar3: 'Завжди ЗАЗДАЛЕГІДЬ переглядайте текст на одне-два слова.',
  TrainAny: 'Тренуйтеся де завгодно!',
  TrainAnyPar1: 'Наш веб-додаток не вимагає постійного підключення до Інтернету.',
  TrainAnyPar2: 'Після завантаження веб-сторінки ви можете відключитися від Інтернету та продовжувати тренуватися в будь-якому місці і в будь-який час!',
  TrainAnyPar3: 'Просто не оновлюйте веб - сторінку в цьому випадку ))',
  enterSym: 'Ввведить символи з картинки',
  multLangInt: 'Багатомовний інтерфейс',
  chkStg: 'Перевірте налаштування',
  toChoose: 'щоб вибрати мову інтерфейсу за вашим вибором',

  
  rpt: 'Повторюємо',
  bestRes: 'Ваш найкращий результат',
  newRes: 'Ваш новий результат -',
  formerRes: 'Колишній найкращий результат -',
  congrat: 'Вітаю!',
  btrRes: 'Ви покращили свій результат!',
  btrSpd: 'Ви покращили швидкість, але все ж,',
  btrSpd2: 'Вам потрібно робити менше помилок',
  btrBfr:'Ви досягли ще більшого успіху',
  btrBfr2: 'під час попередньої спроби',
  amaiz: 'Дивно!!!',
  sameRes: 'Ви двічі отримали абсолютно однакові результати!',
  grWork: 'Чудова робота!',
  imprRes:'Ви покращили швидкість набору тексту',
  youAreRpt: 'Ви повторюєте :',
  chooseLang: 'Оберіть мову',
  headerStartBtn1: 'Почати тренерування',
  headerStartBtn2: 'До тренерування',
  headerLogInBtn: 'Увійти / Зареєструватись',
  keyBoardPurpose: 'Це зображення клавіатури призначене лише для того, щоб допомогти вам знайти місце розташування наступної літери. Для набору тексту, будь ласка, використовуйте комп\'ютерну клавіатуру',
  exrsToRpt: 'Вправи, рекомендовані для перепідготовки',
  analitics: 'Analitics',
  reTrain: "Re-Train | Re-Do",

});

   

  // contacts: {
  //   header: "Вы можете связаться со мной",
  //   byPhone: "По телефону: ",
  //   messageIn: "Написать мне в:",
  //   or: "или",
  //   messageForm: "Отправьте мне сообщение",
  //   messageFormName: "Ваше имя", 
  //   messageFormMail: "Ваш email",
  //   messageFormSubj: "Тема",
  //   messageFormMsg: "Ваше сообщение",
  //   messageButton: "Отправить",
  //   visitPage: "Посетите мою страницу в:",

  // }