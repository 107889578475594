import { Outlet } from 'react-router-dom'
import PageRegControllers from './Components/MenuButtons/PageRegControllers'

const Layout = () => {
  return (
    <>
    <PageRegControllers />
    {/* &#x1F3C1; */}
    <Outlet/>
    </>
    
  )
}

export default Layout