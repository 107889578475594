import { Routes, Route } from "react-router-dom";
import useBg from "./Hooks/useBg";
import c from "./App.module.css";
//import { HashRouter as Router, Switch, Route} from 'react-router-dom';
import HomeScreen from "./views/HomeScreen";
import TextInputController from "./controllers/TextInputController";
import NoPage from "./Components/NoPage";
import AllLessonsController from "./controllers/AllLessonsController";
//import LessonEndController from './controllers/LessonEndController';
import ExercEndController from "./controllers/ExercEndController";
import RptExercController from "./controllers/RptExercController";
import RptExercEndController from "./controllers/RptExercEndController";
import NewLessonStartController from "./controllers/NewLessonStartController";
import ContactFormController from "./controllers/ContactFormController";
import AllUsersController from "./Components/Temp/AllUsersController";
import Login from "./Components/Login/Login";
import NewUser from "./Components/Login/NewUser";
import RestorePassword from "./Components/Login/RestorePassword";
import Layout from "./Layout";
import UserResult from "./Redux/features/results/UserResult";
import EditUserResult from "./Redux/features/results/EditUserResult";
import NewUserResult from "./Redux/features/results/NewUserResult";
import EditUser from "./Redux/features/users/EditUser";
import Prefetch from './Redux/api/Prefetch';
//import PersistLogin from "./Redux/features/auth/PersistLogin";

// Ctrl+Shift+L - обрати увсі подібні записи
function App() {
  const { activClass } = useBg(); 

  return (
    <div className={`${c.App} ${activClass}`}>
      <Routes>
        {/* <Route element={<PersistLogin />}>   Persist LogIn Starts */}
          <Route path='/' element={<Layout />}>
            <Route index element={<HomeScreen />} />

            <Route path='/training' element={<TextInputController />} />
            <Route path='/all_lessons' element={<AllLessonsController />} />
            {/* <Route path="/lesson_completed" component={LessonEndController}/> */}
            <Route
              path='/exercise_completed'
              element={<ExercEndController />}
            />
            <Route path='/exercise_rpt' element={<RptExercController />} />
            <Route
              path='/exercise_repited'
              element={<RptExercEndController />}
            />
            <Route
              path='/to_new_power'
              element={<NewLessonStartController />}
            />
            <Route path='/contact_form' element={<ContactFormController />} />
            <Route path='/login' element={<Login />} />
            <Route path='/new_user' element={<NewUser />} />

             <Route element={<Prefetch/>}>{/*  Wrapper containing Prefetch data */}

            <Route path='users'>
              <Route index element={<AllUsersController />} />
              <Route path='restore_password' element={<RestorePassword />} />
              <Route path=':id' element={<EditUser />} />
            </Route>

            <Route path='results'>
              <Route index element={<UserResult />} />
              <Route path='edit_result' element={<EditUserResult />} />
              <Route path='new_result' element={<NewUserResult />} />
            </Route>

            </Route> {/*  Prefetch ends */}
          </Route>
          {/*  Home Route ends - path='/'  */}
       {/* </Route>
        Persist LogIn ends */}
        <Route path='*' element={<NoPage />} />
      </Routes>
    </div>
  );
}

export default App;
// {/* <PrivateRoute path="/to_requests" component={TORequests} /> */}

//    <Switch>
//   <Route path='/' exect component={App} />
//   <Route path='/Data' component={Data} />
//   <Route path='/Agreement' component={Agreement} />
//   <Route path='/Statement' component={Statement} />
//  </Switch>

//  <Link to='Data'>Заполнить Данные</Link>
//  <Link to='Agreement'>Перейти к Договору Партнерства</Link>
//  <Link to='Statement'>Перейти к Акту Приема-Передачи</Link>
