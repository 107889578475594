export const AllLessons = [
   // Lesson 1 Основна позиція пальців
    [
      // Ex 1
      [
         'j', 'k', 'k', 'j', 'k', 'Space', 'k', 'j', 'k', 'k', 'j', 'Space', 'j', 'k', 'j', 'j', 'k',  'Space', 'k', 'k', 'j', 'k', 'j', 'Space', 
         'j', 'j', 'k', 'j', 'k', 'Space', 'j', 'k', 'j', 'k', 'k',  'Space', 'k', 'j', 'k', 'j', 'j', 'Space',
         'j', 'k', 'j', 'k', 'j', 'Space', 'k', 'k', 'j', 'k', 'j', 'Space', 'j', 'j', 'k', 'j', 'k',      
      ],
      // Ex 2
      [
         'd', 'f', 'f', 'd', 'f', 'Space', 'f', 'd', 'f', 'f', 'd', 'Space', 'd', 'f', 'd', 'd', 'f', 'Space', 'f', 'f', 'd', 'f', 'd', 'Space',
         'd', 'd', 'f', 'd', 'f', 'Space', 'd', 'f', 'd', 'f', 'f', 'Space', 'f', 'd', 'f', 'd', 'd', 'Space',
         'd', 'f', 'd', 'f', 'd', 'Space', 'f', 'f', 'd', 'f', 'd', 'Space', 'd', 'd', 'f', 'd', 'f',
      ],
      // Ex 3
      [
         'j', 'f', 'f', 'j', 'f', 'Space', 'j', 'j', 'j', 'f', 'f', 'Space', 'f', 'j', 'f', 'f', 'j', 'Space', 'j', 'j', 'f', 'f', 'j','Space',
         'f', 'f', 'f', 'j', 'j', 'Space', 'd', 'k', 'd', 'k', 'd', 'Space', 'k', 'd', 'k', 'd', 'k', 'Space',  
         'k', 'k', 'd', 'd', 'k', 'Space', 'd', 'd', 'k', 'd', 'k', 'Space', 'k', 'd', 'd', 'k', 'k',
      ],
      // Ex 4
      [
         'd', 'd', 'j', 'j', 'd', 'Space', 'k', 'k', 'f', 'k', 'k', 'Space', 'f', 'f', 'j', 'f', 'j', 'Space', 'k', 'k', 'd', 'k', 'k', 'Space',
         'j', 'j', 'd', 'j', 'd', 'Space', 'k', 'd', 'k', 'd', 'k', 'Space', 'f', 'f', 'j', 'j', 'k', 'Space',
         'k', 'k', 'd', 'd', 'f', 'Space', 'd', 'k', 'f', 'd', 'f', 'Space', 'k', 'k', 'j', 'j', 'f',        
      ],
      // Ex 5
      [
         'l', ';', ';', 'l', ';', 'Space', ';', 'l', ';', ';', 'l', 'Space','l', ';', 'l', 'l', ';', 'Space', ';', ';', 'l', ';', 'l','Space', 
         'l', 'l', ';', 'l', ';', 'Space', 'l', ';', 'l', ';', ';', 'Space',';', 'l', ';', 'l', 'l', 'Space',
         'l', ';', 'l', ';', 'l', 'Space', ';', ';', 'l', ';', 'l', 'Space', 'l', 'l', ';', 'l', ';',
      ],
      // Ex 6
      [
         'l', 'f', 'k', 'Space', ';', 'f', 'k', 'Space', 'd', 'j', 'l', 'f', 'Space', 'k', 'f', 'l', 'f', 'Space',';', 'f', 'k', 'j', 'Space',
         'k', 'j', ';', 'f', 'Space', 'd', 'j', ';', ';', 'f', 'Space', ';', 'f', ';', 'l', 'f',  'Space', ';', 'l', 'f', 'k', 'f', 'Space',
         'd', 'l', 'j', 'd', 'f', 'Space', ';', 'f', ';', 'l', 'f', 'k', 'f',    
      ],
      // Ex 7
      [
         'a', 's', 's', 'a', 's', 'Space', 's', 'a', 's', 's', 'a', 'Space', 'a', 's', 'a', 'a', 's', 'Space', 's', 's', 'a', 's', 'a', 'Space', 
         'a', 'a', 's', 'a', 's', 'Space', 'a', 's', 'a', 's', 's', 'Space', 's', 'a', 's', 'a', 'a', 'Space',
         'a', 's', 'a', 's', 'a', 'Space', 's', 's', 'a', 's', 'a', 'Space', 'a', 'a', 's', 'a', 's',
      ],
      // Ex 8
      [
         'a', 'l', 'l', 'Space', 'a', 'd', 'd', ';', 'Space', 'a', 's', 'Space', 'a', 's', 'k', ';', 'Space', 's', 'a', 'd', ';', 'Space', 
         'f', 'a', 's', 'Space', 'l', 'a', 'd', 'Space', 'd', 'a', 'k', ';', 'Space', 'd', 'a', 'd', 'Space', 'f', 'a', 'd', 'Space', 
         'f', 'a', 'l', 'l', ';', 'Space', 'l', 'a', 's', 's', 'Space',  'd', 'a', 'l', 'l', ';',  
      ],
      // Ex 9
      [
         'a', 'l', 'a', 's', 'Space', 'd', 'a', 'l', 'd', 'Space', 'f', 'a', 'l', 'l', ';', 'Space', 'd', 'a', 'd', 'Space',
         'f', 'l', 'a', 'k', ';', 'Space', 'l', 'a', 's', 's', 'Space', 's', 'a', 'd', ';', 'Space', 'f', 'a', 's', 's', ';','Space',
          'a', 'l', 'l', 'Space', 'f', 'a', 'l', 'l', 'Space', 'l', 'a', 'd', ';', 'Space', 'a', 's', 'k',         
      ],
      // Ex 10
      [
         'l', 'j', ';', 'l', 'j', 'Space', 'f', 'l', 'a', 'k', ';', 'Space', 'd', 'a', 'k', ';', 'Space', 'd', 'j', 'l', 'f', 'Space',
         'k', 'f', 'l', 'f', 'Space', 'a', 'd', 'd', ';', 'Space', 'a', 'l', 'a', 's', 'Space', 'j', 'a', 'l', 'l', 'Space',
         'f', 'a', 'l', 'k', 'Space', 'd', 'a', 'l', 'l', ';', 
      ]
   ],
 //  Lesson 2 Основна позиція + верхній та середній раяд вказівних пальців
   [
       // Ex 1
       [
         'g', 'a', 'd', 'Space', 'h', 'a', 's', 'Space', 'a', 'h', 'a', ';', 'Space', 'h', 'a', 'd', 'Space',
         'f', 'l', 'a', 'g', 'Space', 'g', 'a', 's', ';', 'Space', 's', 'a', 'g', 'Space', 'a', 's', 'h', ';', 'Space',
         'g', 'a', 'g', 'Space', 'd', 'a', 's', 'h','Space', 'g', 'l', 'a', 'g', 'Space', 'h', 'a', 'l', 'f', ';',  
      ],      
      // Ex 2     
      [
         'g', 'a', 'f', 'f', ';', 'Space', 'h', 'a', 'l', 'l', 'Space', 'h', 'a', 'l', 'd', 'Space', 's', 'a', 'g', 'a', 'Space',
         'h', 'a', 's', 'h', ';', 'Space', 's', 'a', 's', 'h', 'Space', 'g', 'a', 'l', 'l', 'Space', 
         'f', 'l', 'a', 'g', ';','Space', 'h', 'a','s','Space', 'd','a', 's', 'h','Space', 'h', 'a', 'l', 'f'
        ],
        // Ex 3
      [
         'r', 't', 't', 'r', 't','Space', 't', 'r', 't','t','r', 'Space', 'r', 't','r', 'r', 't', 'Space', 't', 't','r', 't','r', 'Space',
         'r', 'r', 't', 'r', 't', 'Space', 'r', 't', 'r', 't', 't', 'Space', 't','r', 't', 'r', 'r', 'Space', 
         'r', 't', 'r', 't', 'r', 'Space', 't', 't', 'r', 't','r', 'Space','r', 'r', 't', 'r', 't'
        ],
        // Ex 4
      [
         'a', 'r', 't', 'Space', 't', 'a', 'd', 'Space', 'g', 'a', 'r', 'Space',  'a', 't', 'Space',
         's', 'a', 't', 'Space','r', 'a',  'g', 'Space', 't', 'a', 'g', ';', 'Space', 'f', 'a', 'r','Space', 'j', 'a', 'r', 'Space',
         't', 'a', 'r', 'Space', 'r', 'a', 'h', 'Space', 'h', 'a', 't', 'Space', 'r', 'a', 't', 'Space', 'r', 'a', 'g', ';', 'Space', 't', 'a', 't',
        ],
        // Ex 5
      [
         'd', 'a', 'f', 't', 'Space', 'd', 'a', 'r', 't', 'Space', 'j', 'a', 'r', 's', 'Space', 't', 'a', 's', 'k', ';', 'Space',
         'h', 'a', 'r', 'd', 'Space', 't', 'a', 'r', 't', 'Space', 's', 't', 'a', 'r', 't', 'Space', 'g', 'r', 'a', 'd', 'Space', 'd', 'a',
         't', 'a', 'Space', 't', 'a', 'l', 'k', ';', 'Space', 's', 'h', 'a', 'f', 't', 'Space', 'r', 'a', 's', 'h'
        ],
        // Ex 6
      [
         'h', 'a', 'r', 't', ';', 'Space', 'h', 'a', 'f', 't', 'Space', 'k', 'a', 'r', 'a', 't', 'Space', 'h', 'a', 'l', 't', 'Space',
         's', 'a', 'l', 't', 'Space', 'd', 'a', 'r', 'k', ';', 'Space', 'r', 'a', 'f', 't', 'Space', 'd', 'r', 'a', 'f', 't','Space', 
         's', 'h', 'a', 'r', 'k', ';', 'Space', 'g', 'r', 'a', 's', 's', 'Space', 'g', 'l', 'a', 's', 's', ';'        
      ],
      // Ex 7
      [
         'g', 'r', 'a', 'f', 't', 'Space', 'f', 'a', 's', 't', 'Space', 'r', 'a', 'j', 'a', 'Space', 's', 'h', 'a', 'r', 'k', 'Space',
         'g', 'a', 'r', 'd', 'Space', 's', 'h', 'a', 'r', 'd', 'Space', 's', 't', 'a', 'r', 't', 'Space', 
         'l', 'a', 'r', 'd', ';', 'Space', 'f', 'l', 'a', 't', 
      ],
      // Ex 8
      [
         'y', 'u', 'u', 'y', 'u', 'Space', 'u', 'y', 'u', 'u', 'y', 'Space', 'y', 'u', 'y', 'y', 'u', 'Space', 
         'u', 'u', 'y', 'u', 'y', 'Space', 'y', 'y', 'u', 'y', 'u', 'Space', 'y', 'u', 'y', 'u', 'u', 'Space',
         'u', 'y', 'u', 'y', 'y', 'Space', 'y', 'u', 'y', 'u', 'y', 'Space', 'u', 'u', 'y', 'u', 'y', 'Space','y', 'y', 'u', 'y', 'u',     
      ],
      // Ex 9
      [
         'd', 'a', 'y','Space', 'f', 'l', 'y', 'Space', 'd', 'u', 'g', 'Space', 'l', 'a', 'y', 'Space', 
         'j', 'a', 'y', 'Space', 's', 'k', 'y', 'Space', 'l', 'u', 'g', 'Space', 'f', 'u', 'r', 'Space', 
         'f', 'r', 'y', 'Space', 't', 'r', 'y', 'Space', 'h', 'u', 't', ';', 'Space', 's', 'a', 'y','Space',
          'l', 'a', 'd', 'y', 'Space', 'y', 'a', 'r', 'd',    
      ],
      // Ex 10
      [
         'l', 'u', 's', 'h', 'Space', 's', 'h', 'u', 't',  'Space', 'f', 'r', 'a', 'y', 'Space', 's', 'u', 'r', 'd', 'Space', 
         'l', 'u', 'r', 'k', 'Space', 'u', 's', 'u', 'a', 'l', 'Space', 's', 'u', 'r', 'f', 'Space', 'f', 'l', 'a', 'y','Space',
         'j', 'u', 's', 't', 'Space', 'l', 'u', 's', 't', 'Space', 'd', 'u', 's', 't',      
      ],
       
     ], 
// Lesson 3 + нижній ряд вказівних пальйів
    [
      // Ex 1
      [
         'v', 'b', 'b', 'v', 'b', 'Space', 'b', 'v', 'b', 'b', 'v', 'Space', 'v', 'b', 'v', 'v', 'b','Space', 
         'b', 'b', 'v', 'b', 'v', 'Space', 'v', 'v', 'b', 'v', 'b', 'Space', 'v', 'b', 'v', 'b', 'b', 'Space',
         'b', 'v', 'b', 'v', 'v', 'Space', 'v', 'b', 'v', 'b', 'v', 'Space', 'b', 'b', 'v', 'b', 'v', 'Space', 'v', 'v', 'b', 'v', 'b',     
      ],
      // Ex 2
      [
         'b', 'a', 'd', 'Space', 'v', 'a', 'g','Space', 'b', 'u', 'g', 'Space','b', 'a', 'r', 'Space',
         'v', 'a', 'g', 'Space', 'v', 'a', 's', 'Space', 'v', 'a', 't', 'Space', 'b', 'a', 'v', 'Space', 
         'b', 'a', 'y', 'Space', 'b', 'a', 't', 'Space', 'b', 'a', 'g', 'Space', 'b', 'a', 'h', 'Space',
         'v', 'a', 's', 't', 'Space', 'b', 'u', 'r', 
      ],
      // Ex 3
      [
         'b', 'a', 'b', 'y', 'Space', 'v', 'a', 'g', 'a', 'r', 'y', 'Space', 'b', 'u', 'r', 's', 't','Space', 
         'v', 'a', 'r', 'y', 'Space', 'r', 'u', 'b', 'y', 'Space', 'v', 'a', 'l', 'u', 't', 'a', 'Space', 
         'b', 'u', 'y', 'Space', 'v', 'a', 's', 't', 'Space', 'v', 'a', 'u', 'l', 't', 'Space', 
         'v', 'u', 'l', 'g', 'a', 'r', 'Space', 'b', 'y',
      ],
      // Ex 4
      [
         'n', 'm', 'm', 'n', 'm', 'Space', 'm', 'n', 'm', 'm', 'n', 'Space', 'n', 'm', 'n', 'n', 'm', 'Space',
         'm', 'm', 'n', 'm', 'n', 'Space', 'n', 'n', 'm', 'n', 'm', 'Space', 'n', 'm', 'n', 'm', 'm', 'Space',
         'm', 'n', 'm', 'n', 'n', 'Space', 'n', 'm', 'n', 'm', 'n', 'Space', 'm', 'm', 'n', 'm', 'n',     
      ],
      // Ex 5
      [
         'h', 'a', 'm', 'Space', 'g', 'u', 'n', 'Space', 'j', 'a', 'm', 'Space', 'r', 'a', 'n', 'Space', 
         'g', 'u', 'm', 'Space', 'm', 'a', 'n', 'Space', 'f', 'u', 'n', 'Space', 'm', 'a', 't', 'Space',
         'n', 'a', 'b', 'Space', 'a', 'r', 'm', 'Space', 's', 'u', 'n', 'Space', 'm', 'a', 'y', 'Space',
         'n', 'u', 't', 'Space', 't', 'u', 'n', 'Space', 'm', 'u', 'd', 
      ],
      // Ex 6
      [
         'n', 'u', 'm', 'b', 'Space', 'b', 'u', 's', 'h', 'Space', 'r', 'a', 'y', 'Space', 'b', 'a', 'u', 'l', 'k', 'Space',
         'f', 'l', 'a', 's', 'k', 'Space', 'b', 'a', 'l', 'd', 'Space', 's', 't', 'u', 'f', 'f', 'Space', 
         'b', 'a', 's', 'k', 'Space', 's', 'h', 'a', 'r', 'k', 'Space', 'n', 'a', 'v', 'y', 'Space', 'h', 'u', 'r', 'r', 'y',
      ],
      // Ex 7
      [
         'm', 'a', 'n', 'y', 'Space', 'n', 'a', 'v', 'y', 'Space', 'h', 'u', 'r', 'r', 'y', 'Space',
         's', 'l', 'u', 'm', 'Space', 'n', 'a', 'n', 'y', 'Space', 's', 'k', 'u', 'n', 'y', 'Space',
         'a', 's', 'k', 'Space', 't', 'a', 'l', 'l', 'Space', 'm', 'a', 'n', 'Space', 't', 'a', 'l', 'k',
      ],
      // Ex 8
      [
         'v', 'b', 'n', 'Space', 'm', 'v', 'b', 'Space', 'n', 'j', 'k', 'Space', 'd', 'd', 'f', 'Space',
         'j', 'm', 'n', 'Space', 'k', 'h', 's', 'Space', 'g', 'a', 's', 'Space', 'k', 'a', 'l', 'k', 'Space',
         'g', 'r', 'm', 'Space', 'u', 'h', 'u', 'Space', 'y', 'u', 'm', 'Space', 'd', 'a', 'v', 
      ],
      // Ex 9
      [
         'm', 'a', 'n', ';', 'Space', 'b', 'u', 'l', 'k', 'Space', 'h', 'a', 'r', 's', 'h', 'Space', 
         'j', 'u', 'n', 'n', 'Space', 't', 'a', 'l', 'k', 'Space', 'b', 'a', 'n', ';', 'Space',
         's', 'a', 'm', 'm', 'Space', 'r', 'a', 'm', ';', 'Space', 'h', 'a', 'm', 'Space', 'm', 'a', 'n',
      ],
      // Ex 10
      [
         'a', 's', 'u', 'm', 'Space', 'b', 'u', 's', 'y', 'Space', 'g', 'r', 'u', 'n', 'Space', 
         's', 'a', 'm', 'y', 'Space', 'b', 'a', 'n', 'k', 'Space', 'k', 'l', 'm', ';', 'Space', 
         'y', 'u', 'm', 'Space', 'm', 'a', 'n', 'y', 'Space', 'j', 'u', 's', 'y', 'Space', 'j', 'a', 'm', ';',  
      ]
   ],
   // Lesson 4 Середній та безімяний пальці
   [
      // Ex 1
      [
         'i', 'o', 'o', 'i', 'o', 'Space', 'o', 'i', 'o', 'o', 'i', 'Space', 'i', 'o', 'i', 'i', 'o', 'Space',
         'o', 'o', 'i', 'o', 'i', 'Space', 'i', 'i', 'o', 'i', 'o', 'Space', 'i', 'o', 'i', 'o', 'o', 'Space',
         'o', 'i', 'o', 'i', 'i', 'Space', 'i', 'o', 'i', 'o', 'i', 'Space', 'o', 'o', 'i', 'o', 'i','Space', 'i', 'i', 'o', 'i', 'o', 
      ],
      // Ex 2
      [
         'h', 'i', 'd', 'Space', 'i', 'l', 'l', 'Space',  'o', 'i', 'l', 'Space', 'f', 'o', 'r', 'Space',  
         'h', 'i', 't', 'Space',  'o', 'u', 't', 'Space', 'r', 'i', 'd', 'Space', 'd', 'o', 'g', 'Space',  
         'h', 'o', 't', 'Space', 'o', 'l', 'd', 'Space', 't', 'o', 'o', 'Space', 's', 'i', 't','Space',  
         'o', 'a', 't', 'Space', 'f', 'i', 'n', 'Space', 'a', 'i', 'm', 
      ],
      // Ex 3
      [
         'w', 'e', 'e', 'w', 'e', 'Space', 'e', 'w', 'e', 'e', 'w', 'Space', 'w', 'e', 'w', 'w', 'e', 'Space', 
         'e', 'e', 'w', 'e', 'w', 'Space', 'w', 'w', 'e', 'w', 'e', 'Space', 'w', 'e', 'w', 'e', 'e', 'Space',
         'e', 'w', 'e', 'w', 'w', 'Space', 'w', 'e', 'w', 'e', 'w', 'Space', 'e', 'e', 'w', 'e', 'w', 'Space',
         'e', 'e', 'w', 'e', 'w',
      ],
      // Ex 4
      [
         'r', 'e', 'd','Space', 't', 'e', 'a','Space', 'w', 'a', 'y','Space', 'l', 'e', 'g','Space',
         't', 'i', 'e','Space', 'l', 'e', 't','Space', 's', 'e', 'e','Space', 'o', 'w', 'e','Space',
         'w', 'e', 't','Space', 's', 'e', 't','Space', 'l', 'i', 'e','Space', 'f', 'e', 'w','Space',
         'h', 'o', 'w','Space', 's', 'i', 'r','Space', 'w', 'h', 'o',
      ],
      // Ex 5
      [
         ',', '.', '.', ',', '.','Space', '.', ',', '.', '.', ',','Space', ',', '.', ',', ',', '.','Space',
         '.', '.', ',', '.', ',','Space', ',', ',', '.', ',', '.','Space', ',', '.', ',', '.', '.','Space',
         '.', ',', '.', ',', ',','Space', ',', '.', ',', '.', ',','Space', '.', '.', ',', '.', ',','Space',
         ',', ',', '.', ',', '.',
      ],
      // Ex 6
      [
         'w', 'h', 'y', ',', 'Space', 'y', 'e', 's', '.', 'Space', 'l', 'o', 'w', ',', 'Space', 'w', 'i', 's', ',', 'Space',
         't', 'w', 'o', ',', 'Space', 'i', 'e', 'n', '.', 'Space', 'l', 'e', 'd', '.', 'Space', 'e', 'i', 'b', ',', 'Space',
         't', 'e', 'n', ',', 'Space', 'y', 'o', 'u', '.', 'Space', 'n', 'e', 'w', ',', 'Space', 'm', 'e', 't', '.',
      ],
      // Ex 7
      [
         'x', 'c', 'c', 'x', 'c', 'Space', 'c', 'x', 'c', 'c', 'x', 'Space', 'x', 'c', 'x', 'x', 'c', 'Space',
         'c', 'c', 'x', 'c', 'x', 'Space', 'x', 'x', 'c', 'x', 'c', 'Space', 'x', 'c', 'x', 'c', 'c', 'Space',
         'c', 'x', 'c', 'x', 'x', 'Space', 'x', 'c', 'x', 'c', 'x', 'Space', 'c', 'c', 'x', 'c', 'x', 'Space',
         'x', 'x', 'c', 'x', 'c', 
      ],
      // Ex 8
      [
         'a', 'c', 't', ',', 'Space', 's', 'i', 'x', 'Space', 'i', 'c', 'y', 'Space', 'c', 'u', 'b', '.', 'Space',
         'c', 'a', 'b', ',', 'Space', 'c', 'a', 'r', '.', 'Space', 'w', 'a', 'x', 'Space', 'c', 'r', 'y', 'Space',
         'a', 'r', 'c', 'Space', 'a', 'x', 'e', ',', 'Space', 'c', 'a', 'n', 'Space', 'c', 'a', 't', 'Space', 'c', 'o', 'd', 
      ],
      // Ex 9
      [
         'i', 'o', 'n', 'i', 'c', 'Space', 't', 'a', 'k', 'e', ',','Space', 'w', 'a', 'k', 'e', 'Space',
         'x', 'c', '.', ',', 'Space', 'e', 'a', 's', 'e', '.', 'Space', 'j', 'o', 'k', 'e', 'Space',
         's', 'o', 'r', 't', 'Space', 'w', 'o', 'o', 'd', '.', 'Space', 'o', 'x', 'y', 'e', 'Space',
         'u', 'n', 'd', 'e', 'r', 'Space', 'b', 'e', 'y', 'o', 'n', 'd', 
      ],
      // Ex 10
      [
         'e', 'x', 'e', 'r', 'c', 'i', 's', 'e', 'Space', 'h', 'a', 'i', 'r', 'Space', 'h', 'u', 'm', 'i', 'n', 'g','Space',
         'g', 'o', 'l', 'd', 'Space','x', 'e', 'n', 'o', 'n', 'Space', 'w', 'a', 'x', '.','Space', 'm', 'a', 'x', ',','Space',
         'w', 'o', 'r', 'm', 'Space', 't', 'o', 'r', 'n', 'Space', 's', 'o', 'u', 'l', 'Space', 'm', 'o', 'u', 'l', ';', 
      ]
   ],
   // Lesson 5
   [
      // Ex 1
      [
         'z', 'q', 'q', 'z', 'q', 'Space', 'q', 'z', 'q', 'q', 'q', 'Space', 'z', 'q', 'z', 'z', 'q', 'Space',
         'q', 'q', 'z', 'q', 'z', 'Space', 'z', 'z', 'q', 'z', 'q', 'Space', 'z', 'q', 'z', 'q', 'q', 'Space',
         'q', 'z', 'q', 'z', 'z', 'Space', 'z', 'q', 'z', 'q', 'z', 'Space', 'q', 'q', 'z', 'q', 'z', 'Space', 'z', 'z', 'q', 'z', 'q', 
      ],
      // Ex 2
      [
         'z', 'o', 'o', 'Space', 'q', 'u', 'i', 't', 'Space', 'z', 'i', 't', 'Space', 'z', 'o', 'o', 'm', 'Space',
         'q', 'u', 'i', 't', 'e', 'Space', 'q', 'u', 'i', 'c', 'k', 'Space', 'z', 'e', 's', 't', 'Space',
         'z', 'i', 'n', 'g', 'Space', 'q', 'u', 'a', 'k', 'e', 'Space', 'z', 'e', 'a', 'l', 'Space', 'z', 'a', 'n', 'y', 
      ],
      // Ex 3
      [
         'p', '[', '[', 'p', '[', 'Space', '[', 'p', '[', '[', 'p', 'Space', 'p', '[', 'p', 'p', '[', 'Space',
         '[', '[', 'p', '[', 'p', 'Space', 'p', 'p', '[', 'p', '[', 'Space', 'p', '[', 'p', '[', '[', 'Space',
         '[', 'p', '[', 'p', 'p', 'Space', 'p', '[', 'p', '[', 'p', 'Space', '[', '[', 'p', '[', 'p', 'Space', 'p', 'p', '[', 'p', '[', 
      ],
      // Ex 4
      [
         'p', 'u', 't', 'Space', 'o', 'p', 'a', 'q', 'u', 'e', 'Space', '[', 'p', 'u', 'p', 'Space', 'p', 'u', 'z', 'z', 'l', 'e', 'Space',
         '[', 'p', 'r', 'o', 'p', 'o', 's', 'a', 'l', 'Space', '[', 'p', 'r', 'o', 'm', 'p', 't', 'Space', 'p', 'l', 'a', 'n', 'Space',
         '[', 'p', 'o', 'm', 'p', 'Space', 'l', 'a', 'm', 'p', 
      ],
      // Ex 5
      [
         'z', ']', ']', 'q', 'p', 'Space', ']', 'p', ']', ']', 'z', 'Space', 'q', ']', 'z', ']', 'q', 'Space',
         'q', ']', 'p', ']', 'z', 'Space', 'p', 'z', ']', 'q', ']', 'Space', '[', ']', '[', ']', 'Space',
         ']', 'z', ']', '[', 'q', 'Space', 'p', ']', 'q', ']', '[', 'Space', ']', 'q', 'z', ']', '[', 'Space',
         'p', '[', ']', 'z', ']',  
      ],
       // Ex 6
       [
         '[','p', 'l', 'u', 's', ']', 'Space', '[', 'm', 'i', 'n', 'u', 's', ']', 'Space', '[', 'q', 'z', ']', ']', 'Space',
         'p', 'l', 'u', ']', 'z', 'Space', '[', 'a', ']', 'p', 'q', 'z', '[', ']', 'Space', '[', 'i', 'w', 'e', ']', 'Space',
         '[', 'q', 'u', ']', '[', 'z', ']', 'Space', 'p', 'q', '[', 'z', ']', 'Space', '[', ']', 'q', '[', ']',
      ],
      // Ex 7
      [
         '\'', '[', ']', '\'', '/', 'Space', ']', '\'', '/', '[', '\'', 'Space', '\'', ']', '\'', '[', '/', 'Space',
         '/', '[', '\'', ']', '\'', 'Space', '\'', '/', 'z', '\'', ']', 'Space', '\'', ']', '\'', 'q', 'Space',
         ']', '\'', 'p', '/', '\'', 'Space', '/', ']', '\'', 'q', '\'', 'Space', ']', '/', '\'', 'z', '\'', 'Space',
         '\'', '\'', ']', '/', ']', 
      ],
       // Ex 8
       [
         '[', 's', 'p', 'q', 'r', ']', 'Space', '/', 'q', 'r', '\'', 'p', 'r', 'Space', '[', 'p', 'r', 'i', 'z', ']', 'Space',
         ']', 'q', 'i', 'w', '[', 'Space', 'p', 'z', '\'', '[', 'w', 'Space', ']', '/', 'q','z', '\'', '[', 'Space',
         ']', '/', 'q', '\'', 'z', '[', 'Space', 'p', '\'', 'z', '/', 'q', '[', ']', 'Space', '[', 'q', 'z', ']', 
      ],
      // Ex 9
      [
         'p', 'l', 'u', 's', '/', 'm', 'i', 'n', 'u', 's', ';', 'Space', 'a', 'c', 'r', 'o', 'p', 'o', 'l', 'i', 's', '\'', 'Space',
         '[', 'a', 'p', 'p', 'a', 'l', 'l', ']', 'Space', 'm', 'i', 'l', 'e', 's', '/', 'h', 'o', 'u', 'r', 'Space',
         '[', 'c', 'm', '/', 's', 'e', 'c', ']', 'Space', 'p', 'e', 'r', '\'', 'Space', 'p', 'a', 'i', 'r',
      ],
      // Ex 10
      [
         'z', 'a', 'p', 'p', 'q', 'z', 'Space', '[', 'q', 'u', 'p', ']', '\'', '/', 'Space', '\'', 'q', '/', 'z', 'p', ']', 'Space',
         '/', '[', ']', 'q', 'p', 'z', '\'', '/', 'Space', '\'', '[', 'z', 'p', 'q', ']', 'Space', 'p', 'r', 'i', 'z', 'e', 'Space',
         '[', '\'', 'p', 'r', 'z', ']', 'Space', '\'', 'q', 'u', 'i', 'z', ']', 'Space', '/', ']', '[', '\'', 'Space', 'z', 'q', 'p', '\'',
      ], 
     
   ],
   // Lesson 6
   [
      // Ex 1
      [
         'm', 'e', 'a', 'n', 'Space', 'j', 'e', 'a', 'n', 's', 'Space', 'm', 'e', 'a', 'n', 'Space', 'j', 'e', 'a', 'n', 's', 'Space',
         'm', 'e', 'a', 'n', 'Space', 'j', 'e', 'a', 'n', 's', 'Space', 'm', 'e', 'a', 'n', 'Space', 'j', 'e', 'a', 'n', 's', 'Space',
         'm', 'e', 'a', 'n', 'Space', 'j', 'e', 'a', 'n', 's', 'Space', 'm', 'e', 'a', 'n',
      ],
      // Ex 2
      [
         'e', 'c', 'h', 'o', 'Space', 't', 'h', 'i', 'n', 'Space', 'e', 'c', 'h', 'o', 'Space', 't', 'h', 'i', 'n', 'Space',
         'e', 'c', 'h', 'o', 'Space', 't', 'h', 'i', 'n', 'Space', 'e', 'c', 'h', 'o', 'Space', 't', 'h', 'i', 'n', 'Space',
         'e', 'c', 'h', 'o', 'Space', 't', 'h', 'i', 'n', 'Space', 'e', 'c', 'h', 'o', 'Space', 't', 'h', 'i', 'n',
      ],
      // Ex 3
      [
         'd', 'i', 's', 'c', 'Space', 'd', 'i', 's', 'h', 'Space', 'd', 'i', 's', 'c', 'Space', 'd', 'i', 's', 'h', 'Space',
         'd', 'i', 's', 'c', 'Space', 'd', 'i', 's', 'h', 'Space', 'd', 'i', 's', 'c', 'Space', 'd', 'i', 's', 'h', 'Space',
         'd', 'i', 's', 'c', 'Space', 'd', 'i', 's', 'h', 'Space', 'd', 'i', 's', 'c', 'Space', 'd', 'i', 's', 'h', 
      ],
      // Ex 4
      [
         'd', 'a', 'l', 'e', 'Space', 'o', 'i', 'l', 's', 'Space', 'd', 'a', 'l', 'e', 'Space', 'o', 'i', 'l', 's', 'Space', 
         'd', 'a', 'l', 'e', 'Space', 'o', 'i', 'l', 's', 'Space', 'd', 'a', 'l', 'e', 'Space', 'o', 'i', 'l', 's', 'Space', 
         'd', 'a', 'l', 'e', 'Space', 'o', 'i', 'l', 's', 'Space', 'd', 'a', 'l', 'e', 'Space', 'o', 'i', 'l', 's',
      ],
      // Ex 5
      [
         'p', 'a', 't', 'h', 'Space', 'l', 'a', 's', 't', 'Space',  'p', 'a', 't', 'h', 'Space', 'l', 'a', 's', 't', 'Space',
         'p', 'a', 't', 'h', 'Space', 'l', 'a', 's', 't', 'Space',  'p', 'a', 't', 'h', 'Space', 'l', 'a', 's', 't', 'Space',
         'p', 'a', 't', 'h', 'Space', 'l', 'a', 's', 't', 'Space',  'p', 'a', 't', 'h', 'Space', 'l', 'a', 's', 't',
      ],
      // Ex 6
      [
         'l', 'a', 'n', 'd', 'Space', 'p', 'e', 't', 's', 'Space',  'l', 'a', 'n', 'd', 'Space', 'p', 'e', 't', 's', 'Space', 
         'l', 'a', 'n', 'd', 'Space', 'p', 'e', 't', 's', 'Space',   'l', 'a', 'n', 'd', 'Space', 'p', 'e', 't', 's', 'Space', 
         'l', 'a', 'n', 'd', 'Space', 'p', 'e', 't', 's', 'Space',   'l', 'a', 'n', 'd', 'Space', 'p', 'e', 't', 's', 
      ],
      // Ex 7
      [
         'm', 'e', 'a', 'n', 'Space', 'j', 'e', 'a', 'n', 's', 'Space', 'e', 'c', 'h', 'o', 'Space', 't', 'h', 'i', 'n', 'Space',
         'd', 'i', 's', 'c', 'Space', 'd', 'i', 's', 'h', 'Space', 'm', 'e', 'a', 'n', 'Space', 'j', 'e', 'a', 'n', 's', 'Space',
         'e', 'c', 'h', 'o', 'Space', 't', 'h', 'i', 'n', 'Space', 'd', 'i', 's', 'c', 'Space', 'd', 'i', 's', 'h',
      ],
      // Ex 8
      [
         'd', 'a', 'l', 'e', 'Space', 'o', 'i', 'l', 's', 'Space', 'p', 'a', 't', 'h', 'Space', 'l', 'a', 's', 't', 'Space',
         'l', 'a', 'n', 'd', 'Space', 'p', 'e', 't', 's', 'Space', 'd', 'a', 'l', 'e', 'Space', 'o', 'i', 'l', 's', 'Space', 
         'p', 'a', 't', 'h', 'Space', 'l', 'a', 's', 't', 'Space', 'l', 'a', 'n', 'd', 'Space', 'p', 'e', 't', 's',
      ],
      // Ex 9
      [
         'm', 'e', 'a', 'n', 'Space', 'j', 'e', 'a', 'n', 's', 'Space', 'e', 'c', 'h', 'o', 'Space', 't', 'h', 'i', 'n', 'Space',
         'd', 'i', 's', 'c', 'Space', 'd', 'i', 's', 'h', 'Space', 'd', 'a', 'l', 'e', 'Space', 'o', 'i', 'l', 's', 'Space', 
         'p', 'a', 't', 'h', 'Space', 'l', 'a', 's', 't', 'Space', 'l', 'a', 'n', 'd', 'Space', 'p', 'e', 't', 's', 
      ],
      // Ex 10
      [
         'm', 'e', 'a', 'n', 'Space', 'j', 'e', 'a', 'n', 's', 'Space', 'e', 'c', 'h', 'o', 'Space', 't', 'h', 'i', 'n', 'Space',
         'd', 'i', 's', 'c', 'Space', 'd', 'i', 's', 'h', 'Space', 'd', 'a', 'l', 'e', 'Space', 'o', 'i', 'l', 's', 'Space', 
         'p', 'a', 't', 'h', 'Space', 'l', 'a', 's', 't', 'Space', 'l', 'a', 'n', 'd', 'Space', 'p', 'e', 't', 's',
      ]
   ],
   // Lesson 7
   [
      // Ex 1
      [
         'p', 'o', 'u', 'n', 'd', 'Space', 'p', 'i', 't', 's', 'Space',  'p', 'o', 'u', 'n', 'd', 'Space', 'p', 'i', 't', 's', 'Space', 
         'p', 'o', 'u', 'n', 'd', 'Space', 'p', 'i', 't', 's', 'Space',  'p', 'o', 'u', 'n', 'd', 'Space', 'p', 'i', 't', 's', 'Space', 
         'p', 'o', 'u', 'n', 'd', 'Space', 'p', 'i', 't', 's', 'Space',  'p', 'o', 'u', 'n', 'd', 'Space', 'p', 'i', 't', 's',
      ],
      // Ex 2
      [
         'r', 'y', 'a', 's', 'Space', 'e', 'b', 'b', 'e', 'd', 'Space', 'r', 'y', 'a', 's', 'Space', 'e', 'b', 'b', 'e', 'd', 'Space',
         'r', 'y', 'a', 's', 'Space', 'e', 'b', 'b', 'e', 'd', 'Space', 'r', 'y', 'a', 's', 'Space', 'e', 'b', 'b', 'e', 'd', 'Space',
         'r', 'y', 'a', 's', 'Space', 'e', 'b', 'b', 'e', 'd', 'Space', 'r', 'y', 'a', 's', 'Space', 'e', 'b', 'b', 'e', 'd',
      ],
      // Ex 3
      [
         'r', 'i', 's', 'k', 'Space', 'r', 'e', 'e', 'k', 's', 'Space',  'r', 'i', 's', 'k', 'Space', 'r', 'e', 'e', 'k', 's', 'Space',
         'r', 'i', 's', 'k', 'Space', 'r', 'e', 'e', 'k', 's', 'Space',  'r', 'i', 's', 'k', 'Space', 'r', 'e', 'e', 'k', 's', 'Space',
         'r', 'i', 's', 'k', 'Space', 'r', 'e', 'e', 'k', 's', 'Space',  'r', 'i', 's', 'k', 'Space', 'r', 'e', 'e', 'k', 's',
      ],
      // Ex 4
      [
         'l', 'e', 'a', 'k', 'Space', 'l', 'e', 'n', 's', 'Space', 'l', 'e', 'a', 'k', 'Space', 'l', 'e', 'n', 's', 'Space', 
         'l', 'e', 'a', 'k', 'Space', 'l', 'e', 'n', 's', 'Space', 'l', 'e', 'a', 'k', 'Space', 'l', 'e', 'n', 's', 'Space', 
         'l', 'e', 'a', 'k', 'Space', 'l', 'e', 'n', 's', 'Space', 'l', 'e', 'a', 'k', 'Space', 'l', 'e', 'n', 's',
      ],
      // Ex 5
      [
         'l', 'e', 'a', 'v', 'e', 'Space', 'f', 'l', 'u', 'x', 'Space',  'l', 'e', 'a', 'v', 'e', 'Space', 'f', 'l', 'u', 'x', 'Space',
         'l', 'e', 'a', 'v', 'e', 'Space', 'f', 'l', 'u', 'x', 'Space',  'l', 'e', 'a', 'v', 'e', 'Space', 'f', 'l', 'u', 'x', 'Space',
         'l', 'e', 'a', 'v', 'e', 'Space', 'f', 'l', 'u', 'x', 'Space',  'l', 'e', 'a', 'v', 'e', 'Space', 'f', 'l', 'u', 'x', 
      ],
      // Ex 6
      [
         'l', 'e', 'i', 's', 'Space', 'l', 'e', 'a', 'f', 'Space', 'l', 'e', 'i', 's', 'Space', 'l', 'e', 'a', 'f', 'Space',
         'l', 'e', 'i', 's', 'Space', 'l', 'e', 'a', 'f', 'Space', 'l', 'e', 'i', 's', 'Space', 'l', 'e', 'a', 'f', 'Space',
         'l', 'e', 'i', 's', 'Space', 'l', 'e', 'a', 'f', 'Space', 'l', 'e', 'i', 's', 'Space', 'l', 'e', 'a', 'f',
      ],
      // Ex 7
      [
         'p', 'o', 'u', 'n', 'd', 'Space', 'p', 'i', 't', 's', 'Space', 'r', 'y', 'a', 's', 'Space', 
         'e', 'b', 'b', 'e', 'd', 'Space', 'r', 'i', 's', 'k', 'Space', 'r', 'e', 'e', 'k', 's', 'Space',
         'p', 'o', 'u', 'n', 'd', 'Space', 'p', 'i', 't', 's', 'Space', 'r', 'y', 'a', 's', 'Space', 
         'e', 'b', 'b', 'e', 'd', 'Space', 'r', 'i', 's', 'k', 'Space', 'r', 'e', 'e', 'k', 's',
      ],
      // Ex 8
      [
         'l', 'e', 'a', 'k', 'Space', 'l', 'e', 'n', 's', 'Space',  'l', 'e', 'a', 'v', 'e', 'Space', 
         'f', 'l', 'u', 'x', 'Space',  'l', 'e', 'i', 's', 'Space', 'l', 'e', 'a', 'f', 'Space',
         'l', 'e', 'a', 'k', 'Space', 'l', 'e', 'n', 's', 'Space',  'l', 'e', 'a', 'v', 'e', 'Space', 
         'f', 'l', 'u', 'x', 'Space',  'l', 'e', 'i', 's', 'Space', 'l', 'e', 'a', 'f',
      ],
      // Ex 9
      [
         'p', 'o', 'u', 'n', 'd', 'Space', 'p', 'i', 't', 's', 'Space', 'r', 'y', 'a', 's', 'Space', 
         'e', 'b', 'b', 'e', 'd', 'Space', 'r', 'i', 's', 'k', 'Space', 'r', 'e', 'e', 'k', 's', 'Space',
         'l', 'e', 'a', 'k', 'Space', 'l', 'e', 'n', 's', 'Space',  'l', 'e', 'a', 'v', 'e', 'Space', 
         'f', 'l', 'u', 'x', 'Space',  'l', 'e', 'i', 's', 'Space', 'l', 'e', 'a', 'f',
      ],
      // Ex 10
      [
         'p', 'o', 'u', 'n', 'd', 'Space', 'p', 'i', 't', 's', 'Space', 'r', 'y', 'a', 's', 'Space', 
         'e', 'b', 'b', 'e', 'd', 'Space', 'r', 'i', 's', 'k', 'Space', 'r', 'e', 'e', 'k', 's', 'Space',
         'l', 'e', 'a', 'k', 'Space', 'l', 'e', 'n', 's', 'Space',  'l', 'e', 'a', 'v', 'e', 'Space', 
         'f', 'l', 'u', 'x', 'Space',  'l', 'e', 'i', 's', 'Space', 'l', 'e', 'a', 'f',
      ]
   ],
   // Lesson 8
   [
      // Ex 1
      [
         'l', 'i', 'e', 'f', 'Space', 'l', 'a', 'z', 'y', 'Space','l', 'i', 'e', 'f', 'Space', 'l', 'a', 'z', 'y', 'Space',
         'l', 'i', 'e', 'f', 'Space', 'l', 'a', 'z', 'y', 'Space', 'l', 'i', 'e', 'f', 'Space', 'l', 'a', 'z', 'y', 'Space',
         'l', 'i', 'e', 'f', 'Space', 'l', 'a', 'z', 'y', 'Space', 'l', 'i', 'e', 'f', 'Space', 'l', 'a', 'z', 'y',
      ],
      // Ex 2
      [
         'k', 'e', 'n', 'o', 'Space', 'q', 'u', 'a', 'c', 'k', 'Space', 'k', 'e', 'n', 'o', 'Space', 'q', 'u', 'a', 'c', 'k', 'Space',
         'k', 'e', 'n', 'o', 'Space', 'q', 'u', 'a', 'c', 'k', 'Space', 'k', 'e', 'n', 'o', 'Space', 'q', 'u', 'a', 'c', 'k', 'Space',
         'k', 'e', 'n', 'o', 'Space', 'q', 'u', 'a', 'c', 'k', 'Space', 'k', 'e', 'n', 'o', 'Space', 'q', 'u', 'a', 'c', 'k', 
      ],
      // Ex 3
      [
         'k', 'n', 'i', 'f', 'e', 'Space', 'j', 'a', 'c', 'k', 'Space', 'k', 'n', 'i', 'f', 'e', 'Space', 'j', 'a', 'c', 'k', 'Space',
         'k', 'n', 'i', 'f', 'e', 'Space', 'j', 'a', 'c', 'k', 'Space', 'k', 'n', 'i', 'f', 'e', 'Space', 'j', 'a', 'c', 'k', 'Space',
         'k', 'n', 'i', 'f', 'e', 'Space', 'j', 'a', 'c', 'k', 'Space', 'k', 'n', 'i', 'f', 'e', 'Space', 'j', 'a', 'c', 'k', 
      ],
      // Ex 4
      [
         'c', 'h', 'a', 'p', 'Space', 's', 'o', 'c', 'k', 'Space', 'c', 'h', 'a', 'p', 'Space', 's', 'o', 'c', 'k', 'Space', 
         'c', 'h', 'a', 'p', 'Space', 's', 'o', 'c', 'k', 'Space', 'c', 'h', 'a', 'p', 'Space', 's', 'o', 'c', 'k', 'Space', 
         'c', 'h', 'a', 'p', 'Space', 's', 'o', 'c', 'k', 'Space', 'c', 'h', 'a', 'p', 'Space', 's', 'o', 'c', 'k',
      ],
      // Ex 5
      [
         'k', 'e', 'y', 's', 'Space', 'o', 'b', 'e', 'y', 'Space', 'k', 'e', 'y', 's', 'Space', 'o', 'b', 'e', 'y', 'Space', 
         'k', 'e', 'y', 's', 'Space', 'o', 'b', 'e', 'y', 'Space',  'k', 'e', 'y', 's', 'Space', 'o', 'b', 'e', 'y', 'Space', 
         'k', 'e', 'y', 's', 'Space', 'o', 'b', 'e', 'y', 'Space',  'k', 'e', 'y', 's', 'Space', 'o', 'b', 'e', 'y',
      ],
      // Ex 6
      [
         'm', 'e', 'n', 's', 'Space', 'c', 'a', 'p', 's', 'Space', 'm', 'e', 'n', 's', 'Space', 'c', 'a', 'p', 's', 'Space', 
         'm', 'e', 'n', 's', 'Space', 'c', 'a', 'p', 's', 'Space', 'm', 'e', 'n', 's', 'Space', 'c', 'a', 'p', 's', 'Space',
         'm', 'e', 'n', 's', 'Space', 'c', 'a', 'p', 's', 'Space', 'm', 'e', 'n', 's', 'Space', 'c', 'a', 'p', 's',
      ],
      // Ex 7
      [
         'l', 'i', 'e', 'f', 'Space', 'l', 'a', 'z', 'y', 'Space', 'k', 'e', 'n', 'o', 'Space', 'q', 'u', 'a', 'c', 'k', 'Space',
         'k', 'n', 'i', 'f', 'e', 'Space', 'j', 'a', 'c', 'k', 'Space', 'l', 'i', 'e', 'f', 'Space', 'l', 'a', 'z', 'y', 'Space',
         'k', 'e', 'n', 'o', 'Space', 'q', 'u', 'a', 'c', 'k', 'Space', 'k', 'n', 'i', 'f', 'e', 'Space', 'j', 'a', 'c', 'k',
      ],
      // Ex 8
      [
         'c', 'h', 'a', 'p', 'Space', 's', 'o', 'c', 'k', 'Space', 'k', 'e', 'y', 's', 'Space', 'o', 'b', 'e', 'y', 'Space',
         'm', 'e', 'n', 's', 'Space', 'c', 'a', 'p', 's', 'Space', 'c', 'h', 'a', 'p', 'Space', 's', 'o', 'c', 'k', 'Space',
         'k', 'e', 'y', 's', 'Space', 'o', 'b', 'e', 'y', 'Space', 'm', 'e', 'n', 's', 'Space', 'c', 'a', 'p', 's', 
      ],
      // Ex 9
      [
         'l', 'i', 'e', 'f', 'Space', 'l', 'a', 'z', 'y', 'Space', 'k', 'e', 'n', 'o', 'Space', 'q', 'u', 'a', 'c', 'k', 'Space',
         'k', 'n', 'i', 'f', 'e', 'Space', 'j', 'a', 'c', 'k', 'Space',  'c', 'h', 'a', 'p', 'Space', 's', 'o', 'c', 'k', 'Space',
         'k', 'e', 'y', 's', 'Space', 'o', 'b', 'e', 'y', 'Space', 'm', 'e', 'n', 's', 'Space', 'c', 'a', 'p', 's',
      ],
      // Ex 10
      [
         'l', 'i', 'e', 'f', 'Space', 'l', 'a', 'z', 'y', 'Space', 'k', 'e', 'n', 'o', 'Space', 'q', 'u', 'a', 'c', 'k', 'Space',
         'k', 'n', 'i', 'f', 'e', 'Space', 'j', 'a', 'c', 'k', 'Space',  'c', 'h', 'a', 'p', 'Space', 's', 'o', 'c', 'k', 'Space',
         'k', 'e', 'y', 's', 'Space', 'o', 'b', 'e', 'y', 'Space', 'm', 'e', 'n', 's', 'Space', 'c', 'a', 'p', 's',
      ]
   ],
   // Lesson 9
   [
      // Ex 1
      [
         'v', 'i', 'l', 'e', 'Space', 'f', 'i', 'l', 'e', 'Space', 'v', 'i', 'l', 'e', 'Space', 'f', 'i', 'l', 'e', 'Space',
         'v', 'i', 'l', 'e', 'Space', 'f', 'i', 'l', 'e', 'Space', 'v', 'i', 'l', 'e', 'Space', 'f', 'i', 'l', 'e', 'Space',
         'v', 'i', 'l', 'e', 'Space', 'f', 'i', 'l', 'e', 'Space', 'v', 'i', 'l', 'e', 'Space', 'f', 'i', 'l', 'e',
      ],
      // Ex 2
      [
         'v', 'e', 'n', 't', 'Space', 'v', 'a', 'l', 'e', 'Space', 'v', 'e', 'n', 't', 'Space', 'v', 'a', 'l', 'e', 'Space',
         'v', 'e', 'n', 't', 'Space', 'v', 'a', 'l', 'e', 'Space', 'v', 'e', 'n', 't', 'Space', 'v', 'a', 'l', 'e', 'Space',
         'v', 'e', 'n', 't', 'Space', 'v', 'a', 'l', 'e', 'Space', 'v', 'e', 'n', 't', 'Space', 'v', 'a', 'l', 'e',
      ],
      // Ex 3
      [
         'b', 'a', 'c', 'k', 'Space', 'b', 'a', 'n', 's', 'Space',  'b', 'a', 'c', 'k', 'Space', 'b', 'a', 'n', 's', 'Space',
         'b', 'a', 'c', 'k', 'Space', 'b', 'a', 'n', 's', 'Space',  'b', 'a', 'c', 'k', 'Space', 'b', 'a', 'n', 's', 'Space',
         'b', 'a', 'c', 'k', 'Space', 'b', 'a', 'n', 's', 'Space',  'b', 'a', 'c', 'k', 'Space', 'b', 'a', 'n', 's',
      ],
      // Ex 4
      [
         'b', 'a', 'g', 's', 'Space', 't', 'i', 'n', 's', 'Space', 'b', 'a', 'g', 's', 'Space', 't', 'i', 'n', 's', 'Space',
         'b', 'a', 'g', 's', 'Space', 't', 'i', 'n', 's', 'Space', 'b', 'a', 'g', 's', 'Space', 't', 'i', 'n', 's', 'Space',
         'b', 'a', 'g', 's', 'Space', 't', 'i', 'n', 's', 'Space', 'b', 'a', 'g', 's', 'Space', 't', 'i', 'n', 's',
      ],
      // Ex 5
      [
         'g', 'i', 'f', 't', 'Space', 'g', 'r', 'i', 't', 'Space', 'g', 'i', 'f', 't', 'Space', 'g', 'r', 'i', 't', 'Space',
         'g', 'i', 'f', 't', 'Space', 'g', 'r', 'i', 't', 'Space', 'g', 'i', 'f', 't', 'Space', 'g', 'r', 'i', 't', 'Space',
         'g', 'i', 'f', 't', 'Space', 'g', 'r', 'i', 't', 'Space', 'g', 'i', 'f', 't', 'Space', 'g', 'r', 'i', 't',
      ],
      // Ex 6
      [
         'h', 'e', 'r', 'b', 'Space', 'h', 'a', 'n', 'd', 'Space', 'h', 'e', 'r', 'b', 'Space', 'h', 'a', 'n', 'd', 'Space',
         'h', 'e', 'r', 'b', 'Space', 'h', 'a', 'n', 'd', 'Space', 'h', 'e', 'r', 'b', 'Space', 'h', 'a', 'n', 'd', 'Space',
         'h', 'e', 'r', 'b', 'Space', 'h', 'a', 'n', 'd', 'Space', 'h', 'e', 'r', 'b', 'Space', 'h', 'a', 'n', 'd',
      ],
      // Ex 7
      [
         'v', 'i', 'l', 'e', 'Space', 'f', 'i', 'l', 'e', 'Space', 'v', 'e', 'n', 't', 'Space', 'v', 'a', 'l', 'e', 'Space',
         'b', 'a', 'c', 'k', 'Space', 'b', 'a', 'n', 's', 'Space', 'v', 'i', 'l', 'e', 'Space', 'f', 'i', 'l', 'e', 'Space', 
         'v', 'e', 'n', 't', 'Space', 'v', 'a', 'l', 'e', 'Space', 'b', 'a', 'c', 'k', 'Space', 'b', 'a', 'n', 's', 
      ],
      // Ex 8
      [
         'b', 'a', 'g', 's', 'Space', 't', 'i', 'n', 's', 'Space', 'g', 'i', 'f', 't', 'Space', 'g', 'r', 'i', 't', 'Space', 
         'h', 'e', 'r', 'b', 'Space', 'h', 'a', 'n', 'd', 'Space', 'b', 'a', 'g', 's', 'Space', 't', 'i', 'n', 's', 'Space', 
         'g', 'i', 'f', 't', 'Space', 'g', 'r', 'i', 't', 'Space', 'h', 'e', 'r', 'b', 'Space', 'h', 'a', 'n', 'd', 
      ],
      // Ex 9
      [
         'v', 'i', 'l', 'e', 'Space', 'f', 'i', 'l', 'e', 'Space', 'v', 'e', 'n', 't', 'Space', 'v', 'a', 'l', 'e', 'Space',
         'b', 'a', 'c', 'k', 'Space', 'b', 'a', 'n', 's', 'Space', 'b', 'a', 'g', 's', 'Space', 't', 'i', 'n', 's', 'Space', 
         'g', 'i', 'f', 't', 'Space', 'g', 'r', 'i', 't', 'Space', 'h', 'e', 'r', 'b', 'Space', 'h', 'a', 'n', 'd',
      ],
      // Ex 10
      [
         'v', 'i', 'l', 'e', 'Space', 'f', 'i', 'l', 'e', 'Space', 'v', 'e', 'n', 't', 'Space', 'v', 'a', 'l', 'e', 'Space',
         'b', 'a', 'c', 'k', 'Space', 'b', 'a', 'n', 's', 'Space', 'b', 'a', 'g', 's', 'Space', 't', 'i', 'n', 's', 'Space', 
         'g', 'i', 'f', 't', 'Space', 'g', 'r', 'i', 't', 'Space', 'h', 'e', 'r', 'b', 'Space', 'h', 'a', 'n', 'd',
      ]
   ],
   // Lesson 10
   [
      // Ex 1
      [
         '8', '7', '8', '7', '7', 'Space', '7', '8', '7', '8', '7', 'Space', '8', '8', '7', '8', '7', 'Space',
         '7', '8', '8', '7', '8', 'Space', '8', '7', '8', '8', '7', 'Space', '7', '8', '7', '7', '8', 'Space', 
         '8', '8', '7', '8', '7', 'Space', '7', '7', '8', '7', '8', 'Space', '7', '8', '7', '8', '8', 'Space',         
         '7', '7', '8', '7', '8',
      ],
      // Ex 2-------
      [
         '5', '6', '6', '5', '6', 'Space', '6', '5', '6', '6', '5', 'Space', '5', '6', '5', '5', '6', 'Space',
         '6', '5', '6', '5', '5', 'Space', '5', '6', '5', '6', '5', 'Space', '6', '6', '5', '6', '5', 'Space',
         '6', '6', '5', '6', '5', 'Space', '5', '5', '6', '5', '6', 'Space', '5', '6', '5', '6', '6', 'Space',        
         '5', '5', '6', '5', '6', 
      ],
      // Ex 3
      [
         '8', 'n', 'e', 'm', 'o', '6', 'Space', '7', 'j', 'a', 'c', 'k', '5', 'Space', '5', 'e', 'a','c', 'h', '8', 'Space',
         '6', 't', 'h', 'i', 'n', '7', 'Space', '8', 'd', 'i', 's', 'c', '6', 'Space', '7', 'd', 'i', 's', 'h', '5', 'Space',
         '6', 'd', 'a', 'e', 'l', '8', 'Space', '5', 'o', 'i', 'l', '7', 'Space', '6', 'p', 'a', 't', 'h', '8', 'Space',
         '5', 'a', 'c', 'e', '7',
      ],
      // Ex 4
      [
         '9', '9', '0', '0', '9', 'Space', '9', '0', '0', '9', '0', 'Space', '0', '9', '9', '0', '9', 'Space',
         '0', '9', '0', '9', '0', 'Space', '9', '9', '0', '0', '9', 'Space', '9', '0', '9', '9', '0', 'Space',
         '9', '0', '9', '9', '0', 'Space', '0', '0', '9', '0', '9', 'Space', '0', '9', '0', '9', '0', 'Space',
         '0', '9', '0', '0', '9', 
      ],
      // Ex 5
      [
         '9', 'l', 'u', 'x', '0', 'Space', '0', 'v', 'a', 'l', 'v', 'e', '9', 'Space', '0', 'k', 'e', 'n', 'z', 'o', '9', 'Space',
         '9', 'n', 'i', 'c', 'o', '0', 'Space', '9', 'l', 'a', 'n', 'y', '9', 'Space', '0', 'l', 'e', 'a', 'f', '9', 'Space',
         '0', 'e', 'l', 'i', '0', 'Space', '9', 'q', 'u', 'i', 'c', 'k', 'y', '0', 
      ],
      // Ex 6
      [
         '3', '4', '4', '3', '4', 'Space', '4', '3', '4', '4', '3', 'Space', '3', '4', '3', '3', '4', 'Space',
         '4', '4', '3', '4', '3', 'Space', '3', '3', '4', '3', '4', 'Space', '3', '4', '3', '4', '4', 'Space',
         '4', '3', '4', '3', '3', 'Space', '3', '4', '3', '4', '3', 'Space', '4', '4', '3', '4', '3', 'Space',
         '3', '3', '4', '3', '4', 
      ],
      // Ex 7
      [
         '3', 'p', 'a', 't', 'c', 'h', '4', 'Space', '3', 'k', 'i', 'd', '\'', 's',  '4', 'Space',
         '4', 's', 'o', 'c', 'k', 's', '4', 'Space', '4', 'm', 'e', 'n', '\'', 's', '3', 'Space', 
         '3', 'c', 'a', 'p', 's', '3', 'Space', '4', 'f', 'i', 'v', 'e', '3', 'Space', '3', 'o', 'b', 'e', 'y', '4', 'Space',
         '4', 'p', 'a', 'n', '3',
      ],
      // Ex 8
      [
         '1', '2', '2', '1', '1', 'Space', '2', '1', '1', '2', '1', 'Space', '1', '2', '1', '1', '2', 'Space', 
         '1', '1', '2', '2', '1', 'Space', '2', '1', '2', '2', '1', 'Space', '2', '2', '1', '2', '1', 'Space',
         '1', '2', '2', '1', '2', 'Space', '1', '1', '2', '1', '2', 'Space', '2', '2', '1', '1', '2', 'Space', 
         '2', '1', '2', '1', '2', 
      ],
      // Ex 9
      [
         '1', 'c', 'a', 'n', '2', 'Space', '2', 'j', 'o', 'i', 's', '1', 'Space', '2', 'd', 'o', 'c', 'k', '1', 'Space', 
         '1', 'w', 'o', 'r', 'l', 's', '2', 'Space', '2', 't', 'o', 'w', 'n', '1', 'Space', '1', 'm', 'e', 'j', 'o', 'r', '2', 'Space', 
         '2', 'z', 'o', 'n', 'e', '1', 'Space', '1', 'm', 'e', 'l', 'o', 'd', 'y', '2',
      ],
      // Ex 10
      [
         '5', 'f', 'o', 'r', 'c', 'e', '2', 'Space', '3', 'q', 'u', 'e', 'e', 'n', '9', 'Space',
         '1', 'm', 'i', 'd', 'l', 'e', '6', 'Space', '4', 'c', 'a', 'n', 'd', 'l', 'e', '8', 
         'Space', '7', 'w', 'i', 'n', 'e', '1', 'Space', '3', 'h', 'o', 't', 'e', 'l', '1', 
         'Space', '9', 's', 'u', 'i', 't', '6', 'Space', '2', '[', 'n', 'o', 'w', ']','5',
      ]
   ],
   // Lesson --
   [
      // Ex 1
      [
         'C', 'o', 'm', 'P', 'a', 'n', 'Y', 'Space', 'H', 'o', 'r', 'S', 'e', 'S', 'Space', 'H', 'a', 'p', 'p', 'Y', 'Space',
         'S', 'p', 'e', 'C', 'i', 'a', 'L', 'Space', 'V', 'e', 'n', 'T', 'u', 'r', 'E', 'Space', 'C', 'h', 'a', 'N', 'g', 'E', 'Space',
         'R', 'e', 't', 'U', 'r', 'N', 'Space', 'K', 'n', 'i', 'G', 'h', 'T', 
      ],
      // Ex 2-------
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 3
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 4
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 5
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 6
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 7
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 8
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 9
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 10
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ]
   ],
   [
      // Ex 5
      [
         'g', 'a', 'f', 'f', ';', 'Space', 'h', 'a', 'l', 'l', 'Space', 'h', 'a', 'l', 'd', 'Space', 's', 'a', 'g', 'a', 'Space',
         'h', 'a', 's', 'h', ';', 'Space', 's', 'a', 's', 'h', 'Space', 'g', 'a', 'l', 'l', 'Space', 
         'f', 'l', 'a', 'g', ';','Space', 'h', 'a','s','Space', 'd','a', 's', 'h','Space', 'h', 'a', 'l', 'f'
        ],
        // Ex 6
        [
         'r', 't', 't', 'r', 't','Space', 't', 'r', 't','t','r', 'Space', 'r', 't','r', 'r', 't', 'Space', 't', 't','r', 't','r', 'Space',
         'r', 'r', 't', 'r', 't', 'Space', 'r', 't', 'r', 't', 't', 'Space', 't','r', 't', 'r', 'r', 'Space', 
         'r', 't', 'r', 't', 'r', 'Space', 't', 't', 'r', 't','r', 'Space','r', 'r', 't', 'r', 't'
        ],
        // Ex 7
        [
         'a', 'r', 't', 'Space', 't', 'a', 'd', 'Space', 'g', 'a', 'r', 'Space',  'a', 't', 'Space',
         's', 'a', 't', 'Space','r', 'a',  'g', 'Space', 't', 'a', 'g', ';', 'Space', 'f', 'a', 'r','Space', 'j', 'a', 'r', 'Space',
         't', 'a', 'r', 'Space', 'r', 'a', 'h', 'Space', 'h', 'a', 't', 'Space', 'r', 'a', 't', 'Space', 'r', 'a', 'g', ';', 'Space', 't', 'a', 't',
        ],
 
   ]

];

export const lessonHeader = [
   [ // Lesson 1
      'Основна позиція пальців - A S D F та J K L ;'
   ],
   [ // Lesson 2
     'Основна позиція + верхній та середній раядки вказівних пальців'
   ],
   [ // Lesson 3
     'Основна позиція + нижній раядoк вказівних пальців'
   ],
   [ // Lesson 4
      'Клавіші середнього та безіменних пальців'
   ],
   [ // Lesson 5
      'Клавіші мізинців'
   ],
   [ // Lesson 6
      'Вчимося печаті слів'
   ],
   [ // Lesson 7
      'Вчимося печаті слів 2'
   ],
   [ // Lesson 8
      'Вчимося печаті слів 3'
   ],
   [ // Lesson 9
      'Вчимося печаті слів 4'
   ],
   [ // Lesson 10
      'Верхній рядок - цифри'
   ],
];
export const lessonHeaderEN = [
   [ // Lesson 1
      'Main finger position - A S D F and J K L ;'
   ],
   [ // Lesson 2
     'Main position + upper and middle rows of index fingers'
   ],
   [ // Lesson 3
     'Main position + bottom row of index fingers'
   ],
   [ // Lesson 4
      'Middle and ring finger keys'
   ],
   [ // Lesson 5
      'Pinky keys'
   ],
   [ // Lesson 6
      'Learning to type words'
   ],
   [ // Lesson 7
      'Learning to type words 2'
   ],
   [ // Lesson 8
      'Learning to type words 3'
   ],
   [ // Lesson 9
      'Learning to type words 4'
   ],
   [ // Lesson 10
      'Top row - numbers'
   ],
];
export const lessonHeaderAR = [
   [ // Lesson 1
      'وضع الأصابع - A S D F و J K L ;'
   ],
   [ // Lesson 2
     'الموقع الرئيسي+ الصفوف العلويه والمتوسطه لإصبع السبابه'
   ],
   [ // Lesson 3
     'الموقع الرئيسي+ الصفوف السفلي من إصبع السبابه'
   ],
   [ // Lesson 4
      'مفاتيح إصبح الوسط وإصبع الخاتم'
   ],
   [ // Lesson 5
      'مفاتيح الخنصر'
   ],
   [ // Lesson 6
      'تعلم طباعة الكلمات'
   ],
   [ // Lesson 7
      'تعلم طباعة الكلمات 2'
   ],
   [ // Lesson 8
      'تعلم طباعة الكلمات 3'
   ],
   [ // Lesson 9
      'تعلم طباعة الكلمات 4'
   ],
   [ // Lesson 10
   'الصف العلوي-أرقام'
],
];

export const exerciseDesc = [
   // Lesson 1 Основна позиція пальців
    [
      // Ex 1
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'J K'
      ],
      // Ex 2
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'D F'  
      ],
      // Ex 3
      [
         'В цієї вправі:', 'Закріплюємо клавіші', 'J K  та D F'  
      ],
      // Ex 4
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'J K  та D F'      
      ],
      // Ex 5
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'L ;'
      ],
      // Ex 6
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'L F   K J  та  D ;'
      ],
      // Ex 7
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'A  S'  
      ],
      // Ex 8
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'A L F  D S K  та  ;'  
      ],
      // Ex 9
      [
         'В цієї вправі:', 'Закріплюємо клавіші', 'A L F  D S K  та  ;'      
      ],
      // Ex 10
      [
         'ОСТАННЯ ВПРАВА УРОКУ', 'Повторюємо усі клавіші', 'A S D F та J K L ;'  
      ]
   ],
 //  Lesson 2 Основна позиція + верхній та середній раяд вказівних пальців
   [
        // Ex 1
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'G H'
      ],
      // Ex 2
      [
         'В цієї вправі:', 'Закріплюємо клавіші', 'G H та A F L D S  ;'  
      ],
      // Ex 3
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'R T'  
      ],
      // Ex 4
      [
         'В цієї вправі:', 'Закріплюємо клавіші', 'R T  та  G H та інші'      
      ],
      // Ex 5
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'R T G H та інші'
      ],
      // Ex 6
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'A S D F G H J K L ; R T'
      ],
      // Ex 7
      [
         'В цієї вправі:', 'Закріплюємо клавіші', 'R T та інші'  
      ],
      // Ex 8
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'Y U'  
      ],
      // Ex 9
      [
         'В цієї вправі:', 'Закріплюємо клавіші', 'Y U та інші'      
      ],
      // Ex 10
      [
         'ОСТАННЯ ВПРАВА УРОКУ', 'Повторюємо усі клавіші', 'Y U R T G H та інші'  
      ]
       
     ], 
// Lesson 3 + нижній ряд вказівних пальйів
    [
      // Ex 1
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'V B'
      ],
      // Ex 2
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'V B ...'  
      ],
      // Ex 3
      [
         'В цієї вправі:', 'Закріплюємо клавіші', 'V B та інші'  
      ],
      // Ex 4
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'N M'      
      ],
      // Ex 5
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'N M та інші'
      ],
      // Ex 6
      [
         'В цієї вправі:', 'Закріплюємо клавіші', 'N M та інші'
      ],
      // Ex 7
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'V N M T Y U A S'  
      ],
      // Ex 8
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'V B N M R T Y U'  
      ],
      // Ex 9
      [
         'В цієї вправі:', 'Повторюємо клавіші', '; V N M T Y U A S'      
      ],
      // Ex 10
      [
         'ОСТАННЯ ВПРАВА УРОКУ', 'Повторюємо усі клавіші', 'V B N M R T Y U та J ;'  
      ]
   ],
   // Lesson 4 Середній та безімяний пальці
   [
      // Ex 1
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'I O'
      ],
      // Ex 2
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'I O'  
      ],
      // Ex 3
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'W E'  
      ],
      // Ex 4
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'W E'      
      ],
      // Ex 5
      [
         'В цієї вправі:', 'Bивчаймо клавіші', ', .'
      ],
      // Ex 6
      [
         'В цієї вправі:', 'Повторюємо клавіші', ', .'
      ],
      // Ex 7
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'X C'  
      ],
      // Ex 8
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'X C та інші'  
      ],
      // Ex 9
      [
         'В цієї вправі:', 'Закріплюємо клавіші', 'X C та інші'      
      ],
      // Ex 10
      [
         'ОСТАННЯ ВПРАВА УРОКУ', 'Повторюємо усі клавіші', 'I O W E X C , .'  
      ]
   ],
   // Lesson 5
   [
      // Ex 1
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'Z Q'
      ],
      // Ex 2
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'Z Q'  
      ],
      // Ex 3
      [
         'В цієї вправі:', 'Bивчаймо клавіші', 'P ['  
      ],
      // Ex 4
      [
         'В цієї вправі:', 'Повторюємо клавіші', 'P [ Z Q'      
      ],
      // Ex 5
      [
         'В цієї вправі:', 'Bивчаймо клавішу', ']'  
      ],
      // Ex 6
      [
         'В цієї вправі:', 'Повторюємо клавішу', ']  та інші'
      ],
      // Ex 7
      [
         'В цієї вправі:', 'Bивчаймо клавіші', '/ \''
      ],
      
      // Ex 8
      [
         'В цієї вправі:', 'Закріплюємо клавіші', '/ \' [ ] Z Q P'  
      ],
      
      // Ex 9
      [
         'В цієї вправі:', 'Закріплюємо клавіші', '\' [ ] та інші'      
      ],
      // Ex 10
      [
         'ОСТАННЯ ВПРАВА УРОКУ', 'Повторюємо усі клавіші', 'Z Q P / \' [ ]'  
      ]
   ],
   // Lesson 6
   [
      // Ex 1
      [
         'В цієї вправі:', 'Тренеруємо слова', 'MEAN JEANS'
      ],
       
      // Ex 2
      [
         'В цієї вправі:', 'Тренеруємо слова', 'ECHO THIN'  
      ],
      // Ex 3
      [
         'В цієї вправі:', 'Тренеруємо слова', 'DISC DISH'  
      ],
      // Ex 4
      [
         'В цієї вправі:', 'Тренеруємо слова', 'DALE OILS'      
      ],
      // Ex 5
      [
         'В цієї вправі:', 'Тренеруємо слова', 'PATH LAST'  
      ],
      // Ex 6
      [
         'В цієї вправі:', 'Тренеруємо слова', 'LAND PETS'
      ],
      // Ex 7
      [
         'В цієї вправі:', 'Закріплюємо слова', 'ECHO THIN DISC та інші'
      ],
      
      // Ex 8
      [
         'В цієї вправі:', 'Закріплюємо слова', 'DALE OILS LAND та інші'  
      ],
      
      // Ex 9
      [
         'В цієї вправі:', 'Повторюємо ', 'Усі слова'      
      ],
      // Ex 10
      [
         'ОСТАННЯ ВПРАВА УРОКУ', 'Повторюємо ', 'Усі слова'  
      ]
   ],
   // Lesson 7
   [
        // Ex 1
      [
       'В цієї вправі:', 'Тренеруємо слова', 'POUND PITS'
      ],
      // Ex 2
      [
         'В цієї вправі:', 'Тренеруємо слова', 'RYAS EBBED'  
      ],
      // Ex 3
      [
         'В цієї вправі:', 'Тренеруємо слова', 'RISK REEKS'  
      ],
      // Ex 4
      [
         'В цієї вправі:', 'Тренеруємо слова', 'LEAK LENS'      
      ],
      // Ex 5
      [
         'В цієї вправі:', 'Тренеруємо слова', 'FLUX LEAVE'  
      ],
      // Ex 6
      [
         'В цієї вправі:', 'Тренеруємо слова', 'LEIS LEAF'
      ],
      // Ex 7
      [
         'В цієї вправі:', 'Закріплюємо слова', 'POUND PITS RYAS та інші'
      ],
      
      // Ex 8
      [
         'В цієї вправі:', 'Закріплюємо слова', 'LEAK LENS FLUX та інші'  
      ],
      
      // Ex 9
      [
         'В цієї вправі:', 'Повторюємо ', 'Усі слова'      
      ],
      // Ex 10
      [
         'ОСТАННЯ ВПРАВА УРОКУ', 'Повторюємо ', 'Усі слова'  
      ]
   ],
   // Lesson 8
   [
       // Ex 1
       [
         'В цієї вправі:', 'Тренеруємо слова', 'LEAF LAZY'
        ],
        // Ex 2
        [
           'В цієї вправі:', 'Тренеруємо слова', 'KENO QUACK'  
        ],
        // Ex 3
        [
           'В цієї вправі:', 'Тренеруємо слова', 'KNIFE JACK'  
        ],
        // Ex 4
        [
           'В цієї вправі:', 'Тренеруємо слова', 'CHAP SOCK'      
        ],
        // Ex 5
        [
           'В цієї вправі:', 'Тренеруємо слова', 'KEYS OBEY'  
        ],
        // Ex 6
        [
           'В цієї вправі:', 'Тренеруємо слова', 'MENS CAPS'
        ],
        // Ex 7
        [
           'В цієї вправі:', 'Закріплюємо слова', 'LEAF LAZY KENO та інші'
        ],
        
        // Ex 8
        [
           'В цієї вправі:', 'Закріплюємо слова', 'CHAP SOCK KEYS та інші'  
        ],
        
        // Ex 9
        [
           'В цієї вправі:', 'Повторюємо ', 'Усі слова'      
        ],
        // Ex 10
        [
           'ОСТАННЯ ВПРАВА УРОКУ', 'Повторюємо ', 'Усі слова'  
        ]
   ],
   // Lesson 9
   [
        // Ex 1
        [
         'В цієї вправі:', 'Тренеруємо слова', 'VILE FILE'
        ],
        // Ex 2
        [
           'В цієї вправі:', 'Тренеруємо слова', 'VENT VALE'  
        ],
        // Ex 3
        [
           'В цієї вправі:', 'Тренеруємо слова', 'BACK BANS'  
        ],
        // Ex 4
        [
           'В цієї вправі:', 'Тренеруємо слова', 'BAGS TINS'      
        ],
        // Ex 5
        [
           'В цієї вправі:', 'Тренеруємо слова', 'GIFT GRIT'  
        ],
        // Ex 6
        [
           'В цієї вправі:', 'Тренеруємо слова', 'HERB HAND'
        ],
        // Ex 7
        [
           'В цієї вправі:', 'Закріплюємо слова', 'VILE FILE VENT та інші'
        ],
        
        // Ex 8
        [
           'В цієї вправі:', 'Закріплюємо слова', 'BAGS TINS GIFT та інші'  
        ],
        
        // Ex 9
        [
           'В цієї вправі:', 'Повторюємо ', 'Усі слова'      
        ],
        // Ex 10
        [
           'ОСТАННЯ ВПРАВА УРОКУ', 'Повторюємо ', 'Усі слова'  
        ]
   ],
   // Lesson 9
   [
      // Ex 1
      [
       'В цієї вправі:', 'Тренеруємо ціфри', '7 та 8'
      ],
      // Ex 2
      [
         'В цієї вправі:', 'Тренеруємо ціфри', '5 та 6'  
      ],
      // Ex 3
      [
         'В цієї вправі:', 'Закріплюємо ціфри', '5, 6, 7 та 8'  
      ],
      // Ex 4
      [
         'В цієї вправі:', 'Тренеруємо ціфри', '9 та 0'      
      ],
      // Ex 5
      [
         'В цієї вправі:', 'Закріплюємо ціфри', '9 та 0'  
      ],
      // Ex 6
      [
         'В цієї вправі:', 'Тренеруємо ціфри', '3 та 4'
      ],
      // Ex 7
      [
         'В цієї вправі:', 'Закріплюємо ціфри', '3 та 4'
      ],
      
      // Ex 8
      [
         'В цієї вправі:', 'Тренеруємо ціфри', '1 та 2'  
      ],
      
      // Ex 9
      [
         'В цієї вправі:', 'Закріплюємо ціфри', '1 та 2'      
      ],
      // Ex 10
      [
         'ОСТАННЯ ВПРАВА УРОКУ', 'Повторюємо', 'Усі ціфри та слова'  
      ]
 ],
   // Lesson 10
   [
      // Ex 1
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 2
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 3
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 4
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 5
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 6
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 7
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 8
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 9
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 10
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ]
   ],
   [
      // Ex 5
      [
         'g', 'a', 'f', 'f', ';', 'Space', 'h', 'a', 'l', 'l', 'Space', 'h', 'a', 'l', 'd', 'Space', 's', 'a', 'g', 'a', 'Space',
         'h', 'a', 's', 'h', ';', 'Space', 's', 'a', 's', 'h', 'Space', 'g', 'a', 'l', 'l', 'Space', 
         'f', 'l', 'a', 'g', ';','Space', 'h', 'a','s','Space', 'd','a', 's', 'h','Space', 'h', 'a', 'l', 'f'
        ],
        // Ex 6
        [
         'r', 't', 't', 'r', 't','Space', 't', 'r', 't','t','r', 'Space', 'r', 't','r', 'r', 't', 'Space', 't', 't','r', 't','r', 'Space',
         'r', 'r', 't', 'r', 't', 'Space', 'r', 't', 'r', 't', 't', 'Space', 't','r', 't', 'r', 'r', 'Space', 
         'r', 't', 'r', 't', 'r', 'Space', 't', 't', 'r', 't','r', 'Space','r', 'r', 't', 'r', 't'
        ],
        // Ex 7
        [
         'a', 'r', 't', 'Space', 't', 'a', 'd', 'Space', 'g', 'a', 'r', 'Space',  'a', 't', 'Space',
         's', 'a', 't', 'Space','r', 'a',  'g', 'Space', 't', 'a', 'g', ';', 'Space', 'f', 'a', 'r','Space', 'j', 'a', 'r', 'Space',
         't', 'a', 'r', 'Space', 'r', 'a', 'h', 'Space', 'h', 'a', 't', 'Space', 'r', 'a', 't', 'Space', 'r', 'a', 'g', ';', 'Space', 't', 'a', 't',
        ],
 
   ]

];

export const exerciseDescEN = [
   // Lesson 1 Основна позиція пальців
    [
      // Ex 1
      [
         'In this exercise:', 'Learning the keys', 'J K'
      ],
      // Ex 2
      [
         'In this exercise:', 'Learning the keys', 'D F'  
      ],
      // Ex 3
      [
         'In this exercise:', 'Pinning the keys', 'J K  and D F'  
      ],
      // Ex 4
      [
         'In this exercise:', 'Repeat the keys', 'J K  and D F'      
      ],
      // Ex 5
      [
         'In this exercise:', 'Learning the keys', 'L ;'
      ],
      // Ex 6
      [
         'In this exercise:', 'Repeat the keys', 'L F   K J  and  D ;'
      ],
      // Ex 7
      [
         'In this exercise:', 'Learning the keys', 'A  S'  
      ],
      // Ex 8
      [
         'In this exercise:', 'Repeat the keys', 'A L F  D S K  and  ;'  
      ],
      // Ex 9
      [
         'In this exercise:', 'Pinning the keys', 'A L F  D S K  and  ;'      
      ],
      // Ex 10
      [
         'LAST EXERCISE OF THE LESSON', 'Repeat all the keys', 'A S D F and J K L ;'  
      ]
   ],
 //  Lesson 2 Основна позиція + верхній та середній раяд вказівних пальців
   [
        // Ex 1
      [
         'In this exercise:', 'Learning the keys', 'G H'
      ],
      // Ex 2
      [
         'In this exercise:', 'Pinning the keys', 'G H та A F L D S  ;'  
      ],
      // Ex 3
      [
         'In this exercise:', 'Learning the keys', 'R T'  
      ],
      // Ex 4
      [
         'In this exercise:', 'Pinning the keys', 'R T  та  G H and others'      
      ],
      // Ex 5
      [
         'In this exercise:', 'Repeat the keys', 'R T G H and others'
      ],
      // Ex 6
      [
         'In this exercise:', 'Repeat the keys', 'A S D F G H J K L ; R T'
      ],
      // Ex 7
      [
         'In this exercise:', 'Pinning the keys', 'R T and others'  
      ],
      // Ex 8
      [
         'In this exercise:', 'Learning the keys', 'Y U'  
      ],
      // Ex 9
      [
         'In this exercise:', 'Pinning the keys', 'Y U and others'      
      ],
      // Ex 10
      [
         'LAST EXERCISE OF THE LESSON', 'Repeat all the keys', 'Y U R T G H and others'  
      ]
       
     ], 
// Lesson 3 + нижній ряд вказівних пальйів
    [
      // Ex 1
      [
         'In this exercise:', 'Learning the keys', 'V B'
      ],
      // Ex 2
      [
         'In this exercise:', 'Repeat the keys', 'V B ...'  
      ],
      // Ex 3
      [
         'In this exercise:', 'Pinning the keys', 'V B and others'  
      ],
      // Ex 4
      [
         'In this exercise:', 'Learning the keys', 'N M'      
      ],
      // Ex 5
      [
         'In this exercise:', 'Repeat the keys', 'N M and others'
      ],
      // Ex 6
      [
         'In this exercise:', 'Pinning the keys', 'N M and others'
      ],
      // Ex 7
      [
         'In this exercise:', 'Repeat the keys', 'V N M T Y U A S'  
      ],
      // Ex 8
      [
         'In this exercise:', 'Repeat the keys', 'V B N M R T Y U'  
      ],
      // Ex 9
      [
         'In this exercise:', 'Repeat the keys', '; V N M T Y U A S'      
      ],
      // Ex 10
      [
         'LAST EXERCISE OF THE LESSON', 'Repeat all the keys', 'V B N M R T Y U and J ;'  
      ]
   ],
   // Lesson 4 Середній та безімяний пальці
   [
      // Ex 1
      [
         'In this exercise:', 'Learning the keys', 'I O'
      ],
      // Ex 2
      [
         'In this exercise:', 'Repeat the keys', 'I O'  
      ],
      // Ex 3
      [
         'In this exercise:', 'Learning the keys', 'W E'  
      ],
      // Ex 4
      [
         'In this exercise:', 'Repeat the keys', 'W E'      
      ],
      // Ex 5
      [
         'In this exercise:', 'Learning the keys', ', .'
      ],
      // Ex 6
      [
         'In this exercise:', 'Repeat the keys', ', .'
      ],
      // Ex 7
      [
         'In this exercise:', 'Learning the keys', 'X C'  
      ],
      // Ex 8
      [
         'In this exercise:', 'Repeat the keys', 'X C and others'  
      ],
      // Ex 9
      [
         'In this exercise:', 'Pinning the keys', 'X C and others'      
      ],
      // Ex 10
      [
         'LAST EXERCISE OF THE LESSON', 'Repeat all the keys', 'I O W E X C , .'  
      ]
   ],
   // Lesson 5
   [
      // Ex 1
      [
         'In this exercise:', 'Learning the keys', 'Z Q'
      ],
      // Ex 2
      [
         'In this exercise:', 'Repeat the keys', 'Z Q'  
      ],
      // Ex 3
      [
         'In this exercise:', 'Learning the keys', 'P ['  
      ],
      // Ex 4
      [
         'In this exercise:', 'Repeat the keys', 'P [ Z Q'      
      ],
      // Ex 5
      [
         'In this exercise:', 'Learning the key', ']'  
      ],
      // Ex 6
      [
         'In this exercise:', 'Повторюємо клавішу', ']  and others'
      ],
      // Ex 7
      [
         'In this exercise:', 'Learning the keys', '/ \''
      ],
      
      // Ex 8
      [
         'In this exercise:', 'Pinning the keys', '/ \' [ ] Z Q P'  
      ],
      
      // Ex 9
      [
         'In this exercise:', 'Pinning the keys', '\' [ ] and others'      
      ],
      // Ex 10
      [
         'LAST EXERCISE OF THE LESSON', 'Repeat all the keys', 'Z Q P / \' [ ]'  
      ]
   ],
   // Lesson 6
   [
       // Ex 1
       [
         'In this exercise:', 'Training words', 'MEAN JEANS'
      ],
      // Ex 2
      [
         'In this exercise:', 'Training words', 'ECHO THIN'  
      ],
      // Ex 3
      [
         'In this exercise:', 'Training words', 'DISC DISH'  
      ],
      // Ex 4
      [
         'In this exercise:', 'Training words', 'DALE OILS'      
      ],
      // Ex 5
      [
         'In this exercise:', 'Training words', 'PATH LAST'  
      ],
      // Ex 6
      [
         'In this exercise:', 'Training words', 'LAND PETS'
      ],
      // Ex 7
      [
         'In this exercise:', 'Fixing words', 'ECHO THIN DISC and others'
      ],
      
      // Ex 8
      [
         'In this exercise:', 'Fixing words', 'DALE OILS LAND and others'  
      ],
      
      // Ex 9
      [
         'In this exercise:', 'Repeat ', 'All words'      
      ],
      // Ex 10
      [
         'LAST EXERCISE OF THE LESSON', 'Repeat ', 'All words'  
      ]
   ],
   // Lesson 7
   [
        // Ex 1
      [
       'In this exercise:', 'Training words', 'POUND PITS'
      ],
      // Ex 2
      [
         'In this exercise:', 'Training words', 'RYAS EBBED'  
      ],
      // Ex 3
      [
         'In this exercise:', 'Training words', 'RISK REEKS'  
      ],
      // Ex 4
      [
         'In this exercise:', 'Training words', 'LEAK LENS'      
      ],
      // Ex 5
      [
         'In this exercise:', 'Training words', 'FLUX LEAVE'  
      ],
      // Ex 6
      [
         'In this exercise:', 'Training words', 'LEIS LEAF'
      ],
      // Ex 7
      [
         'In this exercise:', 'Fixing words', 'POUND PITS RYAS and others'
      ],
      
      // Ex 8
      [
         'In this exercise:', 'Fixing words', 'LEAK LENS FLUX and others'  
      ],
      
      // Ex 9
      [
         'In this exercise:', 'Repeat ', 'All words'      
      ],
      // Ex 10
      [
         'LAST EXERCISE OF THE LESSON', 'Repeat ', 'All words'  
      ]
   ],
   // Lesson 8
   [
       // Ex 1
       [
         'In this exercise:', 'Training words', 'LEAF LAZY'
        ],
        // Ex 2
        [
           'In this exercise:', 'Training words', 'KENO QUACK'  
        ],
        // Ex 3
        [
           'In this exercise:', 'Training words', 'KNIFE JACK'  
        ],
        // Ex 4
        [
           'In this exercise:', 'Training words', 'CHAP SOCK'      
        ],
        // Ex 5
        [
           'In this exercise:', 'Training words', 'KEYS OBEY'  
        ],
        // Ex 6
        [
           'In this exercise:', 'Training words', 'MENS CAPS'
        ],
        // Ex 7
        [
           'In this exercise:', 'Fixing words', 'LEAF LAZY KENO and others'
        ],
        
        // Ex 8
        [
           'In this exercise:', 'Fixing words', 'CHAP SOCK KEYS and others'  
        ],
        
        // Ex 9
        [
           'In this exercise:', 'Repeat ', 'All words'      
        ],
        // Ex 10
        [
           'LAST EXERCISE OF THE LESSON', 'Repeat ', 'All words'  
        ]
   ],
   // Lesson 9
   [
        // Ex 1
        [
         'In this exercise:', 'Training words', 'VILE FILE'
        ],
        // Ex 2
        [
           'In this exercise:', 'Training words', 'VENT VALE'  
        ],
        // Ex 3
        [
           'In this exercise:', 'Training words', 'BACK BANS'  
        ],
        // Ex 4
        [
           'In this exercise:', 'Training words', 'BAGS TINS'      
        ],
        // Ex 5
        [
           'In this exercise:', 'Training words', 'GIFT GRIT'  
        ],
        // Ex 6
        [
           'In this exercise:', 'Training words', 'HERB HAND'
        ],
        // Ex 7
        [
           'In this exercise:', 'Fixing words', 'VILE FILE VENT and others'
        ],
        
        // Ex 8
        [
           'In this exercise:', 'Fixing words', 'BAGS TINS GIFT and others'  
        ],
        
        // Ex 9
        [
           'In this exercise:', 'Repeat ', 'All words'      
        ],
        // Ex 10
        [
           'LAST EXERCISE OF THE LESSON', 'Repeat ', 'All words'  
        ]
   ],
      // Lesson 9
      [
         // Ex 1
         [
          'In this exercise:', 'Training numbers', '7 & 8'
         ],
         // Ex 2
         [
            'In this exercise:', 'Training numbers', '5 & 6'  
         ],
         // Ex 3
         [
            'In this exercise:', 'Fixing numbers', '5, 6, 7 & 8'  
         ],
         // Ex 4
         [
            'In this exercise:', 'Training numbers', '9 & 0'      
         ],
         // Ex 5
         [
            'In this exercise:', 'Fixing numbers', '9 & 0'  
         ],
         // Ex 6
         [
            'In this exercise:', 'Training numbers', '3 & 4'
         ],
         // Ex 7
         [
            'In this exercise:', 'Fixing numbers', '3 & 4' 
         ],
         
         // Ex 8
         [
            'In this exercise:', 'Training numbers', '1 & 2'  
         ],
         
         // Ex 9
         [
            'In this exercise:', 'Fixing numbers', '1 & 2'      
         ],
         // Ex 10
         [
            'LAST EXERCISE OF THE LESSON', 'Repeat ', 'All numbers and words'  
         ]
    ],
   // Lesson 10
   [
      // Ex 1
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 2
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 3
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 4
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 5
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 6
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 7
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 8
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 9
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 10
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ]
   ],
   [
      // Ex 5
      [
         'g', 'a', 'f', 'f', ';', 'Space', 'h', 'a', 'l', 'l', 'Space', 'h', 'a', 'l', 'd', 'Space', 's', 'a', 'g', 'a', 'Space',
         'h', 'a', 's', 'h', ';', 'Space', 's', 'a', 's', 'h', 'Space', 'g', 'a', 'l', 'l', 'Space', 
         'f', 'l', 'a', 'g', ';','Space', 'h', 'a','s','Space', 'd','a', 's', 'h','Space', 'h', 'a', 'l', 'f'
        ],
        // Ex 6
        [
         'r', 't', 't', 'r', 't','Space', 't', 'r', 't','t','r', 'Space', 'r', 't','r', 'r', 't', 'Space', 't', 't','r', 't','r', 'Space',
         'r', 'r', 't', 'r', 't', 'Space', 'r', 't', 'r', 't', 't', 'Space', 't','r', 't', 'r', 'r', 'Space', 
         'r', 't', 'r', 't', 'r', 'Space', 't', 't', 'r', 't','r', 'Space','r', 'r', 't', 'r', 't'
        ],
        // Ex 7
        [
         'a', 'r', 't', 'Space', 't', 'a', 'd', 'Space', 'g', 'a', 'r', 'Space',  'a', 't', 'Space',
         's', 'a', 't', 'Space','r', 'a',  'g', 'Space', 't', 'a', 'g', ';', 'Space', 'f', 'a', 'r','Space', 'j', 'a', 'r', 'Space',
         't', 'a', 'r', 'Space', 'r', 'a', 'h', 'Space', 'h', 'a', 't', 'Space', 'r', 'a', 't', 'Space', 'r', 'a', 'g', ';', 'Space', 't', 'a', 't',
        ],
 
   ]

];
/////////////////////////////////////////////
///////////////////////////////////////////////////





export const exerciseDescAR = [
   // Lesson 1 Основна позиція пальців
    [
      // Ex 1
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'J K'
      ],
      // Ex 2
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'D F'  
      ],
      // Ex 3
      [
         'في هذا التدريب', 'الضغط عالمفاتيح', 'J K  و D F'  
      ],
      // Ex 4
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'J K  و D F'      
      ],
      // Ex 5
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'L ;'
      ],
      // Ex 6
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'L F   K J  و  D ;'
      ],
      // Ex 7
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'A  S'  
      ],
      // Ex 8
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'A L F  D S K  و  ;'  
      ],
      // Ex 9
      [
         'في هذا التدريب', 'الضغط عالمفاتيح', 'A L F  D S K  و  ;'      
      ],
      // Ex 10
      [
         'أخر تدريب في الدرس', 'كرر كل المفاتيح', 'A S D F و J K L ;'  
      ]
   ],
 //  Lesson 2 Основна позиція + верхній та середній раяд вказівних пальців
   [
        // Ex 1
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'G H'
      ],
      // Ex 2
      [
         'في هذا التدريب', 'الضغط عالمفاتيح', 'G H та A F L D S  ;'  
      ],
      // Ex 3
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'R T'  
      ],
      // Ex 4
      [
         'في هذا التدريب', 'الضغط عالمفاتيح', 'R T  та  G H و أخرى'      
      ],
      // Ex 5
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'R T G H و أخرى'
      ],
      // Ex 6
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'A S D F G H J K L ; R T'
      ],
      // Ex 7
      [
         'في هذا التدريب', 'الضغط عالمفاتيح', 'R T و أخرى'  
      ],
      // Ex 8
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'Y U'  
      ],
      // Ex 9
      [
         'في هذا التدريب', 'الضغط عالمفاتيح', 'Y U و أخرى'      
      ],
      // Ex 10
      [
         'أخر تدريب في الدرس', 'كرر كل المفاتيح', 'Y U R T G H و أخرى'  
      ]
       
     ], 
// Lesson 3 + нижній ряд вказівних пальйів
    [
      // Ex 1
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'V B'
      ],
      // Ex 2
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'V B ...'  
      ],
      // Ex 3
      [
         'في هذا التدريب', 'الضغط عالمفاتيح', 'V B و أخرى'  
      ],
      // Ex 4
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'N M'      
      ],
      // Ex 5
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'N M و أخرى'
      ],
      // Ex 6
      [
         'في هذا التدريب', 'الضغط عالمفاتيح', 'N M و أخرى'
      ],
      // Ex 7
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'V N M T Y U A S'  
      ],
      // Ex 8
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'V B N M R T Y U'  
      ],
      // Ex 9
      [
         'في هذا التدريب', 'تكرار المفاتيح', '; V N M T Y U A S'      
      ],
      // Ex 10
      [
         'أخر تدريب في الدرس', 'كرر كل المفاتيح', 'V B N M R T Y U و J ;'  
      ]
   ],
   // Lesson 4 Середній та безімяний пальці
   [
      // Ex 1
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'I O'
      ],
      // Ex 2
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'I O'  
      ],
      // Ex 3
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'W E'  
      ],
      // Ex 4
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'W E'      
      ],
      // Ex 5
      [
         'في هذا التدريب', 'تعلم المفاتيح', ', .'
      ],
      // Ex 6
      [
         'في هذا التدريب', 'تكرار المفاتيح', ', .'
      ],
      // Ex 7
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'X C'  
      ],
      // Ex 8
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'X C و أخرى'  
      ],
      // Ex 9
      [
         'في هذا التدريب', 'الضغط عالمفاتيح', 'X C و أخرى'      
      ],
      // Ex 10
      [
         'أخر تدريب في الدرس', 'كرر كل المفاتيح', 'I O W E X C , .'  
      ]
   ],
   // Lesson 5
   [
      // Ex 1
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'Z Q'
      ],
      // Ex 2
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'Z Q'  
      ],
      // Ex 3
      [
         'في هذا التدريب', 'تعلم المفاتيح', 'P ['  
      ],
      // Ex 4
      [
         'في هذا التدريب', 'تكرار المفاتيح', 'P [ Z Q'      
      ],
      // Ex 5
      [
         'في هذا التدريب', 'تعلم المفاتيح', ']'  
      ],
      // Ex 6
      [
         'في هذا التدريب', 'تكرار المفاتيح', ']  و أخرى'
      ],
      // Ex 7
      [
         'في هذا التدريب', 'تعلم المفاتيح', '/ \''
      ],
      
      // Ex 8
      [
         'في هذا التدريب', 'الضغط عالمفاتيح', '/ \' [ ] Z Q P'  
      ],
      
      // Ex 9
      [
         'في هذا التدريب', 'الضغط عالمفاتيح', '\' [ ] و أخرى'      
      ],
      // Ex 10
      [
         'أخر تدريب في الدرس', 'كرر كل المفاتيح', 'Z Q P / \' [ ]'  
      ]
   ],
   // Lesson 6
   [
       // Ex 1
       [
         'في هذا التدريب', 'كلمات تدريبيه', 'MEAN JEANS'
      ],
      // Ex 2
      [
         'في هذا التدريب', 'كلمات تدريبيه', 'ECHO THIN'  
      ],
      // Ex 3
      [
         'في هذا التدريب', 'كلمات تدريبيه', 'DISC DISH'  
      ],
      // Ex 4
      [
         'في هذا التدريب', 'كلمات تدريبيه', 'DALE OILS'      
      ],
      // Ex 5
      [
         'في هذا التدريب', 'كلمات تدريبيه', 'PATH LAST'  
      ],
      // Ex 6
      [
         'في هذا التدريب', 'كلمات تدريبيه', 'LAND PETS'
      ],
      // Ex 7
      [
         'في هذا التدريب', 'كلمات ثابته', 'ECHO THIN DISC و أخرى'
      ],
      
      // Ex 8
      [
         'في هذا التدريب', 'كلمات ثابته', 'DALE OILS LAND و أخرى'  
      ],
      
      // Ex 9
      [
         'في هذا التدريب', 'كرر ', 'جميع الكلمات'      
      ],
      // Ex 10
      [
         'أخر تدريب في الدرس', 'كرر ', 'جميع الكلمات'  
      ]
   ],
   // Lesson 7
   [
        // Ex 1
      [
       'في هذا التدريب', 'كلمات تدريبيه', 'POUND PITS'
      ],
      // Ex 2
      [
         'في هذا التدريب', 'كلمات تدريبيه', 'RYAS EBBED'  
      ],
      // Ex 3
      [
         'في هذا التدريب', 'كلمات تدريبيه', 'RISK REEKS'  
      ],
      // Ex 4
      [
         'في هذا التدريب', 'كلمات تدريبيه', 'LEAK LENS'      
      ],
      // Ex 5
      [
         'في هذا التدريب', 'كلمات تدريبيه', 'FLUX LEAVE'  
      ],
      // Ex 6
      [
         'في هذا التدريب', 'كلمات تدريبيه', 'LEIS LEAF'
      ],
      // Ex 7
      [
         'في هذا التدريب', 'كلمات ثابته', 'POUND PITS RYAS و أخرى'
      ],
      
      // Ex 8
      [
         'في هذا التدريب', 'كلمات ثابته', 'LEAK LENS FLUX و أخرى'  
      ],
      
      // Ex 9
      [
         'في هذا التدريب', 'كرر ', 'جميع الكلمات'      
      ],
      // Ex 10
      [
         'أخر تدريب في الدرس', 'كرر ', 'جميع الكلمات'  
      ]
   ],
   // Lesson 8
   [
       // Ex 1
       [
         'في هذا التدريب', 'كلمات تدريبيه', 'LEAF LAZY'
        ],
        // Ex 2
        [
           'في هذا التدريب', 'كلمات تدريبيه', 'KENO QUACK'  
        ],
        // Ex 3
        [
           'في هذا التدريب', 'كلمات تدريبيه', 'KNIFE JACK'  
        ],
        // Ex 4
        [
           'في هذا التدريب', 'كلمات تدريبيه', 'CHAP SOCK'      
        ],
        // Ex 5
        [
           'في هذا التدريب', 'كلمات تدريبيه', 'KEYS OBEY'  
        ],
        // Ex 6
        [
           'في هذا التدريب', 'كلمات تدريبيه', 'MENS CAPS'
        ],
        // Ex 7
        [
           'في هذا التدريب', 'كلمات ثابته', 'LEAF LAZY KENO و أخرى'
        ],
        
        // Ex 8
        [
           'في هذا التدريب', 'كلمات ثابته', 'CHAP SOCK KEYS و أخرى'  
        ],
        
        // Ex 9
        [
           'في هذا التدريب', 'كرر ', 'جميع الكلمات'      
        ],
        // Ex 10
        [
           'أخر تدريب في الدرس', 'كرر ', 'جميع الكلمات'  
        ]
   ],
   // Lesson 9
   [
        // Ex 1
        [
         'في هذا التدريب', 'كلمات تدريبيه', 'VILE FILE'
        ],
        // Ex 2
        [
           'في هذا التدريب', 'كلمات تدريبيه', 'VENT VALE'  
        ],
        // Ex 3
        [
           'في هذا التدريب', 'كلمات تدريبيه', 'BACK BANS'  
        ],
        // Ex 4
        [
           'في هذا التدريب', 'كلمات تدريبيه', 'BAGS TINS'      
        ],
        // Ex 5
        [
           'في هذا التدريب', 'كلمات تدريبيه', 'GIFT GRIT'  
        ],
        // Ex 6
        [
           'في هذا التدريب', 'كلمات تدريبيه', 'HERB HAND'
        ],
        // Ex 7
        [
           'في هذا التدريب', 'كلمات ثابته', 'VILE FILE VENT و أخرى'
        ],
        
        // Ex 8
        [
           'في هذا التدريب', 'كلمات ثابته', 'BAGS TINS GIFT و أخرى'  
        ],
        
        // Ex 9
        [
           'في هذا التدريب', 'كرر ', 'جميع الكلمات'      
        ],
        // Ex 10
        [
           'أخر تدريب في الدرس', 'كرر ', 'جميع الكلمات'  
        ]
   ],
       // Lesson 9
       [
         // Ex 1
         [
          'في هذا التمرين:', 'نحن ندرب الأرقام', '7 & 8'
         ],
         // Ex 2
         [
            'في هذا التمرين:', 'نحن ندرب الأرقام', '5 & 6'  
         ],
         // Ex 3
         [
            'في هذا التمرين:', 'كرر الأرقام', '5, 6, 7 & 8'  
         ],
         // Ex 4
         [
            'في هذا التمرين:', 'نحن ندرب الأرقام', '9 & 0'      
         ],
         // Ex 5
         [
            'في هذا التمرين:', 'كرر الأرقام', '9 & 0'  
         ],
         // Ex 6
         [
            'في هذا التمرين:', 'نحن ندرب الأرقام', '3 & 4'
         ],
         // Ex 7
         [
            'في هذا التمرين:', 'كرر الأرقام', '3 & 4' 
         ],
         
         // Ex 8
         [
            'في هذا التمرين:', 'نحن ندرب الأرقام', '1 & 2'  
         ],
         
         // Ex 9
         [
            'في هذا التمرين:', 'كرر الأرقام', '1 & 2'      
         ],
         // Ex 10
         [
            'التمرين الأخير للدرس', 'كرر', 'كل الأرقام والكلمات'  
         ]
    ],
   // Lesson 10
   [
      // Ex 1
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 2
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 3
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 4
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 5
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 6
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 7
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 8
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 9
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ],
      // Ex 10
      [
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
         '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 
      ]
   ],
   [
      // Ex 5
      [
         'g', 'a', 'f', 'f', ';', 'Space', 'h', 'a', 'l', 'l', 'Space', 'h', 'a', 'l', 'd', 'Space', 's', 'a', 'g', 'a', 'Space',
         'h', 'a', 's', 'h', ';', 'Space', 's', 'a', 's', 'h', 'Space', 'g', 'a', 'l', 'l', 'Space', 
         'f', 'l', 'a', 'g', ';','Space', 'h', 'a','s','Space', 'd','a', 's', 'h','Space', 'h', 'a', 'l', 'f'
        ],
        // Ex 6
        [
         'r', 't', 't', 'r', 't','Space', 't', 'r', 't','t','r', 'Space', 'r', 't','r', 'r', 't', 'Space', 't', 't','r', 't','r', 'Space',
         'r', 'r', 't', 'r', 't', 'Space', 'r', 't', 'r', 't', 't', 'Space', 't','r', 't', 'r', 'r', 'Space', 
         'r', 't', 'r', 't', 'r', 'Space', 't', 't', 'r', 't','r', 'Space','r', 'r', 't', 'r', 't'
        ],
        // Ex 7
        [
         'a', 'r', 't', 'Space', 't', 'a', 'd', 'Space', 'g', 'a', 'r', 'Space',  'a', 't', 'Space',
         's', 'a', 't', 'Space','r', 'a',  'g', 'Space', 't', 'a', 'g', ';', 'Space', 'f', 'a', 'r','Space', 'j', 'a', 'r', 'Space',
         't', 'a', 'r', 'Space', 'r', 'a', 'h', 'Space', 'h', 'a', 't', 'Space', 'r', 'a', 't', 'Space', 'r', 'a', 'g', ';', 'Space', 't', 'a', 't',
        ],
 
   ]

];

