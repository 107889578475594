import { 
        createApi, 
        fetchBaseQuery // Similiar to 'axios' or 'fetch'
        } from '@reduxjs/toolkit/query/react'
import { 
         setCredentials, 
         //logOut 
       } from '../features/auth/authSlice'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_DOMAIN,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token

        if(token) {
            headers.set("authorization", `Bearer ${token}`) // Sets up a message header
        }
        return headers
    } 
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    //args - request url, method, body
    //api - signal, dispatch, getState()
    //extraOptions - custom option like - {option: true}
    let result = await baseQuery(args, api, extraOptions)

    if(result?.error?.status === 403) {
        //send refresh token
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)


        if(refreshResult?.data) {
            const user = api.getState().auth.user

            api.dispatch(setCredentials({ ...refreshResult.data, user}))

            //retry original query with new access token
            result = await baseQuery(args, api, extraOptions)
        } else {

            if(refreshResult?.error?.status === 403) {
                refreshResult.error.data.message = "Your login has expired. "
            }
            return refreshResult
        }
    }

    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Note', 'User', 'Result'],
    endpoints: builder => ({})
})