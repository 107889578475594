import c from './css/all_lessons.module.css';
import s from './css/graph.module.css'
import { GraphUpIcon } from '../Components/Icons';
// import { FormattedMessage } from 'react-intl';
// import defineMessages from '../Lang/languages/messages';
import useBg from '../Hooks/useBg';
import { nanoid } from '@reduxjs/toolkit';
import { recommend } from '../Functions';
import OpenCardView from './OpenCardView';
///////////////////////////////////////////////// Shows and Sets number of Exrc to Rpt
// import  { useSelector, useDispatch } from 'react-redux';
// import { toggleRpt, increment, decrement } from '../Redux/rptSlice'//sets number of exrs to be rptd
///////////////////////////////////////////////


const AnaliticsView = (props) => {


    
///////////////////////////////////////////////// Shows and Sets number of Exrc to Rpt
// const rpt = useSelector((state) => state.rpt.rpt)
// const dispatch = useDispatch();
///////////////////////////////////////////////


const {
  yellowTxt, 
 // whiteTxt, 
  starColr
 } = useBg();

//console.log(props.TotalRecords)


  return (
   
<div className={c.all_lessons_wrapper}>   
   

  <div className={c.lesson_list_container}>  
    <h4>
      
        <span className={`${yellowTxt}`}>
            Here is your succes rate for typing of each letter
            {/* <FormattedMessage id='exrsToRpt' defaultMessage={defineMessages.exrsToRpt} key={uuidv4()}/>   */}
        </span>  
      
    </h4>  

    <div className={s.cards_wrapper}>

      <div className={s.cards_container}>
        <div className={`${s.cards_header} ${yellowTxt}`}>
               <div className={s.card_header_letter}>
                    Letter
                </div>
                <div className={s.card_header_result_wrapper}>
                    <div className={s.card_header_result_topper}>
                       Typing Success Rate 
                    </div>                    
                    <div className={s.card_header_result_cont}>                      
                        <div>
                           Correct
                        </div>
                        <div>
                           Wrong 
                        </div>
                        <div>
                          %
                        </div>
                    </div>
                </div>
                <div className={s.card_header_recom}>
                    Recommendation
                </div>
        </div> 
        <div className={s.card_item_cont}>
          {props.TotalRecords?.map((ltr, index) => 
          <div key={nanoid()}> 
             {props.indexTrecker === index && props.openView &&
               <OpenCardView
                 ltr={ltr} 
                 handleCloseView={props.handleCloseView} 
                 index={index}
                 exercToRe={props.exercToRe}
                 exerciseDesc={props.exerciseDesc}
                 handleGoToLesson={props.handleGoToLesson}                
               />
              }

            <div 
              className={`${s.card_item}`} 
              key={nanoid()}
               onClick={() => props.handleOpenView(index)}
              // onClick={() => alert(index)}
            >
             
                <div className={s.card_item_letter}>
                   {ltr.ltr}
                </div> 
                <div className={s.card_item_result}>                   
                    <div>
                     {ltr.t}
                    </div>
                    <div className={s.card_item_result_f}>
                       {ltr.f}
                    </div>
                    <div >
                      {ltr.successRate} %
                      {/* {(100 - (+ltr.f / (+ltr.t + +ltr.f))*100).toFixed(1)} % */}
                    </div>
                </div>
                <div className={s.card_item_recom}>
                 {recommend(ltr.t, ltr.f)}
                </div>
            </div>
          </div>
           
          )}



           {/* <div className={s.card_item} key={nanoid()}>
                <div className={s.card_item_letter}>
                 TEST 1
                </div>
                <div className={s.card_item_result}>                   
                    <div>
                    1
                    </div>
                    <div className={s.card_item_result_f}>
                      1
                    </div>
                    <div >
                    60 %
                    </div>
                </div>
                <div className={s.card_item_recom}>
                 {recommend(6, 4)}
                </div>
            </div>

            <div className={s.card_item} key={nanoid()}>
                <div className={s.card_item_letter}>
                TEST 2
                </div>
                <div className={s.card_item_result}>                   
                    <div>
                    1
                    </div>
                    <div className={s.card_item_result_f}>
                      1
                    </div>
                    <div >
                    50 %
                    </div>
                </div>
                <div className={s.card_item_recom}>
                 {recommend(5, 5)}
                </div>
            </div>

            <div key={nanoid()} onClick={() => props.setOpenView(true)}
                 className={s.card_item}
              >
                <div className={s.card_item_letter}>
                TEST 3
                </div>
                <div className={s.card_item_result}>                   
                    <div>
                    1
                    </div>
                    <div className={s.card_item_result_f}>
                      1
                    </div>
                    <div >
                    30 %
                    </div>
                </div>
                <div className={s.card_item_recom}>
                 {recommend(3, 7)}
                </div>
            </div> */}


        </div>
        
           
           

        </div>
    </div>


{/* 
    {props.exercToRe?.map((item, index) => 
      <div className={c.displ_inline} key={uuidv4()}>       
          <div className={c.lesson_list_index}
               onClick={() => {props.handleGoToLesson(
                               +item.id.charAt(2) === 0 ? 10 : +item.id.charAt(2), 
                               +item.id - 1,
                               +item.id.charAt(0) - 1,
                               'rptd'
                                                      )
                               }
                        }      
           >
            <span className={`${whiteTxt}`}>
              <span>{index + 1}.  </span>
               <FormattedMessage id='superPower' defaultMessage={defineMessages.superPower} /><span> {item.id.charAt(0)}. </span> 
               <FormattedMessage id='exerc' defaultMessage={defineMessages.exerc} /> {item.id.charAt(2) === '0' ? '10' : item.id.charAt(2)}.
              <span> {props.exerciseDesc[+(item.id.charAt(0)) - 1][+(item.id.charAt(2) === '0' ? '10' : item.id.charAt(2)) - 1][1]}</span>
              <span> {props.exerciseDesc[+(item.id.charAt(0)) - 1][+(item.id.charAt(2) === '0' ? '10' : item.id.charAt(2)) - 1][2]}</span>
            </span> 
          </div>
            <div className={c.results_cont}>
                <div key={uuidv4()}>
                    {+item.stars === 0 ? <span className={`${yellowTxt}`}> - </span> : fiveStars.map((starNum) => (
                      <span key={uuidv4()}>
                       {item.stars >= starNum && <span className='ml-1'>{star}</span>}
                      </span>
                    ))}
                </div> 
                <span className={`${whiteTxt}`}>
                   {item.tpm} {item.tpm && 't/m'}
                </span>              
            </div>          
      </div>
    )} */}
    </div> 

    <div className={c.lessons_left_screen_part}>
        {GraphUpIcon(100, `${starColr}`)}       
     </div> 
</div>
  )
}
export default AnaliticsView
 