import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './api/apiSlice'
import { setupListeners } from '@reduxjs/toolkit/query';
import  soundReducer  from './soundSlice';
import bgReducer from './bgSlice';
import keyBoardLayoutReducer from './keyBoardLayoutSlice';
import trigerReducer from './trigerSlice';
import pageHeaderReducer from './pageHeaderSlice';
import authReducer from './features/auth/authSlice';
import rptReducer from './rptSlice';
import ltrReducer from './ltrSlice';


// export default configureStore({
//     reducer: {
//         rentList: rentReducer,
//         TOJobsList: TOJobsReducer,
//     }
// });

const store = configureStore({
    reducer: {
       sound: soundReducer,
       bg: bgReducer,
       rpt: rptReducer,
       ltr: ltrReducer,
       layout: keyBoardLayoutReducer,
       triger: trigerReducer,
       pageHeader: pageHeaderReducer,
       [apiSlice.reducerPath]: apiSlice.reducer,
       auth: authReducer,
      
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: process.env.REACT_APP_NODE_ENV === 'development'
});
export default  store

setupListeners(store.dispatch)


