
import React, { useState, useEffect, useContext } from 'react'
import { ExercContext } from '../../Context/ExercContext';
import ScreenCard from '../Cards/ScreenCard'
import { Button, Spinner } from 'react-bootstrap';
import c from './login.module.css'
import { motion } from 'framer-motion/dist/framer-motion';
import { 
   //  createNewUser, 
       isSubmitAllowed, 
       validateName 
       } from '../../Functions';
import NewUserScreen1 from './NewUserScreen1';
import NewUserScreen2 from './NewUserScreen2';
import NewUserScreen3 from './NewUserScreen3';
import ProgressBar from './ProgressBar';
import NewUserScreen4 from './NewUserScreen4';
import { useNavigate } from "react-router-dom";

import { useAddNewUserMutation } from '../../Redux/features/users/usersApiSlice';
//mport { ROLES } from '../../config/roles'

const NewUser = () => {

    // const [ addNewUser, {
    //         isLoading,
    //         isSuccess,
    //         isError,
    //         error
    //     }] = useAddUserMutation()
        const [addNewUser, {isSuccess}] = useAddNewUserMutation()

    useEffect(() => {
        if(isSuccess) {
            cleanUpForm()
        }
// eslint-disable-next-line
    }, [isSuccess])

    const navigate = useNavigate();
    const {  setRptd  } = useContext(ExercContext);  
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true)
    const [email, setEmail] = useState('');
    const [showPwd, setShowPwd] = useState(false)
    const [password, setPassword] = useState('');
    const [rptPassword, setRptPassword] = useState('');    
    const [pwdValid, setPwdValid] = useState(true)
    const [pwdMutch, setPwdMutch] = useState(true)
    const [rptPwdChecked, setRptPwdChecked] = useState(false)
    const [allowSubmit, setAllowSubmit] = useState(true)
    const [noCaptcha, setNoCaptcha] = useState(true)
    const [submitBlocked, setSubmitBlocked] = useState(true)
    const [screenSwitch, setScreenSwitch] = useState(1)
    const [disableNext, setDisableNext] = useState(false)
// eslint-disable-next-line 
    const [serverRes, setServerRes] = useState('')
    const [serverError, setServerError] = useState(false)
    const [loader, setLoader] = useState(false)

    const handleFormSubmit = async () => {
       
        setLoader(true)
        setNoCaptcha(true)
        setAllowSubmit(true)
     
        const user = {
            username: name,
            email,
            password
        }
       // await createNewUser(user, setServerRes) // Function, make no use of Redux Query
        await addNewUser(user) // Function, using Redux Query
     }

    function cleanUpForm() {
        setScreenSwitch(prev => prev + 1)
        setLoader(false)
        setName('')
        setEmail('')
        setPassword('')
        setRptPassword('')
        setPwdValid(true)
        setPwdMutch(true)
        setRptPwdChecked(false)
        setAllowSubmit(true)
        setNoCaptcha(true)
        setSubmitBlocked(true)    
    }

     useEffect(() => {
        console.log(serverRes)
        if (serverRes === '') {
            setServerError(false)
        } else if (serverRes === 201) {
            cleanUpForm()
        } else {
            setServerError(true)
            setLoader(false)
            setTimeout(() => {
                setServerError(false)
            }, 20000)
        }   
        // eslint-disable-next-line 
     },[serverRes])
     
     useEffect(() => {
        setDisableNext(false) 
        if(screenSwitch === 1) {
            !nameValid && setDisableNext(true)
            email === '' && setDisableNext(true)
        } else if (screenSwitch === 2) {
             password === '' && setDisableNext(true)
             rptPassword === '' && setDisableNext(true)
            !pwdValid && setDisableNext(true)
            !pwdMutch && setDisableNext(true)
        }
       
        // eslint-disable-next-line 
     },[screenSwitch, nameValid, email, name, password, pwdValid, pwdMutch, rptPassword])

    useEffect(() => {       
       if(!noCaptcha && !allowSubmit) {
        setSubmitBlocked(false)
       } else {
        setSubmitBlocked(true)
       }
        // eslint-disable-next-line 
     },[allowSubmit, noCaptcha])

    useEffect(() => {
       setRptd(true) 
       // eslint-disable-next-line 
    },[])

     useEffect(() => {
        if(name?.length > 4) {
            validateName(name, setNameValid) 
        }      
        // eslint-disable-next-line 
     },[name])

    useEffect(() => {
        isSubmitAllowed(name, nameValid, email, password, rptPassword, pwdValid, pwdMutch, setAllowSubmit) 
       // eslint-disable-next-line
     },[name, nameValid, email, password, rptPassword, pwdValid, pwdMutch])

  return (
    <ScreenCard >
        <div className={`${c.login_wrapper} ${c.min_margins}`}>
            <div className={`${c.header_txt} ${c.min_margins}`}>
                Create New User Account
            </div>

            {screenSwitch !== 4 && <ProgressBar screenSwitch={screenSwitch}/>}

      <div className={`${c.inputs_section} ${c.mgn_btm}`}>
        {screenSwitch === 1 &&
           <NewUserScreen1 
                name={name} 
                setName={setName} 
                setNameValid={setNameValid} 
                nameValid={nameValid} 
                email={email} 
                setEmail={setEmail} 
                validateName={validateName}
               
            />
        }
        {screenSwitch === 2 &&
            <NewUserScreen2 
               showPwd={showPwd}
               setShowPwd={setShowPwd}
               setPassword={setPassword}
               setPwdValid={setPwdValid}
               password={password}
               setRptPwdChecked={setRptPwdChecked}
               pwdValid={pwdValid}
               rptPassword={rptPassword}
               setPwdMutch={setPwdMutch}
               pwdMutch={pwdMutch}
               rptPwdChecked={rptPwdChecked}
               setRptPassword={setRptPassword}
              
             />
        }
         {screenSwitch === 3 &&
             <NewUserScreen3 
              setNoCaptcha={setNoCaptcha}
              serverError={serverError}
             />
         }
          {screenSwitch === 4 &&
             <NewUserScreen4 
              
             />
         }



    <div className={c.btn_next_prev}>
     {screenSwitch !== 1 && screenSwitch !== 4 &&
        <motion.div 
            className={c.btn_box} 
            key='screen_1'
            animate={{x:"0%", y: "0%", opacity: 1, scale: 1}}
            initial={{ x:"-120%", y: "0%",  scale: 1}}
            exit={{x:"100%", y: "0%"}}
            transition={{ duration: 0.1 }}
         >
         
                <Button 
                    variant='outline-light' 
                    className={`${c.login_btn} ${c.btn_prev}`}
                    disabled={screenSwitch === 1}
                    onClick={() => setScreenSwitch(prev => prev - 1)}
                >
                    Previous
                </Button>
           
        </motion.div> 
     } 
         <div className={c.btn_box}>
    {screenSwitch !== 3 && screenSwitch !== 4 &&
                <Button 
                    variant='success' 
                    className={`${c.login_btn} ${c.btn_prev}`}
                    disabled={disableNext}
                    onClick={() => setScreenSwitch(prev => prev + 1)}
                >
                    Next
                </Button> 
            
    }  

    {screenSwitch === 3 && screenSwitch !== 4 &&
                    <Button variant='success' 
                        className={`${c.login_btn} ${c.btn_prev}`} 
                        disabled={submitBlocked}
                        onClick={() => handleFormSubmit()}
                    >
                          {!loader ? 'Submit' : <Spinner animation="border" variant="secondary" size="md" />}
                    </Button>
    } 
    {screenSwitch === 4 &&
                   <Button variant='success' 
                        className={`${c.login_btn} ${c.btn_prev}`} 
                        onClick={() => navigate('/login')}
                    >
                       Go To Login
                    </Button>
    }
            </div>   
         </div>
       </div>
     </div>
    </ScreenCard>
  )
}

export default NewUser