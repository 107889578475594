import React from 'react';
import c from '../../views/home_screen.module.css';
import { useNavigate } from "react-router-dom";
import { homeIcon } from '../Icons';
import useBg from '../../Hooks/useBg';

const GoHome = () => {

const navigate = useNavigate();
const { starColr } = useBg();
   
  return (
    <div className={c.abs_div} onClick={() => navigate('/')}>
        {homeIcon(50, `${starColr}`)}
    </div>
  )
}

export default GoHome