import React from 'react';
import { useNavigate } from "react-router-dom";
import c from './settings.module.css';
import s from './rotateLtr.module.css'

import CircularText from './CircularText';
import { PwrIcon } from '../Icons';
import useBg from '../../Hooks/useBg';


 
const AllPowersIcon = (props) => {

    
    const navigate = useNavigate();
    const { starColr } = useBg();

 
/////////////////////////////////////////////////

 

const link = (data, AllLessDirection) => {   
  localStorage.setItem("AllLessDirection", AllLessDirection);
  navigate(data);
};

  const text = [
    '','P','','E','','R','','','|','','','P','','O','','W','','E','','R',
    '','S','','','|',
    '','','A','','L','','L','','','|','','S','','U',
        ] 

  return (

      <div className={`${c.re_abs_div} ${props.cla}`} onClick={() => link(`/all_lessons`, "AllLess")}>
        <div className={`${c.re_abs_div_above}  `}>
          
          <div className=' '>
            {/* PwR */}
            {PwrIcon(32, `${starColr}`)}
          </div> 
            <CircularText text={text} cla={`${s.rotate_all} ${s.rotate_pwr}`} />
        </div> 
      </div>
  
  )
}

export default AllPowersIcon