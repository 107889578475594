import { createSlice } from "@reduxjs/toolkit";

const bgSaved = localStorage.getItem('bg')
//console.log(bgSaved) 

const initialState = {
    bg: bgSaved
}

export const bgSlice = createSlice({
    name: 'bg',
    initialState,
    reducers: {
        toggleBg: (state, action) => {
            state.bg = action.payload;
           localStorage.setItem('bg', action.payload);
        },
        // update: (state, action) => {
        //     state.soundOff = action.payload.sound;
        //    // localStorage.setItem('soundOff', action.payload.sound);
        // },
    },
});
 
export const { toggleBg } = bgSlice.actions;
export default bgSlice.reducer;

// Connect state to the Store!!!