import React  from 'react';
import c from '../components.module.css';
import { VolumeMuteIcon, VolumeUpIcon } from '../Icons';
//import { ServiceContext } from '../Context/ServiceContext';

import  { useSelector, useDispatch } from 'react-redux'
import { toggleSound } from '../../Redux/soundSlice'
import useBg from '../../Hooks/useBg';

const SoundControll = () => {

    const sound = useSelector((state) => state.sound.soundOn)
   
    const dispatch = useDispatch()
    const { starColr } = useBg();

  return (
   <>   
    <div className={c.volume_box} 
       //onClick={() => {soundControll()}}
       onClick={() => dispatch(toggleSound())}

    >
    
      {!sound ? VolumeMuteIcon(35, 'red') : VolumeUpIcon(35, `${starColr}`)}
    </div>
  </>
  )
}

export default SoundControll



 // {/* {soundOff === 'yes' ? VolumeMuteIcon(35, 'red') : VolumeUpIcon(35)} */}
// const {soundOff, setSoundOff} = useContext(ServiceContext);

// // const soundControll = () => {
// //     if(soundOff === 'yes') {
// //         setSoundOff('no');
// //         localStorage.setItem('soundOff', 'no');

// //     } else {
// //         setSoundOff('yes');
// //         localStorage.setItem('soundOff', 'yes');
// //     }
// // };
// const soundControll = () => {
//   if(sound) {
//       dispatch(toggleSound())
//       localStorage.setItem('soundOff', 'no');
//   } else {
//       dispatch(toggleSound())
//       localStorage.setItem('soundOff', 'yes');
//   }
// };