import c from './css/all_lessons.module.css';
import { 
    ReIcon,   
    StarFillIcon 
        } from '../Components/Icons';
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';
import { nanoid } from '@reduxjs/toolkit';
import useBg from '../Hooks/useBg';
///////////////////////////////////////////////// Shows and Sets number of Exrc to Rpt
// import  { useSelector, useDispatch } from 'react-redux';
// import { toggleRpt, increment, decrement } from '../Redux/rptSlice'//sets number of exrs to be rptd
///////////////////////////////////////////////


const HUBview = (props) => {



///////////////////////////////////////////////// Shows and Sets number of Exrc to Rpt
// const rpt = useSelector((state) => state.rpt.rpt)
// const dispatch = useDispatch();
///////////////////////////////////////////////

const fiveStars = [1,2,3,4,5];
const {yellowTxt, whiteTxt, starColr} = useBg();
const star = StarFillIcon(12, `${starColr}`);


 
  return (
<div className={c.all_lessons_wrapper}>
   
     


    <div className={c.lesson_list_container}>  
    <h4>
      <span className={`${yellowTxt}`}>
        <FormattedMessage id='exrsToRpt' defaultMessage={defineMessages.exrsToRpt} key={nanoid()}/>  
      </span>     
    </h4>  

    {props.exercToRe?.map((item, index) => 
      <div className={c.displ_inline} key={nanoid()}>       
          <div className={c.lesson_list_index}
               onClick={() => {props.handleGoToLesson(
                               +item.id.charAt(2) === 0 ? 10 : +item.id.charAt(2), 
                               +item.id - 1,
                               +item.id.charAt(0) - 1,
                               'rptd')
                               }
                        }      
           >
            <span className={`${whiteTxt}`}>
              <span>{index + 1}.  </span>
               <FormattedMessage id='superPower' defaultMessage={defineMessages.superPower} /><span> {item.id.charAt(0)}. </span> 
               <FormattedMessage id='exerc' defaultMessage={defineMessages.exerc} /> {item.id.charAt(2) === '0' ? '10' : item.id.charAt(2)}.
              <span> {props.exerciseDesc[+(item.id.charAt(0)) - 1][+(item.id.charAt(2) === '0' ? '10' : item.id.charAt(2)) - 1][1]}</span>
              <span> {props.exerciseDesc[+(item.id.charAt(0)) - 1][+(item.id.charAt(2) === '0' ? '10' : item.id.charAt(2)) - 1][2]}</span>
            </span> 
          </div>
            <div className={c.results_cont}>
                <div key={nanoid()}>
                    {+item.stars === 0 ? <span className={`${yellowTxt}`}> - </span> : fiveStars.map((starNum) => (
                      <span key={nanoid()}>
                       {item.stars >= starNum && <span className='ml-1'>{star}</span>}
                      </span>
                    ))}
                </div> 
                <span className={`${whiteTxt}`}>
                   {item.tpm} {item.tpm && 't/m'}
                </span>              
            </div>          
      </div>
    )}
    </div>  
    <div className={c.lessons_left_screen_part}>
        {ReIcon(100, `${starColr}`)} 
     </div>
</div>
  )
}
export default HUBview
 