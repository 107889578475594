import React from 'react';
import { useNavigate } from "react-router-dom";
import c from './settings.module.css';
import s from './rotateLtr.module.css';
//import  { useSelector } from 'react-redux'
import { GraphUpIcon } from '../Icons';
import CircularText from './CircularText';
import useBg from '../../Hooks/useBg';


 
const GraphIcon = (props) => {

    //const rpt = useSelector((state) => state.rpt.rpt)
    const navigate = useNavigate();
    const { starColr } = useBg();

 
/////////////////////////////////////////////////

 

const link = (data, AllLessDirection) => {   
  localStorage.setItem("AllLessDirection", AllLessDirection);
  navigate(data);
};
 
  const text = [
    'A','','N','','A','','L','','I','','T','','I','','C','','S','','|',
    '','A','','N','','A','','L','','I','','T','','I','','C','','S','','|','',
        ] 

  return (
      <div className={`${c.re_abs_div} ${props.cla}`} onClick={() => link(`/all_lessons`, "Graph")}>
        <div className={c.re_abs_div_above}>
          <div>
            {GraphUpIcon(30, `${starColr}`)}
          </div>         
             <CircularText text={text} cla={`${s.rotate_all} ${s.rotate_graph}`} />
        </div>
      </div>
    
  )
}

export default GraphIcon