import React, { useContext } from 'react'
import { ExercContext } from '../../Context/ExercContext';
import c from './screen_card.module.css'
import { motion } from 'framer-motion/dist/framer-motion';


const ScreenCard = ({ children }) => {
    const {  rptd  }  = useContext(ExercContext);
   // console.log(rptd)
   
  return (
    <div className={c.wrapper}>       
        <motion.div className={c.note_wrapper} 
        animate={{y: 0, x: 0, scale: 1, transition: {duration: 1}}} 
        initial={{y: -500, x: 0, scale: 0}}        
        >
            <div className={`${c.note_container} ${rptd ? c.note_container_rptd : ''}`}>
             {children}
            </div>
        </motion.div>
    </div>
  )
}

export default ScreenCard