import { createSlice } from "@reduxjs/toolkit";



//console.log(exrsToRe, exrsToRe.length, 'rptSlice')
const initialState = {
    ltr: []
}
 
export const ltrSlice = createSlice({
    name: 'ltr',
    initialState,
    reducers: {
        addLtr: (state, action) => {
            state.ltr = [...state.ltr, action.payload];
        }       
       
        // update: (state, action) => {
        //     state.soundOff = action.payload.sound;
        //    // localStorage.setItem('soundOff', action.payload.sound);
        // },
    },
});
  
export const { addLtr } = ltrSlice.actions;
export default ltrSlice.reducer;

// Connect state to the Store!!!