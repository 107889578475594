import React from 'react'
import ContactForm from '../Components/Mail/ContactForm'

const ContactFormController = () => {
  return (
    <>      
        <ContactForm />
    </>
  )
}

export default ContactFormController