import c from './Form.module.css'
import { motion } from 'framer-motion/dist/framer-motion';

const MailScreen3 = () => {
  return (
    <motion.div
        key='screen_4'
        animate={{x:"0%", y: "0%", opacity: 1, scale: 1}}
        initial={{ x:"-100%", y: "0%", opacity: 0, scale: 1}}
        exit={{x:"100%", y: "0%", transition: { duration: 0.8}}}
        transition={{ duration: 0.8, ease: "easeOut"}}
        className={c.screen_box}
    >
        <div className={c.congrats}>
            Thank You!  <br/><br/>
          Your Message has been successfully sent.
           
        </div>

</motion.div>
  )
}

export default MailScreen3