import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    userInfo: localStorage.getItem('NTUInfo') ? 
       JSON.parse(localStorage.getItem('NTUInfo')) : 
       null
}

const authSlice = createSlice({
    name: 'auth',
   // initialState: { token: null },
   initialState,
    reducers: {
        setCredentials: (state, action) => {
                     const { accessToken } = action.payload
                     state.token = accessToken
            state.userInfo = action.payload
            localStorage.setItem('NTUInfo', JSON.stringify(action.payload))
        },
        logOut: (state, action) => {
                     state.token = null
            state.userInfo = null
            localStorage.setItem('NTUInfo', JSON.stringify(''))
        },
    }
})

export const { setCredentials, logOut} = authSlice.actions

export default authSlice.reducer

                           export const selectCurrentToken = (state) => state.auth.token