import c from './login.module.css'

const ProgressBar = ({screenSwitch}) => {
  return (
    <div className={c.progress_bar}>
        <div className={`${c.progress_bar__step} ${c.progress_bar__step_filled}`}>step 1</div>
        <div className={`${c.progress_bar__step} ${screenSwitch > 1 && c.progress_bar__step_filled}`}>step 2</div>
        <div className={`${c.progress_bar__step} ${screenSwitch > 2 && c.progress_bar__step_filled}`}>step 3</div>
    </div>
  )
}

export default ProgressBar