import React from 'react';
import { Container, Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import c from './components.module.css';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';
import ScreenCard from './Cards/ScreenCard';
import PageRegControllers from '../Components/MenuButtons/PageRegControllers';




const NoPage = () => {

const navigate = useNavigate();

  return (
    
     <ScreenCard >
      <PageRegControllers/>
      <Container fluid className={c.container} >     
        <div className={`${c.card_header} ${c.card_item}`}>
            404
        </div>
        <div className={`${c.card_item} ${c.card_body}`}>
          <FormattedMessage id='sorry' defaultMessage={defineMessages.sorry} />,<br/>
          <FormattedMessage id='reqPage' defaultMessage={defineMessages.reqPage} /><br/> 
          <FormattedMessage id='notFnd' defaultMessage={defineMessages.notFnd} />     
        </div>
        <div className={`${c.card_item}`}>
          <Button  className={c.no_page_btn} onClick={() => {navigate('/');}} variant='success'>
            <FormattedMessage id='toMain' defaultMessage={defineMessages.toMain} />
          </Button>
        </div>   
     </Container>
     </ScreenCard>
 
  )
}

export default NoPage