import React from 'react';
import { useNavigate } from "react-router-dom";
import c from './settings.module.css';
import s from './rotateLtr.module.css'
import { PplIcon } from '../Icons';
import CircularText from './CircularText';
import useBg from '../../Hooks/useBg';


 
const AllUsersIcon = () => {

    
    const navigate = useNavigate();
    const { starColr } = useBg();

 
/////////////////////////////////////////////////

 

 const link = (data, toHUB) => {
   
    localStorage.setItem("goToHUB", toHUB);
    navigate(data);
  };

  const text = [
   '','E', '','R','','S','','|','','C','','O','','M','','M','','U','','N','','I','','T',
    '','Y','','|',
    '','A','','L','','L','','|','','U','','S',
        ] 

  return (

      <div className={`${c.re_abs_div} ${c.ppl_icon}`} onClick={() => link(`/users`, "no", "no")}>
        <div className={`${c.re_abs_div_above}  `}>
          
          <div className=' '>
            {PplIcon(32, `${starColr}`)}
          </div> 
            <CircularText text={text} cla={`${s.rotate_all} ${s.rotate_ppl}`} />
        </div> 
      </div>
  
  )
}

export default AllUsersIcon