import c from './css/all_lessons.module.css';
import {  
         ChevronDownIcon2, 
         UnlockIcon, 
         LockIcon, 
         MortorboardIcon, 
         StarFillIcon, 
         PwrIcon
        } from '../Components/Icons';
import { getStars } from '../Functions';
import useBg from '../Hooks/useBg';
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';
import { nanoid } from '@reduxjs/toolkit';

const AllLessonsView = (props) => {


const fiveStars = [1,2,3,4,5];
const {yellowTxt, whiteTxt, iconColr, starColr} = useBg();
const star = StarFillIcon(12, `${starColr}`);

  return (
  <div className={c.all_lessons_wrapper} key={nanoid()}>
    <div className={c.lesson_list_container} key={nanoid()}>  
    <h4 key={nanoid()}>
      <span key={nanoid()} className={`${yellowTxt}`}>
         <FormattedMessage id='chooseLesAndEx' defaultMessage={defineMessages.chooseLesAndEx} key={nanoid()}/>
      </span>
     
    </h4>     
      {props.lessonHeader.map((lesson, index) => 
      <div key={nanoid()} className={`${whiteTxt}`}>       
        <div  className={c.lesson_list_spreadout} onClick={() => props.handleLessonNumSet(index)} key={nanoid()}>
         <div key={nanoid()} className={c.lesson_list_items} >

            <div className={c.mortorboardIcon} key={nanoid()}>
              {+props.graduated >= index + 1 ? MortorboardIcon() : <div className={c.place_holder}/>}
            </div>

            <div className={c.lesson_list_index} key={nanoid()} >
            <span className={whiteTxt}>
             <FormattedMessage id='superPower' defaultMessage={defineMessages.superPower} /> {index + 1}
            </span>
        </div>
          
            <div className={c.lesson_list_descr} key={nanoid()}>
                 <span c key={nanoid()} className={`ml-1 ${whiteTxt}`}> 
                  {lesson}
                 </span>                   
            </div>           
                          
          </div> 
            <div 
            key={nanoid()}          
            className={`${c.lesson_list_arrow} ${props.active && props.lessonNum === index && c.lesson_list_arrow_rotate} ${whiteTxt}`}
            >            
              <span className={`${whiteTxt}`}>
                {ChevronDownIcon2(25, `${iconColr}`)} 
              </span>             
            </div>            
        </div>         
           
               {/* DRAWER */}
          
                    <div className={`${c.exerc_list}
                           ${props.active && props.lessonNum === index ? 
                           c.exerc_list_container : 
                           c.exerc_list_container_hidden}
                           ${whiteTxt}
                           `} 
                           key={nanoid()}
                           >
                     {props.active && props.exerciseDesc[props.lessonNum].map((desc, index) => 
                     <>
                      <div key={nanoid()} 
                          className={`
                          ${+props.lessonExercPassed <= parseInt(`${props.lessonNum  + 1}0${index}`) ? c.locked_content : '' }
                          ${c.exerc_list_item} 
                          ${+props.lessonExercPassed <= parseInt(`${props.lessonNum  + 1}0${index}`) && c.future_lesson}  
                          ${+props.lessonNum === 0 && index === 0  ? c.open_content : '' }  
                          ${whiteTxt}          
                          `}
                             onClick={() => {props.handleGoToLesson(
                                                index + 1, 
                                                parseInt(`${props.lessonNum  + 1}0${index}`),
                                                props.lessonNum
                                                // getStars(props.lessonNum + 1, index, props.keyBoardLayOut).stars,
                                                // getStars(props.lessonNum + 1, index, props.keyBoardLayOut).tpm
                                                )}}
                      >
                        <div key={nanoid()}>
                          <span className={c.lock_icon} key={nanoid()}>
            {/* Іконка замочка */}
    {/* Умова!!!  */}        {+props.lessonExercPassed <= parseInt(`${props.lessonNum  + 1}0${index}`) ? 
                               (+props.lessonNum + index !== 0 ? LockIcon(18, `${iconColr}`) : UnlockIcon(18, `${iconColr}`)) : 
                               UnlockIcon(18, `${iconColr}`)
                              }  
                          </span>
                          <FormattedMessage id='exerc' defaultMessage={defineMessages.exerc} /> {index + 1} - {desc[1]} - {desc[2]}
                        </div>
                        <div className={c.results_cont} key={nanoid()}>
                          <div key={nanoid()}>
                           {fiveStars.map((starNum) => (
                             <span key={nanoid()}>
                               {getStars(props.lessonNum + 1, index, props.keyBoardLayOut)?.stars >= starNum && <span className='ml-1'>{star}</span>}
                             </span>
                          ))}
                          </div> 
                          {getStars(props.lessonNum + 1, index, props.keyBoardLayOut)?.tpm} {getStars(props.lessonNum + 1, index, props.keyBoardLayOut)?.tpm && 't/m'} 
                                          
                        </div>
                      </div>
                    </>
                     )}
                    </div> {/* DRAWER */} 
         </div>      
        )}
    </div>

    <div className={c.lessons_left_screen_part} key={nanoid()}>      
        {PwrIcon(120, `${starColr}`)}       
     </div>
  
</div>
  )
}

export default AllLessonsView
 //  ${lessonPassed < (lessonNum  + 1) && c.future_lesson}

// {+lessonExercPassed} - {parseInt(`${lessonNum  + 1}0${index}`)}

// {lessonNum !== '' &&
//     <div className={c.exerc_list_container}>
//         <div 
//          className={`${c.exerc_list_header} ${+lessonExercPassed < parseInt(`${lessonNum + 1}00`) && c.future_lesson}`}
//          >
//             Урок: {lessonNum  + 1}
//         </div> 
//           {exerciseDesc[lessonNum].map((desc, index) => 
//             <div key={index} 
//                  className={`
//                  ${c.exerc_list_item} 
//                  ${+lessonExercPassed <= parseInt(`${lessonNum  + 1}0${index}`) && c.future_lesson}                
//                  `}
//                  onClick={() => {handleGoToLesson(index + 1, parseInt(`${lessonNum  + 1}0${index}`))}}
//              >
//                Вправа {index + 1} - {desc[1]} - {desc[2]}<br/>
             
//             </div>
//           )}
//     </div>
//   }