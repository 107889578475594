import { useEffect } from 'react'
import store  from '../Store'
import { usersApiSlice } from '../features/users/usersApiSlice'
import { resultsApiSlice } from '../features/results/resultsApiSlice'
import { Outlet } from 'react-router-dom'

// Provides data subscription to child components
// it refreshesh data in the store and send it to a child
const Prefetch = () => {
    
    useEffect(() => {
          const users = store.dispatch(usersApiSlice.endpoints.getUsers.initiate())
        const results = store.dispatch(resultsApiSlice.endpoints.getResults.initiate())

        return () => {
              users?.unsubscribe()
            results?.unsubscribe()
        }
    }, [])
  return (
    <>
      <Outlet />
    </>
  )
}

export default Prefetch


// useEffect(() => {
//   const usersRequest = store.dispatch(usersApiSlice.endpoints.getUsers.initiate);
//   const resultsRequest = store.dispatch(resultsApiSlice.endpoints.getResults.initiate);

//   return () => {
//       usersRequest.unsubscribe();
//       resultsRequest.unsubscribe();
//   };
// }, []);