import React, { useEffect } from "react";
import s from "./action_btn.module.css";
import c from "../views/home_screen.module.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/languages/messages";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
//import { toggleRpt, increment, decrement } from '../Redux/rptSlice'//sets number of exrs to be rptd
import { 
        changePageHeader, 
        //findRptExerc 
      } from "../Functions";
import useBg from '../Hooks/useBg';

const HomeScreen = () => {

 
  const {yellowTxt, whiteTxt} = useBg();
  const triger = useSelector((state) => state.triger.triger);
  
  //const keyBoardLayOut = useSelector((state) => state.layout.layout)
  
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const link = (data, load, toHUB) => {
    localStorage.setItem("toHighest", load);
    localStorage.setItem("goToHUB", toHUB);
    navigate(data);
  };
  const linkToHighest = (data, load) => {
    localStorage.setItem(
      "exerciseCount",
      parseInt(lessonExercPassed?.slice(1, 3))
    );
    localStorage.setItem("lessonCount", lessonExercPassed?.slice(0, 1));
    localStorage.setItem("lessonComplete", "no");
    localStorage.setItem("toHighest", load);
    navigate(data);
  };
  const startLessonOne = () => {
    localStorage.setItem("lessonStarted", false);
    localStorage.setItem("lessonComplete", "no");
    localStorage.setItem("exerciseCount", 1);
    localStorage.setItem("lessonCount", 1);
    link(`/training`, "no", "no");
  };
  //const allLessons = () => {
  // localStorage.setItem('lessonStarted', false);
  // localStorage.setItem('lessonComplete', 'no');
  // localStorage.setItem('exerciseCount', 1);
  // localStorage.setItem('lessonCount', 1);
  //     link(`/all_lessons`);
  //  };
  const lessonExercPassed = localStorage.getItem("lessonExcercPassed");
  //console.log(lessonExercPassed);

  useEffect(() => {
    changePageHeader(
      triger,
      dispatch,
      "Your Super Power",
      "Твоя Супер Здібність",
      "حقق قواك العظمى"
    );
    // eslint-disable-next-line
  }, [triger]);
  


  return (
    <div className={c.home_screen_wrapper}>
      <div className={`${c.logo_container} `} >
        <motion.div          
          whileHover={{
            originX: 0,
            scale: 1.03,
            textShadow: "0px 0px 2px rgb(255,255,255)",
            color: "#f8e112",
          }}
          transition={{ type: "spring", stiffness: 300 }}>
          <span className={`mr-3 ${c.italic_txt} ${yellowTxt}`}>
            NO TYPO
          </span> 
          <span className={`${yellowTxt}`}>
             -{" "}<FormattedMessage id='logo' defaultMessage={defineMessages.logo} />
          </span>
         
        </motion.div>

        <Button
          variant='success'
          onClick={() =>
            lessonExercPassed === null
              ? startLessonOne()
              : linkToHighest(`/training`, "yes")
          }
          className={` ${c.rouned_btn} ${s.action_btn}`}>
          <span className={`${c.note_notice_txt} `} >
            {lessonExercPassed === null ? (
              <FormattedMessage
                id='headerStartBtn1'
                defaultMessage={defineMessages.headerStartBtn1}
              />
            ) : (
              <FormattedMessage
                id='headerStartBtn2'
                defaultMessage={defineMessages.headerStartBtn2}
              />
            )}
          </span>
        </Button>

        {/* <br/>
        <div>
         <Button variant='success' onClick={() => dispatch(increment())}>
          +
        </Button>
        <Button className='ml-2' variant='success' onClick={() => dispatch(decrement())}>
          -
        </Button>
        <Button variant='danger' onClick={() => dispatch(toggleRpt(10))}>
          toggleRpt
        </Button> 
        </div> */}
        

      </div>

      <div className={c.home_screen_body_holder}>
        <div className={c.home_screen_options_container} />
        <div className={c.home_screen_body_txt}>
          <h3 >
            <span className={yellowTxt}>
               <FormattedMessage
              id='gainPower'
              defaultMessage={defineMessages.gainPower}
            />
            </span>           
            <br />
            <span className={yellowTxt}>
             <FormattedMessage
              id='rightPlace'
              defaultMessage={defineMessages.rightPlace}
             />  
            </span>            
          </h3>
          <div className={c.home_screen_article_container}>
            <div className={c.home_screen_article_containe_row}>
              <div className={c.home_screen_article}>
                <h4 className={yellowTxt}>
                  <FormattedMessage
                    id='howTo'
                    defaultMessage={defineMessages.howTo}
                  />
                </h4>
                <span className={whiteTxt}>
                  <FormattedMessage
                    id='forTouchType'
                    defaultMessage={defineMessages.forTouchType}
                  />{" "} 
                </span>
                
                <br />
                <span className={whiteTxt}>
                  <FormattedMessage
                    id='practiceReg'
                    defaultMessage={defineMessages.practiceReg}
                  /> 
                </span>
              </div>
            </div>

            <div className={c.home_screen_article_containe_row}>
              <div className={c.home_screen_article}>
                <h4 className={yellowTxt}>
                  <FormattedMessage
                    id='homeRow'
                    defaultMessage={defineMessages.homeRow}
                  />
                </h4>
                <span className={whiteTxt}>
                  <FormattedMessage
                  id='yourFingures'
                  defaultMessage={defineMessages.yourFingures}
                  />
                </span>
                
                <br />
                <b className={whiteTxt}>
                  <FormattedMessage
                    id='homeRowPos'
                    defaultMessage={defineMessages.homeRowPos}
                  />
                </b>{" "}
                <br />
                <span className={whiteTxt}>
                 <FormattedMessage
                  id='alwaysStart'
                  defaultMessage={defineMessages.alwaysStart}
                 /> 
                </span>
                
              </div>
              <div className={c.img_wrapper}>
                <div className={c.home_position_pic} />
              </div>
            </div>

            <div className={c.home_screen_article_containe_row}>
              <div className={c.home_screen_article}>
                <h4 className={yellowTxt}>
                  <FormattedMessage
                    id='finMo'
                    defaultMessage={defineMessages.finMo}
                  />
                </h4>
                <span className={whiteTxt}>
                 <FormattedMessage
                  id='finMoPar'
                  defaultMessage={defineMessages.finMoPar}
                 /> 
                </span>
                
                <br />
                <br />
                <h4 className={yellowTxt}>
                  <FormattedMessage
                    id='tySpd'
                    defaultMessage={defineMessages.tySpd}
                  />
                </h4>
                <span className={whiteTxt}>
                  <FormattedMessage
                    id='tySpdPar1'
                    defaultMessage={defineMessages.tySpdPar1}
                  />  
                </span>
                
                <br />
                <span className={whiteTxt}>
                  <FormattedMessage
                  id='tySpdPar2'
                  defaultMessage={defineMessages.tySpdPar2}
                  />  
                </span>
              
                <br />
                <span className={whiteTxt}>
                 <FormattedMessage
                  id='tySpdPar3'
                  defaultMessage={defineMessages.tySpdPar3}
                  />  
                </span>
                
                <br />
              </div>
              <div className={c.img_wrapper}>
                <div className={c.touch_typing_pic} />
              </div>
            </div>

            <div className={c.home_screen_article_containe_row}>
              <div className={c.home_screen_article}>
                <h4 className={yellowTxt}>
                  <FormattedMessage
                    id='TrainAny'
                    defaultMessage={defineMessages.TrainAny}
                  />
                </h4>
                <span className={whiteTxt}>
                  <FormattedMessage
                  id='TrainAnyPar1'
                  defaultMessage={defineMessages.TrainAnyPar1}
                   />
                </span>
                
                <br />
                <span className={whiteTxt}>
                 <FormattedMessage
                  id='TrainAnyPar2'
                  defaultMessage={defineMessages.TrainAnyPar2}
                 />  
                </span>
               
                <br />
                <span className={whiteTxt}>
                 <FormattedMessage
                  id='TrainAnyPar3'
                  defaultMessage={defineMessages.TrainAnyPar3}
                 />  
                </span>
                
              </div>
              <div className={c.img_wrapper}>
                <div className={c.no_net_pic} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;

//   {/* {soundOff !== 'yes' ? (
//       <motion.div
//                onClick={() => setSchedric(true)}
//                className={`mt-3 ${c.link}`}
//                whileHover={{
//                 scale: 1.1,
//                 textShadow: '0px 0px 8px rgb(255,255,255)',
//                 color: '#f8e112',
//             }}
//             transition={{ type: 'spring', stiffness: 300 }}
//         >
//             <FormattedMessage id='schedric' defaultMessage={defineMessages.schedric} />
//            <br/>
//            <FormattedMessage id='schedric2' defaultMessage={defineMessages.schedric2} />
//         </motion.div>
//   ) : (
//     <div className={c.no_sound}>
//         <FormattedMessage id='schedric' defaultMessage={defineMessages.schedric} />
//          <br/>
//         <FormattedMessage id='schedric3' defaultMessage={defineMessages.schedric3} />
//     </div>
//   )} */}

//         {/* <motion.div
//                onClick={() => link(`/lesson_completed`, 'no')}
//                className={`mt-3 ${c.link}`}
//                whileHover={{
//                 scale: 1.1,
//                 textShadow: '0px 0px 8px rgb(255,255,255)',
//                 color: '#f8e112',
//             }}
//             transition={{ type: 'spring', stiffness: 300 }}
//         >
//            Lesson End Screen
//         </motion.div> */}

//   <div className={c.lang_intro_wrapper}>
//   <h4>
//    <FormattedMessage id='multLangInt' defaultMessage={defineMessages.multLangInt} />
//   </h4>
//   <div className={c.lang_intro_description} onClick={() => setShowHome(!showHome)}>
//    <FormattedMessage id='chkStg' defaultMessage={defineMessages.chkStg} /> {gearIcon(25)} <FormattedMessage id='toChoose' defaultMessage={defineMessages.toChoose} />
//   </div>

//   <div className={c.flags_container}>
//       <div className={c.flag_item1} onClick={() => context.selectLangString('en')}/>
//       <div className={c.flag_item2} onClick={() => context.selectLangString('ua')} />
//       <div className={c.flag_item3} onClick={() => context.selectLangString('ar')}/>
//   </div>
// </div>

// useEffect(() => {
//     if(schedric && !noRepeat && soundOff !== 'yes') {
//        // console.log('go', schedric, noRepeat);
//         setNoRepeat(true);
//         playSound(Schedric);
//         setTimeout(() => setNoRepeat(false),40000);
//         setTimeout(() => setSchedric(false),35000);
//       }
// // eslint-disable-next-line
// },[schedric, soundOff]);

// import {playSound} from '../Components/PlaySound';
// import Schedric from '../assets/Schedric.mp3';
