import React, { useState, useEffect, useContext } from 'react';
import { 
        lessonHeader, 
        exerciseDesc, 
        lessonHeaderEN, 
        exerciseDescEN,
        lessonHeaderAR, 
        exerciseDescAR 
       } from '../models/Tasks';
import { useNavigate } from "react-router-dom";
import { ExercContext } from '../Context/ExercContext';
import AllLessonsView from '../views/AllLessonsView';
import HUBview from '../views/HUBview';
import c from '../views/post_lesson.module.css';
import k from '../Components/MenuButtons/settings.module.css'
import  { 
           useSelector,
           useDispatch 
        } from 'react-redux'
import { changeLessonDescription, changePageHeader } from '../Functions';
import { nanoid } from '@reduxjs/toolkit';
import AnaliticsView from '../views/AnaliticsView';
import AllPowersIcon from '../Components/MenuButtons/AllPowers';
import GraphIcon from '../Components/MenuButtons/GraphIcon';
import RepeatIcon from '../Components/MenuButtons/RepeatIcon';
import AllLessonsLeftSide from '../views/AllLessonsLeftSide';

const AllLessonsController = () => {

const dispatch = useDispatch()
const navigate = useNavigate();
const link = (data) => {
    navigate(data);
};
const direction = localStorage.getItem("AllLessDirection");
const keyBoardLayOut = useSelector((state) => state.layout.layout)
const triger = useSelector((state) => state.triger.triger)
const { setRptExrc }  = useContext(ExercContext);
const lessonExercPassed = localStorage.getItem('lessonExcercPassed');
const graduated = localStorage.getItem('graduated') ? localStorage.getItem('graduated') : 0;
const exercToRe = JSON.parse(localStorage.getItem(`${keyBoardLayOut}exercToRe`));
const [lessonNum, setLessonNum] = useState('');
const [active, setActive] = useState(false);



const handleLessonNumSet = (num) => {
  setLessonNum(num);
  if(active && num === lessonNum) {
    setActive(false);
  } else {
    setActive(true);
  }
  
};

const linkToHighest = (data, load) => {
  localStorage.setItem('exerciseCount', parseInt(lessonExercPassed?.slice(1, 3)));
  localStorage.setItem('lessonCount', lessonExercPassed?.slice(0, 1));
  localStorage.setItem('lessonComplete', 'no');   
  localStorage.setItem('toHighest', load);
  navigate(data);
};

const handleGoToLesson = (num, num2, lessonNum, rptd) => {
//console.log(num, num2, lessonNum + 1);
   localStorage.setItem('rptLsnExrc', `${num2 + 1}`); // Це номер вправи для редагування

  let existingLocStorData = JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lessonNum + 1}Res`));
   console.log(`${keyBoardLayOut}Lesson${lessonNum + 1}Res`, existingLocStorData, '-existingLocStorData')
 // console.log(existingLocStorData, lsnNum)
   if(existingLocStorData === null && rptd !== 'rptd') {
      localStorage.setItem('lessonStarted', false);
      localStorage.setItem('lessonComplete', 'no');
      localStorage.setItem('exerciseCount', num);
      localStorage.setItem('lessonCount', lessonNum + 1);
      link(`/training`);
   } else {
   //  let ecercResultToEdit = existingLocStorData.find(data => +data.id === +num2+1);
   // console.log('ecercResultToEdit', ecercResultToEdit);
     if(+lessonExercPassed >= parseInt(`${num2 + 2}`)) {
      //console.log('--1--',+lessonExercPassed, +lessonNum + 1, num, '--11--', parseInt(`${num2}`));
      let data = `${num2}`;
      localStorage.setItem('rptLsn', data.slice(0,1));
      localStorage.setItem('rptExrc', num);
     
      setRptExrc(num);
      navigate('/exercise_rpt');        
    } else if(+lessonExercPassed === parseInt(`${num2 + 1}`)) {
        if(parseInt(`${graduated}11`) > parseInt(`${num2 + 1}`)) {
    //console.log('Вправа у пройденному Уроці', parseInt(`${graduated}11`), parseInt(`${num2 + 1}`));
           localStorage.setItem('lessonCount', +lessonNum + 1);
           localStorage.setItem('exerciseCount', num);
          // link(`/training`);
          link(`/exercise_rpt`);
        } else {
          console.log('Вправа за межами пройдених Уроків', parseInt(`${graduated}11`), parseInt(`${num2 + 1}`));
          linkToHighest(`/training`, 'yes')
        }       
    } else {
      return;
    }
   }
   
};
//////////////// Language Block //////////////
/////////////////////////////////////////////
const [desc, setDesc] = useState(exerciseDesc);
const [header, setHeader] = useState(exerciseDesc);
//const [headerToRe, setHeaderToRe] = useState([]);

//console.log('header -', header, 'exercToRe - ', exercToRe)

// useEffect(() => {
//   let data = exercToRe.map(item => header?.filter(exrc => exrc.id === item.id));
//   console.log(data)
//   //setHeaderToRe(data)
// }, [header, exercToRe])

useEffect(() => { 
  changeLessonDescription(triger, setDesc, setHeader,
                     exerciseDescEN, lessonHeaderEN, exerciseDesc, 
                     lessonHeader, exerciseDescAR, lessonHeaderAR)
  changePageHeader(triger, dispatch, 'All Super Powers', 'Усі Здібності', 'جميع القوى العظمى')  
  // eslint-disable-next-line 
}, [triger]);
/////////////////////////////////////////////
const [openView, setOpenView] = useState(false);
const [indexTrecker, setIndexTrecker] = useState(1000)

// useEffect(() => {
//   setOpenView(prev => !prev)
//    console.log(openView, indexTrecker)
// }, [indexTrecker])

const handleOpenView = (index) => { 
  if(!openView) {
    setIndexTrecker(index)
    setOpenView(true)
  //  console.log(openView, indexTrecker,index)
  } else { return }
  
  

}
const handleCloseView = () => {
  setOpenView(false)
  setIndexTrecker(1000)
}
const [dataToShow, setDataToShow] = useState([])

let TotalRecords = JSON.parse(localStorage.getItem(`${keyBoardLayOut}TotalRecords`)) || [];
//console.log(TotalRecords)
  
useEffect(() => {
 // let ordered = TotalRecords.
 function calculateSuccessRate(obj) {
  return ((100 - (+obj.f / (+obj.t + +obj.f))*100).toFixed(1));  
}
TotalRecords.forEach(obj => {
  obj.successRate = calculateSuccessRate(obj);
});
let sortedOne = TotalRecords?.sort((a, b) => a.successRate - b.successRate);

setDataToShow(sortedOne)

  },[]) 
    
  return (
    <div className={`${c.wrapper} ${c.row_container}`} key={nanoid()}> 
      <AllLessonsLeftSide />

      <div>
          {direction === 'HUB' && 
            <HUBview         
            exercToRe={exercToRe}
            handleGoToLesson={handleGoToLesson}
            exerciseDesc={desc}
            />}
          
          {direction === 'Graph' &&
            <AnaliticsView 
                exercToRe={exercToRe}
                handleGoToLesson={handleGoToLesson}
                exerciseDesc={desc}

                keyBoardLayOut={keyBoardLayOut}
                openView={openView}
                setOpenView={setOpenView}
                indexTrecker={indexTrecker}
                handleOpenView={handleOpenView}
                handleCloseView={handleCloseView}

                TotalRecords={dataToShow}
              
            />}

          {direction === 'AllLess' &&
            <AllLessonsView 
            lessonHeader={header}
            exerciseDesc={desc}
            handleLessonNumSet={handleLessonNumSet} 
            handleGoToLesson={handleGoToLesson}
            active={active}
            keyBoardLayOut={keyBoardLayOut}
            lessonNum={lessonNum}
            graduated={graduated}
            lessonExercPassed={lessonExercPassed}
            />}

      </div> 

     

    </div>
  )
}

export default AllLessonsController




