//import React, { useState, useEffect, useContext } from 'react';
//import { calculateStars, calculateTPM, findUnder4Stars } from '../Functions';
// import { playSound } from '../Components/PlaySound';
// import sound from '../assets/bells.mp3';
// import fanfars from '../assets/fanfars.mp3';
// import { ServiceContext } from '../Context/ServiceContext';
// import { ExercContext } from '../Context/ExercContext';
// import LessonEndView from '../views/LessonEndView';
// import { useHistory } from "react-router-dom";


import NewLessonStartView from '../views/NewLessonStartView';

const NewLessonStartController = () => {
  return (
    <div>       
        <NewLessonStartView  />
    </div>
  )
}

export default NewLessonStartController