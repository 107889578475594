import React from 'react';
import { useNavigate } from "react-router-dom";
import c from './settings.module.css';
import s from './rotateLtr.module.css'
import  { useSelector } from 'react-redux'
import CircularText from './CircularText';
import { ReIcon } from '../Icons';
import useBg from '../../Hooks/useBg';


 
const RepeatIcon = ( props ) => {

    const rpt = useSelector((state) => state.rpt.rpt)
    const navigate = useNavigate();
    const { starColr } = useBg();

 
/////////////////////////////////////////////////

 

 const link = (data, AllLessDirection) => {
   
    localStorage.setItem("AllLessDirection", AllLessDirection);
    navigate(data);
  };

  const text = [
    '','','','','','','','','','R','','E','','-','','D','','O','','|','',
    'R','','E','','-','','T','','','R','','','A','','','I','','','N',
    '','|',
        ] 

  return (

      <div className={`${c.re_abs_div} ${props.cla}`} onClick={() => link(`/all_lessons`, "HUB")}>
        <div className={`${c.re_abs_div_above}  `}>
           <div className={`${c.re_abs_div_above_txt} ${c.action_btn}`}>
             {rpt}
           </div>
          <div className=' '>
            {/* Re.. */}
            {ReIcon(36, `${starColr}`)}
          </div> 
            <CircularText text={text} cla={`${s.rotate_all} ${s.rotate_re}`} />
        </div> 
      </div>
  
  )
}

export default RepeatIcon