import React, { useState, useContext, useEffect } from 'react';
import { gearIcon } from '../../Components/Icons.js';
//import { Globe } from '../assets/globe2.svg';
import c from './settings.module.css';
import {backgrounds} from '../../Components/backgrounds.js';
import { Context } from '../../Lang/LanguageProvider.js';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../../Lang/languages/messages.js';
import useBg from '../../Hooks/useBg';
import  { useSelector, useDispatch } from 'react-redux'
import { toggleBg } from '../../Redux/bgSlice.js'
import { toggleTriger } from '../../Redux/trigerSlice.js'
import CircularText from './CircularText.js';
import s from './rotateLtr.module.css';
 
const Settings = ({ hide, home, setShowHome, showHome }) => {

   const bg = useSelector((state) => state.bg.bg)   
   const dispatch = useDispatch()

  const context = useContext(Context); 
  const { starColr } = useBg();
  const [counter, setCounter] = useState(25);
  const [go, setGo] = useState(false);

 
 const [show, setShow] = useState(false);
/////////////////////////////////////////////////
 const handleChange = (value) => {
    // setBg(value);
    // localStorage.setItem('bg', value);
    dispatch(toggleBg(value))

    setGo(false); 
    setTimeout(() => {
      setGo(true);
    }, 1000);     
 };
 const handleShow = () => {
  if(!home) {
    setShow(!show);
  } else {
    setShowHome(!showHome)
  }
  
  setGo(!go); 
 };

 
 useEffect(() => {
  if (!bg) {
    //setBg('3');
    dispatch(toggleBg('3'))   
  } else {return};
  // eslint-disable-next-line
 },[]);
//  function mouseL () {
//    alert('exit');
//  };
 //onmouseleave={}



useEffect(() => {     
    if (!go) {
      setCounter(25);
    }
    if (go && counter > 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
    if (go && counter === 0) {
      setShow(false);
    }
   // eslint-disable-next-line
}, [show, counter, go]);

useEffect(() => { 
    setCounter(0);
    setGo(false);    
    setCounter(25); 
 // eslint-disable-next-line
}, [bg]);
// {counter}   {go && 'yes'} 

const bgItem = (num, bgNum, name) => {
  return (
    <div className={`${c.bg} ${bgNum}  ${bg === `${num}` && c.outlined}`} onClick={() => handleChange(`${num}`)}>
      {name}
    </div>
  );
};
const text = [
  'S','','E','','T','','T','','I','','N','','G','','S','','','|','',
  '','S','','E','','T','','T','','I','','N','','G','','S','','','|','',
      ] 
  return (
    <>
         

      <div className={c.gear_whele_abs_div} onClick={() => handleShow()}>
        <div className={!home ? (show ? c.gear_wheel : c.gear_wheel_init) : (showHome ? c.gear_wheel : c.gear_wheel_init)}>
          {gearIcon(30, `${starColr}`)}
        </div>
        <CircularText text={text} cla={`${s.rotate_all} ${s.rotate_sett}`}/>
      </div>
        
        <div className={`
                       ${c.settings_wrapper} 
                       ${!home ? (show ? c.show_wrapper : hide ? c.displayNone : c.hide_wrapper) : (showHome ? c.show_wrapper : hide ? c.displayNone : c.hide_wrapper)}
                       `}  >
              <div className={c.lang_box_bg}>
                <span className={c.txt_yellow}>
                  <FormattedMessage id='chooseLang' defaultMessage={defineMessages.chooseLang} />
                </span> 
                <select className={c.lang_select_abs_div} onClick={(e) =>  dispatch(toggleTriger(e.target.value))} onChange={context.selectLang} value={context.locale}>                
                    <option onClick={() =>  dispatch(toggleTriger('ua'))} value="ua">🌐 UA</option>
                    <option onClick={() =>  dispatch(toggleTriger('en'))} value="en">🌐 EN</option>
                    <option onClick={() =>  dispatch(toggleTriger('ar'))} value="ar">🌐 AR</option>
                </select> 
              </div>
                  


            <div className={c.menu_header}>
             <FormattedMessage id='chooseColour' defaultMessage={defineMessages.chooseColour} /> 
            </div>
            {backgrounds.map((bg, index) => 
              <div key={bg.name}>
                 {bgItem(index+1, bg.bgNum, bg.name)}
              </div>
            )} 
        </div>  
      
    </>
    
  )
}

export default Settings