import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";




const resultsAdapter = createEntityAdapter({})

const initialState = resultsAdapter.getInitialState()

export const resultsApiSlice = apiSlice.injectEndpoints({ // Dowloading ALL RESULTS from DB // Можна адаптувати за для завантаження будь якиого масива данніх 
    endpoints: builder => ({
        getResults: builder.query({
////////////////////////////////////////////// Check End Point 

            query: () => '/user_results', // За для адаптації потрібно замінити endpoint та назви переміних
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
           // keepUnusedDataFor: 1200, //   60 sec of Data Cashing
            transformResponse: responseData => { // Міняе result._id на result.id для подальшого використов.
                const loadedResults = responseData.map(result => {
                    result.id = result._id
                    return result
                });
                return resultsAdapter.setAll(initialState, loadedResults)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        { type: 'Result', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Result', id }))
                    ]
                } else return [{ type: 'Result', id: 'LIST' }]
            }
        }),// getResults ends
        addNewResult: builder.mutation({
            query: initialResult => ({
                url:'/user_results',
                method: 'POST',
                body: {
                    ...initialResult,
                }
            }),
            invalidatesTags: [
                { type: 'Result', id: "LIST" } // type: - provided in apiSlice - tagTypes
            ]   
        }),
        updateResult: builder.mutation({
            query: initialResult => ({
                url:'/user_results',
                method: 'PATCH',
                body: {
                    ...initialResult,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Result', id: arg.id }
            ]  
        }),
        deleteResult: builder.mutation({
            query: ({ id }) => ({
                url:'/user_results',
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Result', id: arg.id }
            ]  
        }),
    }),// builder ends
})

export const {
    useGetResultsQuery, // this Hook is automaticly generated by resultsApiSlice based on endpoint from getResults, такий Hook має починатися з  use та закінч.з Query
    useAddNewResultMutation,
    useUpdateNewResultMutation,
    useDeleteNewResultMutation,
} = resultsApiSlice


// returns the query result object
export const selectResultsResult = resultsApiSlice.endpoints.getResults.select()

// create memoized selector
const selectResultsData = createSelector(
    selectResultsResult,
    resultsResult => resultsResult.data // normalized state object with ids & entities
)
// getSelectors creates these selectors and we rename them aliases using destructuring
export const {
    selectAll: selectAllResults,
    selectById: selectResultById,
    selectIds: selectResultsIds
    //Pass in a selector that returns the results slice of state
} = resultsAdapter.getSelectors(state => selectResultsData(state) ?? initialState)
// ?? - null .... operator - if selectResultsData(state) === null => state => initialState