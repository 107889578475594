import React from 'react'
import c from './login.module.css'
import { motion } from 'framer-motion/dist/framer-motion';
import AlertDangerRound from '../Modal/AlertDangerRound';
import { Spinner } from 'react-bootstrap';

const LoginScreen3 = (props) => {
  return (
    <motion.div
    key='screen_1'
    animate={{x:"0%", y: "0%", opacity: 1, scale: 1}}
    initial={{ x:"-100%", y: "0%", opacity: 0, scale: 1}}
    exit={{x:"100%", y: "0%", transition: { duration: 0.8}}}
    transition={{ duration: 0.8, ease: "easeOut"}}
    className={c.screen_box}
>
  {props.isLoading ? 
    <Spinner  animation="border" variant="success" size="xl"/> : props.errMsg ?
    <AlertDangerRound alertHeader={props.errMsg} triger={props.errMsg}/> :
    <div className={c.congrats}>
        Welcome back<br/> <br/>
        {props.username}        
    </div>
  }
</motion.div>
  )
}

export default LoginScreen3