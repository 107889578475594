import React from 'react';
import { Alert } from 'react-bootstrap';
import {exclamationCircleIcon} from '../Icons';
import c from './alert.module.css'

const AlertDangerRound = (props) => {
    return (
     <>
        {props.triger &&
        <Alert variant='danger' className={c.alert}>
          {props.alertHeader}
           <span className="ml-3" >{exclamationCircleIcon()}</span>
        </Alert>
        }
     </>
    )
}

export default AlertDangerRound
