
// 4175
 export const lettersContent = [
    
        {ltr:'j', exerc:
                 [{id:101, rpt:25}, {id:103, rpt:12}, {id:104, rpt:11},{id:106, rpt:5},{id:110, rpt:4},
                  {id:204, rpt:1},{id:205, rpt:1},{id:207, rpt:1},{id:209, rpt:1},{id:210, rpt:1},
                  {id:305, rpt: 1},{id:308, rpt: 2},{id:309, rpt: 1},{id:310, rpt: 2},{id:409, rpt: 1},
                  {id:601, rpt: 5},{id:607, rpt: 2},{id:609, rpt: 1},{id:610, rpt: 1},{id:807, rpt: 2},
                  {id:809, rpt: 1},{id:810, rpt: 1},{id:1003, rpt: 1},{id:1009, rpt: 2}]},
 // j  1-57 2-5 3-6 4-1 6-9 8-10 10-3 = 91  

        {ltr:'k', exerc:
                 [{id:101, rpt:25}, {id:103, rpt:13}, {id:104, rpt:17}, {id:106, rpt:7},{id:108, rpt:2},{id:109, rpt:2},{id:110, rpt:4},
                  {id:205, rpt:2},{id:206, rpt:3},{id:207, rpt:1},{id:209, rpt:1},{id:210, rpt:1},{id:306, rpt: 4},
                  {id:307, rpt: 3},{id:308, rpt: 4},{id:309, rpt: 2},{id:310, rpt: 2},{id:409, rpt: 3},{id:502, rpt: 2},
                  {id:703, rpt: 12},{id:704, rpt: 6},{id:707, rpt: 4},{id:708, rpt: 2},{id:709, rpt: 3},{id:710, rpt: 3},                
                  {id: 802, rpt: 12},{id: 803, rpt: 12},{id: 804, rpt: 6},{id: 805, rpt: 6},{id: 807, rpt: 8},{id: 808, rpt: 4},{id: 809, rpt: 6},{id: 810, rpt: 6},
                  {id: 903, rpt: 6},{id: 907, rpt: 2},{id: 909, rpt: 1},{id: 910, rpt: 1},{id: 1003, rpt: 1},{id: 1005, rpt: 2},{id: 1007, rpt: 2},{id: 1009, rpt: 1}]},
 // k   1-70  2-8  3-15  4-3  5-2  7-30  8-60  9-10  10-6  = 204 

        {ltr:'d', exerc:
                 [{id:102, rpt:25}, {id:103, rpt:12}, {id:104, rpt:12},{id:106, rpt:4},{id:108, rpt:9},{id:109, rpt:6},{id:110, rpt:5},
                  {id:201, rpt:3},{id:202, rpt:2},{id:204, rpt:1},{id:205, rpt:5},{id:206, rpt:2},{id:207, rpt:3},{id:209, rpt:4},{id:210, rpt:2},
                  {id: 302, rpt: 1},{id: 305, rpt: 1},{id: 306, rpt: 1},{id: 308, rpt: 3},{id: 402, rpt: 4},{id: 404, rpt: 1},{id: 406, rpt: 1},{id: 408, rpt: 1},{id: 409, rpt: 3},{id: 410, rpt: 1},
                  {id: 603, rpt: 12}, {id: 643, rpt: 6}, {id: 606, rpt: 6}, {id: 607, rpt: 4}, {id: 608, rpt: 4}, {id: 609, rpt: 4}, {id: 610, rpt: 4},
                  {id: 701, rpt: 6},{id: 702, rpt: 6},{id: 707, rpt: 4},{id: 709, rpt: 2},{id: 710, rpt: 2},
                  {id: 906, rpt: 6},{id: 908, rpt: 2},{id: 909, rpt: 1},{id: 910, rpt: 1},{id: 1003, rpt: 3},{id: 1007, rpt: 1},{id: 1009, rpt: 2},{id: 1010, rpt: 2}
                ]},
// d   1-73  2-22  3-6  4-11  6-40  7-20  9-10  10-8  = 190  

        {ltr:'f', exerc:
                [{id:102, rpt:25}, {id:103, rpt:13}, {id:104, rpt:10}, {id:106, rpt:16},{id:108, rpt:3},{id:109, rpt:4},{id:110, rpt:5},
                {id:201, rpt:2},{id:202, rpt:4},{id:204, rpt:1},{id:205, rpt:2},{id:206, rpt:3},{id:207, rpt:3},{id:209, rpt:3},{id:210, rpt:3},
                {id: 305, rpt: 1},{id: 306, rpt: 3},{id: 308, rpt: 1},{id: 402, rpt: 2},{id: 404, rpt: 1},{id: 705, rpt: 6},{id: 706, rpt: 6},{id: 708, rpt: 4},{id: 709, rpt: 2},{id: 710, rpt: 2},
                {id: 801, rpt: 6},{id: 803, rpt: 6},{id: 807, rpt: 4},{id: 809, rpt: 2},{id: 810, rpt: 2},{id: 901, rpt: 6},{id: 905, rpt: 6},{id: 907, rpt: 2},
                {id: 908, rpt: 2},{id: 909, rpt: 2},{id: 910, rpt: 2},{id: 1005, rpt: 1},{id: 1007, rpt: 1},{id: 1010, rpt: 1}
                ]},
// f   1-76  2-21  3-5  4-3  7-20  8-20  9-20  10-3  = 148   

        {ltr:'l', exerc:
                [{id:105, rpt:25}, {id:106, rpt:7},{id:108, rpt:8},{id:109, rpt:11},{id:110, rpt:11},
                 {id:201, rpt:3},{id:202, rpt:7},{id:205, rpt:1},{id:206, rpt:3},{id:207, rpt:2},{id:209, rpt:4},{id:210, rpt:5},
                 {id: 303, rpt: 3},{id: 306, rpt: 3},{id: 307, rpt: 4},{id: 308, rpt: 1},{id: 309, rpt: 2},{id: 310, rpt: 1},
                 {id: 402, rpt: 4},{id: 404, rpt: 3},{id: 406, rpt: 2},{id: 410, rpt: 3},{id: 502, rpt: 1},{id: 504, rpt: 4},{id: 506, rpt: 2},{id: 509, rpt: 5},
                 {id: 604, rpt: 12},{id: 605, rpt: 6},{id: 606, rpt: 6},{id: 608, rpt: 8},{id: 609, rpt: 4},{id: 610, rpt: 4},
                 {id: 704, rpt: 12},{id: 705, rpt: 12},{id: 706, rpt: 12},{id: 708, rpt: 12},{id: 709, rpt: 6},{id: 710, rpt: 6},
                 {id: 801, rpt: 12},{id: 807, rpt: 4},{id: 809, rpt: 2},{id: 810, rpt: 2},{id: 901, rpt: 12},{id: 902, rpt: 6},{id: 907, rpt: 6},{id: 909, rpt: 3},{id: 910, rpt: 3},
                 {id: 1003, rpt: 2},{id: 1005, rpt: 5},{id: 1009, rpt: 2},{id: 1010, rpt: 3}
                ]},
// l   1-62  2-25  3-14  4-12  5-12  6-40  7-60  8-20  9-30  10-12  =287   

        {ltr:';', exerc:
                [{id:105, rpt:25}, {id:106, rpt:10},{id:108, rpt:6},{id:109, rpt:5},{id:110, rpt:5},{id:201, rpt:4},{id:202, rpt:3},{id:204, rpt:2},
                 {id:205, rpt:2},{id:206, rpt:4},{id:207, rpt:1},{id:209, rpt:1},{id: 309, rpt: 3},{id: 310, rpt: 2},{id: 410, rpt: 1},{id: 509, rpt: 1},                 
                ]},
// ;   1-51  2-17  3-5  4-1  5-1  = 75  

        {ltr:'a', exerc:
                [{id:107, rpt:25},{id:108, rpt:13},{id:109, rpt:13},{id:110, rpt:8},{id:201, rpt:13},{id:202, rpt:12},{id:204, rpt:15},{id:205, rpt:13},
                 {id:206, rpt:12},{id:207, rpt:10},{id:209, rpt:6},{id:210, rpt:3},{id:302, rpt:12},{id:303, rpt:9},{id:305, rpt:8},{id:306, rpt:7},{id:307, rpt:7},{id:308, rpt:3},
                 {id:309, rpt:8},{id:310, rpt:5},{id:402, rpt:2},{id:404, rpt:2},{id:408, rpt:8},{id:409, rpt:3},{id:410, rpt:3},
                 {id:502, rpt:3},{id:504, rpt:4},{id:506, rpt:1},{id:509, rpt:4},{id:510, rpt:1},{id:601, rpt:11},{id:604, rpt:6},{id:605, rpt:12},{id:606, rpt:6},{id:607, rpt:4},
                 {id:608, rpt:8},{id:609, rpt:6},{id:610, rpt:6},{id:702, rpt:6},{id:704, rpt:6},{id:705, rpt:6},{id:706, rpt:6},{id:707, rpt:2},{id:708, rpt:6},{id:709, rpt:4},{id:710, rpt:4},
                 {id:801, rpt:6},{id:802, rpt:6},{id:803, rpt:6},{id:804, rpt:6},{id:806, rpt:6},{id:807, rpt:6},{id:808, rpt:4},{id:809, rpt:5},{id:810, rpt:5},
                 {id:902, rpt:6},{id:903, rpt:12},{id:904, rpt:6},{id:906, rpt:6},{id:907, rpt:6},{id:908, rpt:4},{id:909, rpt:5},{id:910, rpt:5},
                 {id:1003, rpt:5},{id:1005, rpt:3},{id:1007, rpt:3},{id:1009, rpt:1},{id:1010, rpt:1}
                ]},
 // a  1-59  2-84  3-59  4-18  5-13  6-59  7-40  8-50  9-50  10-13  = 445 

        {ltr:'s', exerc:
                [{id:107, rpt:25},{id:108, rpt:6},{id:109, rpt:7},{id:110, rpt:1},{id:201, rpt:5},{id:202, rpt:6},{id:204, rpt:1},{id:205, rpt:5},{id:206, rpt:6},{id:207, rpt:4},
                 {id:209, rpt:2},{id:210, rpt:8},{id:302, rpt:2},{id:303, rpt:2},{id:305, rpt:1},{id:306, rpt:5},{id:307, rpt:3},{id:308, rpt:2},{id:309, rpt:2},{id:310, rpt:4},
                 {id:402, rpt:1},{id:404, rpt:3},{id:406, rpt:2},{id:408, rpt:1},{id:409, rpt:2},{id:410, rpt:2},{id:502, rpt:1},{id:504, rpt:1},{id:506, rpt:2},{id:508, rpt:1},{id:509, rpt:5},
                 {id:601, rpt:5},{id:603, rpt:12},{id:604, rpt:6},{id:605, rpt:6},{id:606, rpt:6},{id:607, rpt:6},{id:608, rpt:6},{id:609, rpt:6},{id:610, rpt:6},
                 {id:701, rpt:6},{id:702, rpt:6},{id:703, rpt:12},{id:704, rpt:6},{id:706, rpt:6},{id:707, rpt:8},{id:708, rpt:4},{id:709, rpt:6},{id:710, rpt:6},
                 {id:804, rpt:6},{id:805, rpt:6},{id:806, rpt:12},{id:808, rpt:8},{id:809, rpt:4},{id:810, rpt:4},{id:903, rpt:6},{id:904, rpt:12},{id:907, rpt:2},
                 {id:908, rpt:4},{id:909, rpt:3},{id:910, rpt:3},{id:1003, rpt:2},{id:1007, rpt:5},{id:1009, rpt:2},{id:1010, rpt:1}
                ]},
// s   1-39  2-37  3-21  4-11  5-10  6-59  7-60  8-40  9-30  10-10  = 317  

        {ltr:'g', exerc:
                [{id:201, rpt:8},{id:202, rpt:4},{id:204, rpt:4},{id:205, rpt:1},{id:206, rpt:2},{id:207, rpt:2},{id:209, rpt:2},{id:302, rpt:4},{id:303, rpt:2},{id:305, rpt:2},
                 {id:308, rpt:2},{id:310, rpt:1},{id:402, rpt:1},{id:404, rpt:1},{id:410, rpt:2},{id: 502, rpt: 1},{id:904, rpt:6},{id:905, rpt:12},{id:908, rpt:6},{id:909, rpt:3},{id:910, rpt:3}
               ]},
// g   2-23  3-11  4-4  5-1  9-30  = 69 

        {ltr:'h', exerc:
                [{id:201, rpt:6},{id:202, rpt:8},{id:204, rpt:2},{id:205, rpt:3},{id:206, rpt:4},{id:207, rpt:2},{id:209, rpt:1},{id:210, rpt:2},
                 {id:302, rpt:1},{id:305, rpt:1},{id:306, rpt:3},{id:307, rpt:1},{id:308, rpt:2},{id:309, rpt:3},{id:402, rpt:3},{id:404, rpt:2},
                 {id:406, rpt:1},{id:410, rpt:2},{id:509, rpt:1},{id:602, rpt:12},{id:603, rpt:6},{id:605, rpt:6},{id:607, rpt:6},{id:608, rpt:2},
                 {id:609, rpt:4},{id:610, rpt:4},{id:804, rpt:6},{id:808, rpt:2},{id:809, rpt:1},{id:810, rpt:1},{id:906, rpt:12},{id:908, rpt:4},
                 {id:909, rpt:2},{id:910, rpt:2},{id:1003, rpt:4},{id:1007, rpt:1},{id:1010, rpt:1}
                ]},
// h   2-28  3-11  4-8  5-1  6-40  8-10  9-20  10-6  = 124  

        {ltr:'y', exerc:
                [{id:208, rpt:25},{id:209, rpt:10},{id:210, rpt:2},{id:302, rpt:1},{id:303, rpt:6},{id:305, rpt:1},{id:306, rpt:3},{id:307, rpt:5},
                 {id:308, rpt:1},{id:310, rpt:5},{id:404, rpt:1},{id:406, rpt:3},{id:408, rpt:2},{id:409, rpt:2},{id:502, rpt:1},{id:702, rpt:6},
                 {id:707, rpt:2},{id:709, rpt:1},{id:710, rpt:1},{id:801, rpt:6},{id:805, rpt:12},{id:807, rpt:2},{id:808, rpt:4},{id:809, rpt:3},{id:810, rpt:3},
                 {id:1005, rpt:2},{id:1007, rpt:1},{id:1009, rpt:1}
                ]},
 // y    2-37  3-22  4-8  5-1  7-10  8-30 10-4  =  112

        {ltr:'u', exerc:
                [{id:208, rpt:25},{id:209, rpt:4},{id:210, rpt:10},{id:302, rpt:2},{id:303, rpt:6},{id:305, rpt:7},{id:306, rpt:5},{id:307, rpt:3},{id:308, rpt:3},
                 {id:309, rpt:2},{id:310, rpt:5},{id:402, rpt:1},{id:406, rpt:1},{id:408, rpt:1},{id:409, rpt:1},{id:410, rpt:3},{id:502, rpt:4},{id:504, rpt:4},
                 {id:506, rpt:4},{id:509, rpt:3},{id:510, rpt:2},{id:701, rpt:6},{id:705, rpt:6},{id:707, rpt:2},{id:708, rpt:2},{id:709, rpt:2},{id:710, rpt:2},
                 {id:802, rpt:6},{id:807, rpt:2},{id:809, rpt:1},{id:810, rpt:1},{id:1005, rpt:2},{id:1010, rpt:2}
                ]},
// u    2-39  3-33  4-7  5-17  7-20  8-10  10-4  = 130  

        {ltr:'t', exerc:
                [{id:203, rpt:25},{id:204, rpt:10},{id:205, rpt:10},{id:206, rpt:7},{id:207, rpt:5},{id:209, rpt:2},{id:210, rpt:4},{id:302, rpt:3},
                 {id:303, rpt:4},{id:305, rpt:3},{id:306, rpt:1},{id:307, rpt:2},{id:309, rpt:1},{id:402, rpt:6},{id:404, rpt:5},{id:406, rpt:3},
                 {id:408, rpt:2},{id:409, rpt:2},{id:410, rpt:1},{id:502, rpt:4},{id:504, rpt:2},{id:602, rpt:6},{id:605, rpt:12},{id:606, rpt:6},
                 {id:607, rpt:2},{id:608, rpt:6},{id:609, rpt:4},{id:610, rpt:4},{id:701, rpt:6},{id:707, rpt:2},{id:709, rpt:1},{id:710, rpt:1},
                 {id:902, rpt:6},{id:904, rpt:6},{id:905, rpt:12},{id:907, rpt:2},{id:908, rpt:6},{id:909, rpt:4},{id:910, rpt:4},
                 {id:1003, rpt:2},{id:1007, rpt:1},{id:1009, rpt:1},{id:1010, rpt:2}
                ]},
// t    2-63  3-14  4-19  5-6  6-40  7-10  9-40  10-6  = 198

        {ltr:'r', exerc:
                [{id:203, rpt:25},{id:204, rpt:9},{id:205, rpt:7},{id:206, rpt:7},{id:207, rpt:7},{id:209, rpt:4},{id:210, rpt:4},{id:302, rpt:2},
                 {id:303, rpt:5},{id:305, rpt:2},{id:306, rpt:4},{id:307, rpt:2},{id:308, rpt:1},{id:309, rpt:2},{id:310, rpt:1},{id:402, rpt:2},
                 {id:404, rpt:2},{id:408, rpt:3},{id:409, rpt:2},{id:410, rpt:4},{id:504, rpt:2},{id:508, rpt:4},{id:509, rpt:4},{id:510, rpt:2},
                 {id:702, rpt:6},{id:703, rpt:12},{id:707, rpt:6},{id:709, rpt:3},{id:710, rpt:3},{id:905, rpt:6},{id:906, rpt:6},{id:908, rpt:4},
                 {id:909, rpt:2},{id:910, rpt:2},{id:1009, rpt:2},{id:1010, rpt:1}
                ]},
// r    2-63  3-19  4-13  5-12  7-30  9-20  10-3  = 297

        {ltr:'v', exerc:
                [{id:301, rpt:25},{id:302, rpt:6},{id:303, rpt:6},{id:306, rpt:1},{id:307, rpt:1},{id:308, rpt:3},{id:705, rpt:6},{id:708, rpt:2},{id:709, rpt:1},
                 {id:710, rpt:1},{id:901, rpt:6},{id:902, rpt:12},{id:907, rpt:6},{id:909, rpt:3},{id:910, rpt:3},{id:1005, rpt:2},{id:1007, rpt:1}
                ]},
// v    3-42  7-10   9-30  10-3  = 85

        {ltr:'b', exerc:
                [{id:301, rpt:25},{id:302, rpt:9},{id:303, rpt:6},{id:305, rpt:1},{id:306, rpt:5},{id:308, rpt:2},{id:309, rpt:2},{id:310, rpt:2},
                 {id:406, rpt:1},{id:408, rpt:2},{id:409, rpt:1},{id:702, rpt:12},{id:707, rpt:4},{id:709, rpt:2},{id:710, rpt:2},{id:805, rpt:6},{id:808, rpt:2},
                 {id:809, rpt:1},{id:810, rpt:1},{id:903, rpt:12},{id:904, rpt:6},{id:906, rpt:6},{id:907, rpt:4},{id:908, rpt:4},{id:909, rpt:4},{id:910, rpt:4},{id:1007, rpt:1}
                ]},
// b   3-52  4-4  7-20  8-10  9-40  10-1  = 127

        {ltr:'n', exerc:
                [{id:304, rpt:22},{id:305, rpt:8},{id:306, rpt:2},{id:307, rpt:6},{id:308, rpt:3},{id:309, rpt:5},{id:310, rpt:3},{id:402, rpt:1},{id:406, rpt:3},
                 {id:408, rpt:1},{id:409, rpt:3},{id:410, rpt:4},{id:502, rpt:2},{id:504, rpt:1},{id:506, rpt:1},{id:509, rpt:1},{id:601, rpt:11},{id:602, rpt:6},
                 {id:606, rpt:6},{id:607, rpt:6},{id:608, rpt:2},{id:609, rpt:4},{id:610, rpt:4},{id:701, rpt:6},{id:704, rpt:6},{id:707, rpt:2},{id:708, rpt:2},
                 {id:709, rpt:2},{id:710, rpt:2},{id:802, rpt:6},{id:803, rpt:6},{id:806, rpt:6},{id:807, rpt:4},{id:808, rpt:2},{id:809, rpt:3},{id:810, rpt:3},
                 {id:902, rpt:6},{id:903, rpt:6},{id:904, rpt:6},{id:906, rpt:6},{id:907, rpt:4},{id:908, rpt:4},{id:909, rpt:4},{id:910, rpt:4},
                 {id:1003, rpt:2},{id:1005, rpt:3},{id:1007, rpt:2},{id:1009, rpt:3},{id:1010, rpt:4}
                ]},
// n  3-49  4-12  5-5  6-39  7-20  8-30  9-40  10-14  = 209 

        {ltr:'m', exerc:
                [{id:304, rpt:23},{id:305, rpt:8},{id:306, rpt:1},{id:307, rpt:3},{id:308, rpt:4},{id:309, rpt:6},{id:310, rpt:6},{id:402, rpt:1},{id:406, rpt:1},{id:410, rpt:4},
                 {id:502, rpt:1},{id:504, rpt:3},{id:506, rpt:1},{id:509, rpt:3},{id:601, rpt:6},{id:607, rpt:2},{id:609, rpt:1},{id:610, rpt:1},
                 {id:806, rpt:6},{id:808, rpt:2},{id:809, rpt:1},{id:810, rpt:1},{id:1003, rpt:1},{id:1007, rpt:1},{id:1009, rpt:2},{id:1010, rpt:1}
                ]},
// m  3-51  4-6  5-8  6-10  8-10  10-5  = 90

        {ltr:'i', exerc:
                [{id:401, rpt:25},{id:402, rpt:8},{id:404, rpt:3},{id:406, rpt:3},{id:408, rpt:2},{id:409, rpt:2},{id:410, rpt:3},{id:502, rpt:2},{id:506, rpt:2},
                 {id:508, rpt:2},{id:509, rpt:4},{id:510, rpt:2},{id:602, rpt:6},{id:603, rpt:12},{id:604, rpt:6},{id:607, rpt:6},{id:608, rpt:2},{id:609, rpt:4},{id:610, rpt:4},
                 {id:701, rpt:6},{id:703, rpt:6},{id:706, rpt:6},{id:707, rpt:4},{id:708, rpt:2},{id:709, rpt:3},{id:710, rpt:3},{id:801, rpt:6},{id:803, rpt:6},
                 {id:807, rpt:4},{id:809, rpt:2},{id:810, rpt:2},{id:901, rpt:12},{id:904, rpt:6},{id:905, rpt:12},{id:907, rpt:4},{id:908, rpt:6},{id:909, rpt:5},{id:910, rpt:5},
                 {id:1003, rpt:4},{id:1005, rpt:3},{id:1007, rpt:2},{id:1009, rpt:1},{id:1010, rpt:3}
                ]},
 // i    4-46  5-15  6-40  7-30  8-20  9-50  10-13  = 214

        {ltr:'o', exerc:
                [{id:401, rpt:25},{id:402, rpt:9},{id:404, rpt:3},{id:406, rpt:3},{id:408, rpt:1},{id:409, rpt:7},{id:410, rpt:6},{id:502, rpt:4},{id:504, rpt:5},{id:509, rpt:3},
                 {id:602, rpt:6},{id:604, rpt:6},{id:607, rpt:2},{id:608, rpt:2},{id:609, rpt:2},{id:610, rpt:2},{id:701, rpt:6},{id:707, rpt:2},{id:709, rpt:1},{id:710, rpt:1},
                 {id:802, rpt:6},{id:804, rpt:6},{id:805, rpt:6},{id:807, rpt:2},{id:808, rpt:4},{id:809, rpt:3},{id:810, rpt:3},{id:1003, rpt:2},{id:1005, rpt:2},{id:1007, rpt:2},{id:1009, rpt:7},{id:1010, rpt:3}
                ]},
// o    4-54  5-12  6-20  7-10  8-30  10-16  = 142

        {ltr:'w', exerc:
                [{id:403, rpt:24},{id:404, rpt:6},{id:406, rpt:5},{id:408, rpt:1},{id:409, rpt:2},{id:410, rpt:2},{id:506, rpt:1},{id:508, rpt:2},{id:1009, rpt:2},{id:1010, rpt:2}]},
// w    4-40  5-3  10-4  = 47

        {ltr:'e', exerc:
                [{id:403, rpt:26},{id:404, rpt:12},{id:406, rpt:7},{id:408, rpt:1},{id:409, rpt:8},{id:410, rpt:4},{id:502, rpt:4},{id:504, rpt:2},{id:506, rpt:1},{id:509, rpt:3},{id:510, rpt:1},
                 {id:601, rpt:11},{id:602, rpt:6},{id:604, rpt:6},{id:606, rpt:6},{id:607, rpt:6},{id:608, rpt:4},{id:609, rpt:5},{id:610, rpt:5},
                 {id:702, rpt:12},{id:703, rpt:12},{id:704, rpt:12},{id:705, rpt:12},{id:706, rpt:12},{id:707, rpt:8},{id:708, rpt:12},{id:709, rpt:10},{id:710, rpt:10},
                 {id:801, rpt:6},{id:802, rpt:6},{id:803, rpt:6},{id:805, rpt:12},{id:806, rpt:6},{id:807, rpt:6},{id:808, rpt:6},{id:809, rpt:6},{id:810, rpt:6},
                 {id:901, rpt:12},{id:902, rpt:12},{id:906, rpt:6},{id:907, rpt:8},{id:908, rpt:2},{id:909, rpt:5},{id:910, rpt:5},{id:1003, rpt:4},{id:1005, rpt:4},{id:1007, rpt:3},{id:1009, rpt:3},{id:1010, rpt:7}
                ]},
// e   4-58  5-11  6-49  7-100  8-60  9-50  10-21  = 349

        {ltr:',', exerc:
                [{id:405, rpt:25},{id:406, rpt:7},{id:408, rpt:3},{id:409, rpt:2},{id:410, rpt:1}]},
// ,    4-38    = 38

        {ltr:'.', exerc:
                [{id:405, rpt:25},{id:406, rpt:5},{id:408, rpt:2},{id:409, rpt:3},{id:410, rpt:1}]},
 // .    4-36   = 36

        {ltr:'x', exerc:
                [{id:407, rpt:25},{id:408, rpt:3},{id:409, rpt:2},{id:410, rpt:4}]},
// x     4-34  7-10  10-1  = 45

        {ltr:'c', exerc:
                [{id:407, rpt:25},{id:408, rpt:10},{id:409, rpt:2},{id:410, rpt:1},{id:502, rpt:1},{id:509, rpt:3},
                 {id:602, rpt:6},{id:603, rpt:6},{id:607, rpt:4},{id:609, rpt:2},{id:610, rpt:2},{id:802, rpt:6},{id:803, rpt:6},{id:804, rpt:12},
                 {id:806, rpt:6},{id:807, rpt:4},{id:808, rpt:6},{id:809, rpt:5},{id:810, rpt:5},{id:903, rpt:6},{id:907, rpt:2},{id:909, rpt:1},
                 {id:910, rpt:1},{id:1003, rpt:4},{id:1005, rpt:2},{id:1007, rpt:3},{id:1009, rpt:2},{id:1010, rpt:2}
                ]},
// c     4-38  5-4  6-20  8-50  9-10  10-13  = 135

        {ltr:'z', exerc:
                [{id:501, rpt:24},{id:502, rpt:7},{id:504, rpt:2},{id:505, rpt:8},{id:506, rpt:5},{id:507, rpt:2},{id:508, rpt:6},{id:510, rpt:9},
                {id:801, rpt:6},{id:807, rpt:2},{id:809, rpt:1},{id:810, rpt:1},{id:1005, rpt:1},{id:1009, rpt:1}]},
// z     5-63  8-10  10-2  = 75

        {ltr:'q', exerc:
                [{id:501, rpt:26},{id:502, rpt:4},{id:504, rpt:1},{id:505, rpt:8},{id:506, rpt:5},{id:507, rpt:2},{id:508, rpt:7},{id:510, rpt:7},
                {id:802, rpt:6},{id:807, rpt:2},{id:809, rpt:1},{id:810, rpt:1},{id:1005, rpt:1},{id:1010, rpt:1}
                ]},
// q     5-60  8-10  10-2  = 72

        {ltr:'p', exerc:
                [{id:503, rpt:25},{id:504, rpt:13},{id:505, rpt:6},{id:506, rpt:4},{id:507, rpt:1},{id:508, rpt:5},{id:509, rpt:6},{id:510, rpt:9},
                 {id:605, rpt:6},{id:606, rpt:6},{id:608, rpt:4},{id:609, rpt:2},{id:610, rpt:2},{id:701, rpt:12},{id:707, rpt:4},{id:709, rpt:2},{id:710, rpt:2},
                 {id:804, rpt:6},{id:806, rpt:6},{id:808, rpt:4},{id:809, rpt:2},{id:810, rpt:2},{id:1003, rpt:1},{id:1007, rpt:3}
                ]},
// p     5-69  6-20  7-20  8-20  10-4  = 133

        {ltr:'[', exerc:
                [{id:503, rpt:25},{id:504, rpt:4},{id:505, rpt:6},{id:506, rpt:11},{id:507, rpt:4},{id:508, rpt:8},{id:509, rpt:2},{id:510, rpt:5},{id:1010, rpt:1}]},
// [    5-65  10-1  = 66

        {ltr:']', exerc:
                [{id:505, rpt:21},{id:506, rpt:13},{id:507, rpt:11},{id:508, rpt:7},{id:509, rpt:2},{id:510, rpt:7},{id:1010, rpt:1}]},
// ]   5-61  10-1  = 62

        {ltr:'/', exerc:
                [{id:507, rpt:9},{id:508, rpt:4},{id:509, rpt:3},{id:510, rpt:5}]},
// /    5-21    = 21

        {ltr:'\'', exerc:
                [{id:507, rpt:20},{id:508, rpt:5},{id:509, rpt:2},{id:510, rpt:8},{id:1007, rpt:2}]},
// \    5-35  10-2  = 37 
    
 ]











 export const lettersRpt = [
        
            {ltr:'j', exerc:
                     [{id:'0101', rpt:25}, {id:'0103', rpt:12}, {id:'0104', rpt:11},{id:'0106', rpt:5},{id:'0110', rpt:4},
                      {id:'0204', rpt:1},{id:'0205', rpt:1},{id:'0207', rpt:1},{id:'0209', rpt:1},{id:'0210', rpt:1},
                      {id:'0305', rpt: 1},{id:'0308', rpt: 2},{id:'0309', rpt: 1},{id:'0310', rpt: 2},{id:'0409', rpt: 1},
                      {id:'0601', rpt: 5},{id:'0607', rpt: 2},{id:'0609', rpt: 1},{id:'0610', rpt: 1},{id:'0807', rpt: 2},
                      {id:'0809', rpt: 1},{id:'0810', rpt: 1},{id:'1003', rpt: 1},{id:'1009', rpt: 2}]},
     // j  1-57 2-5 3-6 4-1 6-9 8-10 10-3 = 91  
    
            {ltr:'k', exerc:
                     [{id:'0101', rpt:25}, {id:'0103', rpt:13}, {id:'0104', rpt:17}, {id:'0106', rpt:7},{id:'0108', rpt:2},{id:'0109', rpt:2},{id:'0110', rpt:4},
                      {id:'0205', rpt:2},{id:'0206', rpt:3},{id:'0207', rpt:1},{id:'0209', rpt:1},{id:'0210', rpt:1},{id:306, rpt: 4},
                      {id:'0307', rpt: 3},{id:'0308', rpt: 4},{id:'0309', rpt: 2},{id:'0310', rpt: 2},{id:'0409', rpt: 3},{id:'0502', rpt: 2},
                      {id:'0703', rpt: 12},{id:'0704', rpt: 6},{id:'0707', rpt: 4},{id:'0708', rpt: 2},{id:'0709', rpt: 3},{id:'0710', rpt: 3},                
                      {id: '0802', rpt: 12},{id: '0803', rpt: 12},{id: '0804', rpt: 6},{id: '0805', rpt: 6},{id: '0807', rpt: 8},{id: '0808', rpt: 4},{id: '0809', rpt: 6},{id: '0810', rpt: 6},
                      {id: '0903', rpt: 6},{id: '0907', rpt: 2},{id: '0909', rpt: 1},{id: '0910', rpt: 1},{id: '1003', rpt: 1},{id: '1005', rpt: 2},{id: '1007', rpt: 2},{id: '1009', rpt: 1}]},
     // k   1-70  2-8  3-15  4-3  5-2  7-30  8-60  9-10  10-6  = 204 
    
            {ltr:'d', exerc:
                     [{id:'0102', rpt:25}, {id:'0103', rpt:12}, {id:'0104', rpt:12},{id:'0106', rpt:4},{id:'0108', rpt:9},{id:'0109', rpt:6},{id:'0110', rpt:5},
                      {id:'0201', rpt:3},{id:'0202', rpt:2},{id:'0204', rpt:1},{id:'0205', rpt:5},{id:'0206', rpt:2},{id:'0207', rpt:3},{id:'0209', rpt:4},{id:'0210', rpt:2},
                      {id: '0302', rpt: 1},{id: '0305', rpt: 1},{id: '0306', rpt: 1},{id: '0308', rpt: 3},{id: '0402', rpt: 4},{id: '0404', rpt: 1},{id: '0406', rpt: 1},{id: '0408', rpt: 1},{id: '0409', rpt: 3},{id: '0410', rpt: 1},
                      {id: '0603', rpt: 12}, {id: '0643', rpt: 6}, {id: '0606', rpt: 6}, {id: '0607', rpt: 4}, {id: '0608', rpt: 4}, {id: '0609', rpt: 4}, {id: '0610', rpt: 4},
                      {id: '0701', rpt: 6},{id: '0702', rpt: 6},{id: '0707', rpt: 4},{id: '0709', rpt: 2},{id: '0710', rpt: 2},
                      {id: '0906', rpt: 6},{id: '0908', rpt: 2},{id: '0909', rpt: 1},{id: '0910', rpt: 1},{id: '1003', rpt: 3},{id: '1007', rpt: 1},{id: '1009', rpt: 2},{id: '1010', rpt: 2}
                    ]},
    // d   1-73  2-22  3-6  4-11  6-40  7-20  9-10  10-8  = 190  
    
            {ltr:'f', exerc:
                    [{id:'0102', rpt:25}, {id:'0103', rpt:13}, {id:'0104', rpt:10}, {id:'0106', rpt:16},{id:'0108', rpt:3},{id:'0109', rpt:4},{id:'0110', rpt:5},
                    {id:'0201', rpt:2},{id:'0202', rpt:4},{id:'0204', rpt:1},{id:'0205', rpt:2},{id:'0206', rpt:3},{id:'0207', rpt:3},{id:'0209', rpt:3},{id:'0210', rpt:3},
                    {id: '0305', rpt: 1},{id: '0306', rpt: 3},{id: '0308', rpt: 1},{id: '0402', rpt: 2},{id: '0404', rpt: 1},{id: '0705', rpt: 6},{id: '0706', rpt: 6},{id: '0708', rpt: 4},{id: '0709', rpt: 2},{id: '0710', rpt: 2},
                    {id: '0801', rpt: 6},{id: '0803', rpt: 6},{id: '0807', rpt: 4},{id: '0809', rpt: 2},{id: '0810', rpt: 2},{id: '0901', rpt: 6},{id: '0905', rpt: 6},{id: '0907', rpt: 2},
                    {id: '0908', rpt: 2},{id: '0909', rpt: 2},{id: '0910', rpt: 2},{id: '1005', rpt: 1},{id: '1007', rpt: 1},{id: '1010', rpt: 1}
                    ]},
    // f   1-76  2-21  3-5  4-3  7-20  8-20  9-20  10-3  = 148   
    
            {ltr:'l', exerc:
                    [{id:'0105', rpt:25}, {id:'0106', rpt:7},{id:'0108', rpt:8},{id:'0109', rpt:11},{id:'0110', rpt:11},
                     {id:'0201', rpt:3},{id:'0202', rpt:7},{id:'0205', rpt:1},{id:'0206', rpt:3},{id:'0207', rpt:2},{id:'0209', rpt:4},{id:'0210', rpt:5},
                     {id: '0303', rpt: 3},{id: '0306', rpt: 3},{id: '0307', rpt: 4},{id: '0308', rpt: 1},{id: '0309', rpt: 2},{id: '0310', rpt: 1},
                     {id: '0402', rpt: 4},{id: '0404', rpt: 3},{id: '0406', rpt: 2},{id: '0410', rpt: 3},{id: '0502', rpt: 1},{id: '0504', rpt: 4},{id: '0506', rpt: 2},{id: '0509', rpt: 5},
                     {id: '0604', rpt: 12},{id: '0605', rpt: 6},{id: '0606', rpt: 6},{id: '0608', rpt: 8},{id: '0609', rpt: 4},{id: '0610', rpt: 4},
                     {id: '0704', rpt: 12},{id: '0705', rpt: 12},{id: '0706', rpt: 12},{id: '0708', rpt: 12},{id: '0709', rpt: 6},{id: '0710', rpt: 6},
                     {id: '0801', rpt: 12},{id: '0807', rpt: 4},{id: '0809', rpt: 2},{id: '0810', rpt: 2},{id: '0901', rpt: 12},{id: '0902', rpt: 6},{id: '0907', rpt: 6},{id: '0909', rpt: 3},{id: '0910', rpt: 3},
                     {id: '1003', rpt: 2},{id: '1005', rpt: 5},{id: '1009', rpt: 2},{id: '1010', rpt: 3}
                    ]},
    // l   1-62  2-25  3-14  4-12  5-12  6-40  7-60  8-20  9-30  10-12  =287   
    
            {ltr:';', exerc:
                    [{id:'0105', rpt:25}, {id:'0106', rpt:10},{id:'0108', rpt:6},{id:'0109', rpt:5},{id:'0110', rpt:5},{id:'0201', rpt:4},{id:'0202', rpt:3},{id:'0204', rpt:2},
                     {id:'0205', rpt:2},{id:'0206', rpt:4},{id:'0207', rpt:1},{id:'0209', rpt:1},{id: '0309', rpt: 3},{id: '0310', rpt: 2},{id: '0410', rpt: 1},{id: '0509', rpt: 1},                 
                    ]},
    // ;   1-51  2-17  3-5  4-1  5-1  = 75  
    
            {ltr:'a', exerc:
                    [{id:'0107', rpt:25},{id:'0108', rpt:13},{id:'0109', rpt:13},{id:'0110', rpt:8},{id:'0201', rpt:13},{id:'0202', rpt:12},{id:'0204', rpt:15},{id:'0205', rpt:13},
                     {id:'0206', rpt:12},{id:'0207', rpt:10},{id:'0209', rpt:6},{id:'0210', rpt:3},{id:'0302', rpt:12},{id:'0303', rpt:9},{id:'0305', rpt:8},{id:'0306', rpt:7},{id:'0307', rpt:7},{id:'0308', rpt:3},
                     {id:'0309', rpt:8},{id:'0310', rpt:5},{id:'0402', rpt:2},{id:'0404', rpt:2},{id:'0408', rpt:8},{id:'0409', rpt:3},{id:'0410', rpt:3},
                     {id:'0502', rpt:3},{id:'0504', rpt:4},{id:'0506', rpt:1},{id:'0509', rpt:4},{id:'0510', rpt:1},{id:'0601', rpt:11},{id:'0604', rpt:6},{id:'0605', rpt:12},{id:'0606', rpt:6},{id:'0607', rpt:4},
                     {id:'0608', rpt:8},{id:'0609', rpt:6},{id:'0610', rpt:6},{id:'0702', rpt:6},{id:'0704', rpt:6},{id:'0705', rpt:6},{id:'0706', rpt:6},{id:'0707', rpt:2},{id:'0708', rpt:6},{id:'0709', rpt:4},{id:'0710', rpt:4},
                     {id:'0801', rpt:6},{id:'0802', rpt:6},{id:'0803', rpt:6},{id:'0804', rpt:6},{id:'0806', rpt:6},{id:'0807', rpt:6},{id:'0808', rpt:4},{id:'0809', rpt:5},{id:'0810', rpt:5},
                     {id:'0902', rpt:6},{id:'0903', rpt:12},{id:'0904', rpt:6},{id:'0906', rpt:6},{id:'0907', rpt:6},{id:'0908', rpt:4},{id:'0909', rpt:5},{id:'0910', rpt:5},
                     {id:'1003', rpt:5},{id:'1005', rpt:3},{id:'1007', rpt:3},{id:'1009', rpt:1},{id:'1010', rpt:1}
                    ]},
     // a  1-59  2-84  3-59  4-18  5-13  6-59  7-40  8-50  9-50  10-13  = 445 
    
            {ltr:'s', exerc:
                    [{id:'0107', rpt:25},{id:'0108', rpt:6},{id:'0109', rpt:7},{id:'0110', rpt:1},{id:'0201', rpt:5},{id:'0202', rpt:6},{id:'0204', rpt:1},{id:'0205', rpt:5},{id:'0206', rpt:6},{id:'0207', rpt:4},
                     {id:'0209', rpt:2},{id:'0210', rpt:8},{id:'0302', rpt:2},{id:'0303', rpt:2},{id:'0305', rpt:1},{id:'0306', rpt:5},{id:'0307', rpt:3},{id:'0308', rpt:2},{id:'0309', rpt:2},{id:'0310', rpt:4},
                     {id:'0402', rpt:1},{id:'0404', rpt:3},{id:'0406', rpt:2},{id:'0408', rpt:1},{id:'0409', rpt:2},{id:'0410', rpt:2},{id:'0502', rpt:1},{id:'0504', rpt:1},{id:'0506', rpt:2},{id:'0508', rpt:1},{id:'0509', rpt:5},
                     {id:'0601', rpt:5},{id:'0603', rpt:12},{id:'0604', rpt:6},{id:'0605', rpt:6},{id:'0606', rpt:6},{id:'0607', rpt:6},{id:'0608', rpt:6},{id:'0609', rpt:6},{id:'0610', rpt:6},
                     {id:'0701', rpt:6},{id:'0702', rpt:6},{id:'0703', rpt:12},{id:'0704', rpt:6},{id:'0706', rpt:6},{id:'0707', rpt:8},{id:'0708', rpt:4},{id:'0709', rpt:6},{id:'0710', rpt:6},
                     {id:'0804', rpt:6},{id:'0805', rpt:6},{id:'0806', rpt:12},{id:'0808', rpt:8},{id:'0809', rpt:4},{id:'0810', rpt:4},{id:'0903', rpt:6},{id:'0904', rpt:12},{id:'0907', rpt:2},
                     {id:'0908', rpt:4},{id:'0909', rpt:3},{id:'0910', rpt:3},{id:'1003', rpt:2},{id:'1007', rpt:5},{id:'1009', rpt:2},{id:'1010', rpt:1}
                    ]},
    // s   1-39  2-37  3-21  4-11  5-10  6-59  7-60  8-40  9-30  10-10  = 317  
    
            {ltr:'g', exerc:
                    [{id:'0201', rpt:8},{id:'0202', rpt:4},{id:'0204', rpt:4},{id:'0205', rpt:1},{id:'0206', rpt:2},{id:'0207', rpt:2},{id:'0209', rpt:2},{id:'0302', rpt:4},{id:'0303', rpt:2},{id:'0305', rpt:2},
                     {id:'0308', rpt:2},{id:'0310', rpt:1},{id:'0402', rpt:1},{id:'0404', rpt:1},{id:'0410', rpt:2},{id: '0502', rpt: 1},{id:'0904', rpt:6},{id:'0905', rpt:12},{id:'0908', rpt:6},{id:'0909', rpt:3},{id:'0910', rpt:3}
                   ]},
    // g   2-23  3-11  4-4  5-1  9-30  = 69 
    
            {ltr:'h', exerc:
                    [{id:'0201', rpt:6},{id:'0202', rpt:8},{id:'0204', rpt:2},{id:'0205', rpt:3},{id:'0206', rpt:4},{id:'0207', rpt:2},{id:'0209', rpt:1},{id:'0210', rpt:2},
                     {id:'0302', rpt:1},{id:'0305', rpt:1},{id:'0306', rpt:3},{id:'0307', rpt:1},{id:'0308', rpt:2},{id:'0309', rpt:3},{id:'0402', rpt:3},{id:'0404', rpt:2},
                     {id:'0406', rpt:1},{id:'0410', rpt:2},{id:'0509', rpt:1},{id:'0602', rpt:12},{id:'0603', rpt:6},{id:'0605', rpt:6},{id:'0607', rpt:6},{id:'0608', rpt:2},
                     {id:'0609', rpt:4},{id:'0610', rpt:4},{id:'0804', rpt:6},{id:'0808', rpt:2},{id:'0809', rpt:1},{id:'0810', rpt:1},{id:'0906', rpt:12},{id:'0908', rpt:4},
                     {id:'0909', rpt:2},{id:'0910', rpt:2},{id:'1003', rpt:4},{id:'1007', rpt:1},{id:'1010', rpt:1}
                    ]},
    // h   2-28  3-11  4-8  5-1  6-40  8-10  9-20  10-6  = 124  
    
            {ltr:'y', exerc:
                    [{id:'0208', rpt:25},{id:'0209', rpt:10},{id:'0210', rpt:2},{id:'0302', rpt:1},{id:'0303', rpt:6},{id:'0305', rpt:1},{id:'0306', rpt:3},{id:'0307', rpt:5},
                     {id:'0308', rpt:1},{id:'0310', rpt:5},{id:'0404', rpt:1},{id:'0406', rpt:3},{id:'0408', rpt:2},{id:'0409', rpt:2},{id:'0502', rpt:1},{id:'0702', rpt:6},
                     {id:'0707', rpt:2},{id:'0709', rpt:1},{id:'0710', rpt:1},{id:'0801', rpt:6},{id:'0805', rpt:12},{id:'0807', rpt:2},{id:'0808', rpt:4},{id:'0809', rpt:3},{id:'0810', rpt:3},
                     {id:'1005', rpt:2},{id:'1007', rpt:1},{id:'1009', rpt:1}
                    ]},
     // y    2-37  3-22  4-8  5-1  7-10  8-30 10-4  =  112
    
            {ltr:'u', exerc:
                    [{id:'0208', rpt:25},{id:'0209', rpt:4},{id:'0210', rpt:10},{id:'0302', rpt:2},{id:'0303', rpt:6},{id:'0305', rpt:7},{id:'0306', rpt:5},{id:'0307', rpt:3},{id:'0308', rpt:3},
                     {id:'0309', rpt:2},{id:'0310', rpt:5},{id:'0402', rpt:1},{id:'0406', rpt:1},{id:'0408', rpt:1},{id:'0409', rpt:1},{id:'0410', rpt:3},{id:'0502', rpt:4},{id:'0504', rpt:4},
                     {id:'0506', rpt:4},{id:'0509', rpt:3},{id:'0510', rpt:2},{id:'0701', rpt:6},{id:'0705', rpt:6},{id:'0707', rpt:2},{id:'0708', rpt:2},{id:'0709', rpt:2},{id:'0710', rpt:2},
                     {id:'0802', rpt:6},{id:'0807', rpt:2},{id:'0809', rpt:1},{id:'0810', rpt:1},{id:'1005', rpt:2},{id:'1010', rpt:2}
                    ]},
    // u    2-39  3-33  4-7  5-17  7-20  8-10  10-4  = 130  
    
            {ltr:'t', exerc:
                    [{id:'0203', rpt:25},{id:'0204', rpt:10},{id:'0205', rpt:10},{id:'0206', rpt:7},{id:'0207', rpt:5},{id:'0209', rpt:2},{id:'0210', rpt:4},{id:'0302', rpt:3},
                     {id:'0303', rpt:4},{id:'0305', rpt:3},{id:'0306', rpt:1},{id:'0307', rpt:2},{id:'0309', rpt:1},{id:'0402', rpt:6},{id:'0404', rpt:5},{id:'0406', rpt:3},
                     {id:'0408', rpt:2},{id:'0409', rpt:2},{id:'0410', rpt:1},{id:'0502', rpt:4},{id:'0504', rpt:2},{id:'0602', rpt:6},{id:'0605', rpt:12},{id:'0606', rpt:6},
                     {id:'0607', rpt:2},{id:'0608', rpt:6},{id:'0609', rpt:4},{id:'0610', rpt:4},{id:'0701', rpt:6},{id:'0707', rpt:2},{id:'0709', rpt:1},{id:'0710', rpt:1},
                     {id:'0902', rpt:6},{id:'0904', rpt:6},{id:'0905', rpt:12},{id:'0907', rpt:2},{id:'0908', rpt:6},{id:'0909', rpt:4},{id:'0910', rpt:4},
                     {id:'1003', rpt:2},{id:'1007', rpt:1},{id:'1009', rpt:1},{id:'1010', rpt:2}
                    ]},
    // t    2-63  3-14  4-19  5-6  6-40  7-10  9-40  10-6  = 198
    
            {ltr:'r', exerc:
                    [{id:'0203', rpt:25},{id:'0204', rpt:9},{id:'0205', rpt:7},{id:'0206', rpt:7},{id:'0207', rpt:7},{id:'0209', rpt:4},{id:'0210', rpt:4},{id:'0302', rpt:2},
                     {id:'0303', rpt:5},{id:'0305', rpt:2},{id:'0306', rpt:4},{id:'0307', rpt:2},{id:'0308', rpt:1},{id:'0309', rpt:2},{id:'0310', rpt:1},{id:'0402', rpt:2},
                     {id:'0404', rpt:2},{id:'0408', rpt:3},{id:'0409', rpt:2},{id:'0410', rpt:4},{id:'0504', rpt:2},{id:'0508', rpt:4},{id:'0509', rpt:4},{id:'0510', rpt:2},
                     {id:'0702', rpt:6},{id:'0703', rpt:12},{id:'0707', rpt:6},{id:'0709', rpt:3},{id:'0710', rpt:3},{id:'0905', rpt:6},{id:'0906', rpt:6},{id:'0908', rpt:4},
                     {id:'0909', rpt:2},{id:'0910', rpt:2},{id:'1009', rpt:2},{id:'1010', rpt:1}
                    ]},
    // r    2-63  3-19  4-13  5-12  7-30  9-20  10-3  = 297
    
            {ltr:'v', exerc:
                    [{id:'0301', rpt:25},{id:'0302', rpt:6},{id:'0303', rpt:6},{id:'0306', rpt:1},{id:'0307', rpt:1},{id:'0308', rpt:3},{id:'0705', rpt:6},{id:'0708', rpt:2},{id:'0709', rpt:1},
                     {id:'0710', rpt:1},{id:'0901', rpt:6},{id:'0902', rpt:12},{id:'0907', rpt:6},{id:'0909', rpt:3},{id:'0910', rpt:3},{id:'1005', rpt:2},{id:'1007', rpt:1}
                    ]},
    // v    3-42  7-10   9-30  10-3  = 85
    
            {ltr:'b', exerc:
                    [{id:'0301', rpt:25},{id:'0302', rpt:9},{id:'0303', rpt:6},{id:'0305', rpt:1},{id:'0306', rpt:5},{id:'0308', rpt:2},{id:'0309', rpt:2},{id:'0310', rpt:2},
                     {id:'0406', rpt:1},{id:'0408', rpt:2},{id:'0409', rpt:1},{id:'0702', rpt:12},{id:'0707', rpt:4},{id:'0709', rpt:2},{id:'0710', rpt:2},{id:'0805', rpt:6},{id:'0808', rpt:2},
                     {id:'0809', rpt:1},{id:'0810', rpt:1},{id:'0903', rpt:12},{id:'0904', rpt:6},{id:'0906', rpt:6},{id:'0907', rpt:4},{id:'0908', rpt:4},{id:'0909', rpt:4},{id:'0910', rpt:4},{id:'1007', rpt:1}
                    ]},
    // b   3-52  4-4  7-20  8-10  9-40  10-1  = 127
    
            {ltr:'n', exerc:
                    [{id:'0304', rpt:22},{id:'0305', rpt:8},{id:'0306', rpt:2},{id:'0307', rpt:6},{id:'0308', rpt:3},{id:'0309', rpt:5},{id:'0310', rpt:3},{id:'0402', rpt:1},{id:'0406', rpt:3},
                     {id:'0408', rpt:1},{id:'0409', rpt:3},{id:'0410', rpt:4},{id:'0502', rpt:2},{id:'0504', rpt:1},{id:'0506', rpt:1},{id:'0509', rpt:1},{id:'0601', rpt:11},{id:'0602', rpt:6},
                     {id:'0606', rpt:6},{id:'0607', rpt:6},{id:'0608', rpt:2},{id:'0609', rpt:4},{id:'0610', rpt:4},{id:'0701', rpt:6},{id:'0704', rpt:6},{id:'0707', rpt:2},{id:'0708', rpt:2},
                     {id:'0709', rpt:2},{id:'0710', rpt:2},{id:'0802', rpt:6},{id:'0803', rpt:6},{id:'0806', rpt:6},{id:'0807', rpt:4},{id:'0808', rpt:2},{id:'0809', rpt:3},{id:'0810', rpt:3},
                     {id:'0902', rpt:6},{id:'0903', rpt:6},{id:'0904', rpt:6},{id:'0906', rpt:6},{id:'0907', rpt:4},{id:'0908', rpt:4},{id:'0909', rpt:4},{id:'0910', rpt:4},
                     {id:'1003', rpt:2},{id:'1005', rpt:3},{id:'1007', rpt:2},{id:'1009', rpt:3},{id:'1010', rpt:4}
                    ]},
    // n  3-49  4-12  5-5  6-39  7-20  8-30  9-40  10-14  = 209 
    
            {ltr:'m', exerc:
                    [{id:'0304', rpt:23},{id:'0305', rpt:8},{id:'0306', rpt:1},{id:'0307', rpt:3},{id:'0308', rpt:4},{id:'0309', rpt:6},{id:'0310', rpt:6},{id:'0402', rpt:1},{id:'0406', rpt:1},{id:'0410', rpt:4},
                     {id:'0502', rpt:1},{id:'0504', rpt:3},{id:'0506', rpt:1},{id:'0509', rpt:3},{id:'0601', rpt:6},{id:'0607', rpt:2},{id:'0609', rpt:1},{id:'0610', rpt:1},
                     {id:'0806', rpt:6},{id:'0808', rpt:2},{id:'0809', rpt:1},{id:'0810', rpt:1},{id:'1003', rpt:1},{id:'1007', rpt:1},{id:'1009', rpt:2},{id:'1010', rpt:1}
                    ]},
    // m  3-51  4-6  5-8  6-10  8-10  10-5  = 90
    
            {ltr:'i', exerc:
                    [{id:'0401', rpt:25},{id:'0402', rpt:8},{id:'0404', rpt:3},{id:'0406', rpt:3},{id:'0408', rpt:2},{id:'0409', rpt:2},{id:'0410', rpt:3},{id:'0502', rpt:2},{id:'0506', rpt:2},
                     {id:'0508', rpt:2},{id:'0509', rpt:4},{id:'0510', rpt:2},{id:'0602', rpt:6},{id:'0603', rpt:12},{id:'0604', rpt:6},{id:'0607', rpt:6},{id:'0608', rpt:2},{id:'0609', rpt:4},{id:'0610', rpt:4},
                     {id:'0701', rpt:6},{id:'0703', rpt:6},{id:'0706', rpt:6},{id:'0707', rpt:4},{id:'0708', rpt:2},{id:'0709', rpt:3},{id:'0710', rpt:3},{id:'0801', rpt:6},{id:'0803', rpt:6},
                     {id:'0807', rpt:4},{id:'0809', rpt:2},{id:'0810', rpt:2},{id:'0901', rpt:12},{id:'0904', rpt:6},{id:'0905', rpt:12},{id:'0907', rpt:4},{id:'0908', rpt:6},{id:'0909', rpt:5},{id:'0910', rpt:5},
                     {id:'1003', rpt:4},{id:'1005', rpt:3},{id:'1007', rpt:2},{id:'1009', rpt:1},{id:'1010', rpt:3}
                    ]},
     // i    4-46  5-15  6-40  7-30  8-20  9-50  10-13  = 214
    
            {ltr:'o', exerc:
                    [{id:'0401', rpt:25},{id:'0402', rpt:9},{id:'0404', rpt:3},{id:'0406', rpt:3},{id:'0408', rpt:1},{id:'0409', rpt:7},{id:'0410', rpt:6},{id:'0502', rpt:4},{id:'0504', rpt:5},{id:'0509', rpt:3},
                     {id:'0602', rpt:6},{id:'0604', rpt:6},{id:'0607', rpt:2},{id:'0608', rpt:2},{id:'0609', rpt:2},{id:'0610', rpt:2},{id:'0701', rpt:6},{id:'0707', rpt:2},{id:'0709', rpt:1},{id:'0710', rpt:1},
                     {id:'0802', rpt:6},{id:'0804', rpt:6},{id:'0805', rpt:6},{id:'0807', rpt:2},{id:'0808', rpt:4},{id:'0809', rpt:3},{id:'0810', rpt:3},{id:'1003', rpt:2},{id:'1005', rpt:2},{id:'1007', rpt:2},{id:'1009', rpt:7},{id:'1010', rpt:3}
                    ]},
    // o    4-54  5-12  6-20  7-10  8-30  10-16  = 142
    
            {ltr:'w', exerc:
                    [{id:'0403', rpt:24},{id:'0404', rpt:6},{id:'0406', rpt:5},{id:'0408', rpt:1},{id:'0409', rpt:2},{id:'0410', rpt:2},{id:'0506', rpt:1},{id:'0508', rpt:2},{id:'1009', rpt:2},{id:'1010', rpt:2}]},
    // w    4-40  5-3  10-4  = 47
    
            {ltr:'e', exerc:
                    [{id:'0403', rpt:26},{id:'0404', rpt:12},{id:'0406', rpt:7},{id:'0408', rpt:1},{id:'0409', rpt:8},{id:'0410', rpt:4},{id:'0502', rpt:4},{id:'0504', rpt:2},{id:'0506', rpt:1},{id:'0509', rpt:3},{id:'0510', rpt:1},
                     {id:'0601', rpt:11},{id:'0602', rpt:6},{id:'0604', rpt:6},{id:'0606', rpt:6},{id:'0607', rpt:6},{id:'0608', rpt:4},{id:'0609', rpt:5},{id:'0610', rpt:5},
                     {id:'0702', rpt:12},{id:'0703', rpt:12},{id:'0704', rpt:12},{id:'0705', rpt:12},{id:'0706', rpt:12},{id:'0707', rpt:8},{id:'0708', rpt:12},{id:'0709', rpt:10},{id:'0710', rpt:10},
                     {id:'0801', rpt:6},{id:'0802', rpt:6},{id:'0803', rpt:6},{id:'0805', rpt:12},{id:'0806', rpt:6},{id:'0807', rpt:6},{id:'0808', rpt:6},{id:'0809', rpt:6},{id:'0810', rpt:6},
                     {id:'0901', rpt:12},{id:'0902', rpt:12},{id:'0906', rpt:6},{id:'0907', rpt:8},{id:'0908', rpt:2},{id:'0909', rpt:5},{id:'0910', rpt:5},{id:'1003', rpt:4},{id:'1005', rpt:4},{id:'1007', rpt:3},{id:'1009', rpt:3},{id:'1010', rpt:7}
                    ]},
    // e   4-58  5-11  6-49  7-100  8-60  9-50  10-21  = 349
    
            {ltr:',', exerc:
                    [{id:'0405', rpt:25},{id:'0406', rpt:7},{id:'0408', rpt:3},{id:'0409', rpt:2},{id:'0410', rpt:1}]},
    // ,    4-38    = 38
    
            {ltr:'.', exerc:
                    [{id:'0405', rpt:25},{id:'0406', rpt:5},{id:'0408', rpt:2},{id:'0409', rpt:3},{id:'0410', rpt:1}]},
     // .    4-36   = 36
    
            {ltr:'x', exerc:
                    [{id:'0407', rpt:25},{id:'0408', rpt:3},{id:'0409', rpt:2},{id:'0410', rpt:4}]},
    // x     4-34  7-10  10-1  = 45
    
            {ltr:'c', exerc:
                    [{id:'0407', rpt:25},{id:'0408', rpt:10},{id:'0409', rpt:2},{id:'0410', rpt:1},{id:'0502', rpt:1},{id:'0509', rpt:3},
                     {id:'0602', rpt:6},{id:'0603', rpt:6},{id:'0607', rpt:4},{id:'0609', rpt:2},{id:'0610', rpt:2},{id:'0802', rpt:6},{id:'0803', rpt:6},{id:'0804', rpt:12},
                     {id:'0806', rpt:6},{id:'0807', rpt:4},{id:'0808', rpt:6},{id:'0809', rpt:5},{id:'0810', rpt:5},{id:'0903', rpt:6},{id:'0907', rpt:2},{id:'0909', rpt:1},
                     {id:'0910', rpt:1},{id:'1003', rpt:4},{id:'1005', rpt:2},{id:'1007', rpt:3},{id:'1009', rpt:2},{id:'1010', rpt:2}
                    ]},
    // c     4-38  5-4  6-20  8-50  9-10  10-13  = 135
    
            {ltr:'z', exerc:
                    [{id:'0501', rpt:24},{id:'0502', rpt:7},{id:'0504', rpt:2},{id:'0505', rpt:8},{id:'0506', rpt:5},{id:'0507', rpt:2},{id:'0508', rpt:6},{id:'0510', rpt:9},
                    {id:'0801', rpt:6},{id:'0807', rpt:2},{id:'0809', rpt:1},{id:'0810', rpt:1},{id:'1005', rpt:1},{id:'1009', rpt:1}]},
    // z     5-63  8-10  10-2  = 75
    
            {ltr:'q', exerc:
                    [{id:'0501', rpt:26},{id:'0502', rpt:4},{id:'0504', rpt:1},{id:'0505', rpt:8},{id:'0506', rpt:5},{id:'0507', rpt:2},{id:'0508', rpt:7},{id:'0510', rpt:7},
                    {id:'0802', rpt:6},{id:'0807', rpt:2},{id:'0809', rpt:1},{id:'0810', rpt:1},{id:'1005', rpt:1},{id:'1010', rpt:1}
                    ]},
    // q     5-60  8-10  10-2  = 72
    
            {ltr:'p', exerc:
                    [{id:'0503', rpt:25},{id:'0504', rpt:13},{id:'0505', rpt:6},{id:'0506', rpt:4},{id:'0507', rpt:1},{id:'0508', rpt:5},{id:'0509', rpt:6},{id:'0510', rpt:9},
                     {id:'0605', rpt:6},{id:'0606', rpt:6},{id:'0608', rpt:4},{id:'0609', rpt:2},{id:'0610', rpt:2},{id:'0701', rpt:12},{id:'0707', rpt:4},{id:'0709', rpt:2},{id:'0710', rpt:2},
                     {id:'0804', rpt:6},{id:'0806', rpt:6},{id:'0808', rpt:4},{id:'0809', rpt:2},{id:'0810', rpt:2},{id:'1003', rpt:1},{id:'1007', rpt:3}
                    ]},
    // p     5-69  6-20  7-20  8-20  10-4  = 133
    
            {ltr:'[', exerc:
                    [{id:'0503', rpt:25},{id:'0504', rpt:4},{id:'0505', rpt:6},{id:'0506', rpt:11},{id:'0507', rpt:4},{id:'0508', rpt:8},{id:'0509', rpt:2},{id:'0510', rpt:5},{id:'1010', rpt:1}]},
    // [    5-65  10-1  = 66
    
            {ltr:']', exerc:
                    [{id:'0505', rpt:21},{id:'0506', rpt:13},{id:'0507', rpt:11},{id:'0508', rpt:7},{id:'0509', rpt:2},{id:'0510', rpt:7},{id:'1010', rpt:1}]},
    // ]   5-61  10-1  = 62
    
            {ltr:'/', exerc:
                    [{id:'0507', rpt:9},{id:'0508', rpt:4},{id:'0509', rpt:3},{id:'0510', rpt:5}]},
    // /    5-21    = 21
    
            {ltr:'\'', exerc:
                    [{id:'0507', rpt:20},{id:'0508', rpt:5},{id:'0509', rpt:2},{id:'0510', rpt:8},{id:'1007', rpt:2}]},
    // \    5-35  10-2  = 37 
        
     ]


 //  {id:301, rpt:},{id:302, rpt:},{id:303, rpt:},{id:304, rpt:},{id:305, rpt:},{id:306, rpt:},{id:307, rpt:},{id:308, rpt:},{id:309, rpt:},{id:310, rpt:}
 //   {id:401, rpt:},{id:402, rpt:},{id:403, rpt:},{id:404, rpt:},{id:405, rpt:},{id:406, rpt:},{id:407, rpt:},{id:408, rpt:},{id:409, rpt:},{id:410, rpt:}
 //  {id:501, rpt:},{id:502, rpt:},{id:503, rpt:},{id:504, rpt:},{id:505, rpt:},{id:506, rpt:},{id:507, rpt:},{id:508, rpt:},{id:509, rpt:},{id:510, rpt:}
 //  {id:601, rpt:},{id:602, rpt:},{id:603, rpt:},{id:604, rpt:},{id:605, rpt:},{id:606, rpt:},{id:607, rpt:},{id:608, rpt:},{id:609, rpt:},{id:610, rpt:}
 //  {id:701, rpt:},{id:702, rpt:},{id:703, rpt:},{id:704, rpt:},{id:705, rpt:},{id:706, rpt:},{id:707, rpt:},{id:708, rpt:},{id:709, rpt:},{id:710, rpt:}
 //  {id:801, rpt:},{id:802, rpt:},{id:803, rpt:},{id:804, rpt:},{id:805, rpt:},{id:806, rpt:},{id:807, rpt:},{id:808, rpt:},{id:809, rpt:},{id:810, rpt:}
 //  {id:901, rpt:},{id:902, rpt:},{id:903, rpt:},{id:904, rpt:},{id:905, rpt:},{id:906, rpt:},{id:907, rpt:},{id:908, rpt:},{id:909, rpt:},{id:910, rpt:}
 //  {id:1001, rpt:},{id:1002, rpt:},{id:1003, rpt:},{id:1004, rpt:},{id:1005, rpt:},{id:1006, rpt:},{id:1007, rpt:},{id:1008, rpt:},{id:1009, rpt:},{id:1010, rpt:}