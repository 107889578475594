import React from 'react';
import {  useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectUserById } from '../../Redux/features/users/usersApiSlice';



const User = ({ userId }) => { 
    const user = useSelector(state => selectUserById(state, userId))
    const navigate =  useNavigate()

    if(user) {
        const handleEdit = () => navigate(`/users/${userId}`)

        const userRolesString = user.roles.toString().replaceAll(',', ', ')  
        
        return (
            <tr>
                <td>
                    {user.username}
                </td>
                <td>
                    {userRolesString}
                </td>
                <td>
                    {user.email}
                </td>
                <td>
                    {user.id}
                </td>
                <td>
                    <button onClick={handleEdit}>
                        Edit User
                    </button>
                </td>
            </tr>
        )

    } else return null

    
}

export default User