import React, { 
     useState, 
     useEffect, 
     useContext 
   } from 'react';
import { useNavigate } from "react-router-dom";
import PostRptExrcNotification from '../views/PostRptExrcNotification';
import { ExercContext } from '../Context/ExercContext';
import { handleStarsCount } from '../Functions';
import { playSound } from '../Components/PlaySound';
import sound from '../assets/bells_old.mp3';
import fanfars from '../assets/fanfars.mp3';
import { 
  lessonHeader, 
  exerciseDesc, 
  lessonHeaderEN, 
  exerciseDescEN,
  lessonHeaderAR, 
  exerciseDescAR 
} from '../models/Tasks';
import { findRptExerc,  checkLangForMsg} from '../Functions';
import useCompareRes from '../Hooks/useCompareRes';
import { toggleRpt } from '../Redux/rptSlice'
import  { 
  useSelector,
  useDispatch 
} from 'react-redux'



const RptExercEndController = () => {

const dispatch = useDispatch()
const navigate = useNavigate();
const keyBoardLayOut = useSelector((state) => state.layout.layout)
const triger = useSelector((state) => state.triger.triger)
const volumeOn = useSelector((state) => state.sound.soundOn)
const { 
          mistakesCount, setMistakesCount,
          starsCount, setStarsCount,
          setCursPos,
          typesPerMinute,                  
      }  = useContext(ExercContext);

localStorage.setItem('tpm', typesPerMinute);
const [trigerErrorMsg, setTrigerErrorMsg] = useState(false);
// eslint-disable-next-line
const [desc, setDesc] = useState(exerciseDesc);
// eslint-disable-next-line
const [header, setHeader] = useState(exerciseDesc);

// useEffect(() => {
//   dispatch(toggleRpt(exerToRepQu))
//   console.log('fm RptexrEndContr l.56  -', exerToRepQu)
// // eslint-disable-next-line
// },[exerToRepQu])
useEffect(() => { 
  const rpt = true;
  checkLangForMsg(
              triger, setDesc, setHeader, dispatch, 
              exerciseDescEN, setTrigerErrorMsg, lessonHeaderEN,
              exerciseDesc, lessonHeader, exerciseDescAR,
              lessonHeaderAR, trigerErrorMsg, rpt, 
              ); 
              // eslint-disable-next-line   
}, [triger, trigerErrorMsg]);


const lessonCount = localStorage.getItem('rptLsn');
const lessonResults = JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lessonCount}Res`));
//const rptExrc = localStorage.getItem('rptExrc');
const rptLsnExrc = localStorage.getItem('rptLsnExrc');
const allLessonsRes = JSON.parse(localStorage.getItem(`${keyBoardLayOut}AllLessonsRes`));

//////////////////////////////////////////////////////
const prevRes = JSON.parse(localStorage.getItem('olderResultsToEdit'));
// localStorage.setItem('formerBestResult', JSON.stringify(lessonResults[rptExrc - 1]));

// useEffect(() => {
//  const test = JSON.parse(localStorage.getItem('formerBestResult'));
//  console.log(test);
// },[]);

////////////////////////////////////////////////////

//console.log(lessonResults, rptExrc, rptLsnExrc);
const [betterRes, setBetterRes] = useState(false);
///////////////////////////////////////////////////////////////////
/// Розраховуємо та записуємо NoTypos
const totalNoTypos = localStorage.getItem('totalNoTypos') ?  localStorage.getItem('totalNoTypos') : 0;
const [nTsEarned, setNTsEarned] = useState(0)
const [totalNTs, setTotalNTs] = useState(+totalNoTypos)
const standardNTsPerExerc = 60;
// Plays sound. Also, counts NTs
useEffect(() => {
  if(typesPerMinute >= 50 && typesPerMinute <= 100) {
    setNTsEarned(standardNTsPerExerc)   
  } else if(typesPerMinute > 100 && typesPerMinute <= 199) {
    setNTsEarned(standardNTsPerExerc * 1.5)
  } else if(typesPerMinute >= 200) {
    setNTsEarned(standardNTsPerExerc * 2)
  } else {
    setNTsEarned(standardNTsPerExerc * 0.5)
  }
if(+mistakesCount === 0 && typesPerMinute > 50) {
  volumeOn && playSound(fanfars);
} else {
  volumeOn && playSound(sound);
} 
// eslint-disable-next-line
}, []);
useEffect(() => {
  setTotalNTs(prev => prev + nTsEarned) 
 // eslint-disable-next-line
 },[nTsEarned]);


/////////////////////////////////////////////////////////////////

///// Встановлює кількість Зірочок
useEffect(() => {
    handleStarsCount(mistakesCount, setStarsCount);
  // eslint-disable-next-line
  }, [mistakesCount]);
///// Оцінює чи збільшилась кількість зірочок у зрівнянні з найбільшим результатом
  useEffect(() => {
    //console.log('fm RptExercEndCont', 'tpm - ', typesPerMinute)     
    if(+starsCount > +prevRes.stars 
      || +typesPerMinute > +prevRes.tpm
       ) {        
       setBetterRes(true);
// lessonResults - contins exerc res for this lesson
        const dataToSave = {id: rptLsnExrc, stars: starsCount, tpm: typesPerMinute};
        //const editedData = [...lessonResults.slice(0, rptExrc - 1), dataToSave, ...lessonResults.slice(rptExrc,  lessonResults.length)];
        let recordsFiltered = lessonResults.filter(obj => obj.id !== rptLsnExrc);
        let newDataToSave = [...recordsFiltered, dataToSave].sort((a, b) => a.id.localeCompare(b.id)); 
        localStorage.setItem(`${keyBoardLayOut}Lesson${lessonCount}Res`, JSON.stringify(newDataToSave));
    
        let allRecordsFiltered = allLessonsRes.filter(obj => obj.id !== rptLsnExrc);
        let newDataToSaveToAllLes = [...allRecordsFiltered, dataToSave].sort((a, b) => a.id.localeCompare(b.id));
        localStorage.setItem(`${keyBoardLayOut}AllLessonsRes`, JSON.stringify(newDataToSaveToAllLes));
//console.log("newDataToSave-", newDataToSave, 'newDataToSaveToAllLes-', newDataToSaveToAllLes)
        findRptExerc(keyBoardLayOut, newDataToSaveToAllLes, dispatch, toggleRpt);       
     } else { 
       setBetterRes(false);
     } 
     // eslint-disable-next-line    
  },[starsCount]);


const handleContinue = () => {   
    setCursPos(0);
    setMistakesCount(0);

    let data = +totalNoTypos + +nTsEarned; 
    localStorage.setItem('totalNoTypos', data);  

    navigate('/all_lessons');                 
};
  
const handleRptExrc = () => {
    setCursPos(0);
    setMistakesCount(0);

    let data = +totalNoTypos + +nTsEarned; 
    localStorage.setItem('totalNoTypos', data);   
 
    navigate('/exercise_rpt');
};


///////////////////////////////////////////////////////////// 
  const keyFunction = (event) => {    
    if(event.key === 'Enter') {           
        handleContinue();       
    } 
 };
  useEffect(() => {
      document.addEventListener('keydown', keyFunction);  
    return () => {
      document.removeEventListener('keydown', keyFunction);
    }        
// eslint-disable-next-line 
}, []);


const [compare, setCompare] = useState(false);

useEffect(() => {
    if(+mistakesCount === 0 && typesPerMinute > 50) {
      volumeOn && playSound(fanfars);
    } else {
      volumeOn && playSound(sound);
    } 
    setCompare(!compare);
    // eslint-disable-next-line
    }, []);
///////////////////////////////////////////////////////////////////
const newRes = {stars: starsCount, tpm: typesPerMinute};
const message = useCompareRes(prevRes, newRes, compare);
//console.log(message)


  return (
      <PostRptExrcNotification 
        typesPerMin={typesPerMinute}
        mistakesCount={mistakesCount}
        starsCount={starsCount} 
        handleRptExrc={handleRptExrc}
        handleContinue={handleContinue}
        betterRes={betterRes}
        prevRes={prevRes}
        msg={message.message}

        nTsEarned={nTsEarned}
        totalNTs={totalNTs}
      />
  )
}

export default RptExercEndController