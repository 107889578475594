
import c from '../views/training_screen.module.css';
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';


const LessonDescription = ( {lesson, exercise, desc, header} ) => {


  return (
    <div>     
     <span className={c.lesson_header_one}>    
     <FormattedMessage id='superPower' defaultMessage={defineMessages.superPower} /> {lesson}     
    </span><br/><br/> 
      <div className={c.note_notice_txt_container}>
          <span className={c.note_notice_txt}>{header[lesson - 1]}</span>
      </div>
      <br/><br/> 
     <span className={c.lesson_header_two}> 
       <FormattedMessage id='exerc' defaultMessage={defineMessages.exerc} />  
       <span> {exercise}</span> 
     </span>
     <br/><br/> 
    
    <div className={c.note_notice_txt_container}>
      <div className={` ${c.note_notice_txt}`}>
        {desc[lesson - 1][exercise - 1][0]} {desc[lesson - 1][exercise - 1][1]} <br/>       
      </div> 
     </div>
     <span className={c.lesson_header_two}> {desc[lesson - 1][exercise - 1][2]}</span>


        
    </div>
  )
}

export default LessonDescription