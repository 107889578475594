import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pageHeader: 'Achieve Your Super Power'
}

export const pageHeaderSlice = createSlice({
    name: 'pageHeader',
    initialState,
    reducers: {
        togglePageHeader: (state, action) => {
            state.pageHeader = action.payload;
          // localStorage.setItem('bg', action.payload);
        },
        // update: (state, action) => {
        //     state.soundOff = action.payload.sound;
        //    // localStorage.setItem('soundOff', action.payload.sound);
        // },
    },
});
 
export const { togglePageHeader } = pageHeaderSlice.actions;
export default pageHeaderSlice.reducer;