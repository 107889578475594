import { Form, FormControl } from 'react-bootstrap';
import c from './login.module.css'
import { IconEyeOpen, IconEyeShut, IconMagic, IconShieldFillCheck } from '../Icons'
import { motion } from 'framer-motion/dist/framer-motion';
import { generatePwd, validatePwd, handleRptPwdInput, 
         handleRptPwdOnBlure, onPwdChange,
       } from '../../Functions';

const NewUserScreen2 = (props) => {

    const { 
        showPwd, setShowPwd, setPassword, setPwdValid,
        password, setRptPwdChecked, pwdValid, rptPassword,
        setPwdMutch, pwdMutch, rptPwdChecked, setRptPassword,
        
    } = props;

  return (
    <motion.div
        key='screen_2'
        animate={{x:"0%", y: "0%", opacity: 1, scale: 1}}
        initial={{ x:"-100%", y: "0%", opacity: 0, scale: 1}}
        exit={{x:"100%", y: "0%", transition: { duration: 0.8}}}
        transition={{ duration: 0.8, ease: "easeOut"}}
        className={c.screen_box}
     >
        <Form.Group className={` ${c.form_group}`}>
                <Form.Label className={c.lable_txt}>
                  User Password
                </Form.Label>
                <div className={`${c.rel_div}`}>
                    <div className={c.abs_div_ext} onClick={() => setShowPwd(prev => !prev)}>
                      {showPwd ? IconEyeShut(22, '#213b44') : IconEyeOpen(22, '#213b44') }
                    </div>
                    <div className={c.abs_div_G} onClick={() => generatePwd(setPassword, setPwdValid)}>
                      <abbr title='Generate Random Password' onClick={() => setShowPwd(true)}>
                      {IconMagic(22, '#213b44')}
                      </abbr>
                    </div>
                    <FormControl 
                        placeholder="password" 
                        name="password"                 
                        value={password} 
                        type={`${showPwd ? "text" : "password"}`}
                        className={c.form_input}
                        onChange={(event) => {onPwdChange(
                                                event, setPassword,  
                                                setRptPwdChecked, pwdValid, rptPassword,
                                                password, setPwdMutch
                                            )}}
                        onBlur={() => validatePwd(password, setPwdValid, pwdMutch,
                                                  password, rptPassword, setPwdMutch,
                                                  setRptPwdChecked, pwdValid
                                                  )}
                        required
                    />
                </div>
                 <Form.Label className={`${c.lable_lower_txt} ${pwdValid ? '' : c.alert_notice}`}>
                   min.8 symb. lower + upper case + numbers
                 </Form.Label>                
            </Form.Group> 
           
            <Form.Group className={`${c.form_group}`}>
                <Form.Label className={c.lable_txt}>
                  Repeat Password
                </Form.Label>
                <div className={`${c.rel_div}`}>
                  <div className={c.abs_div_ext} onClick={() => setShowPwd(prev => !prev)}>
                    {showPwd ? IconEyeShut(22, '#213b44') : IconEyeOpen(22, '#213b44') }
                  </div>
                   {rptPwdChecked ? ( 
                     <div className={`${c.abs_div_G} ${c.no_border}`}>
                      <abbr title='Passwords are mutching and secure'>
                       {IconShieldFillCheck(22, '#213b44')}
                      </abbr>
                     </div>) : ('')
                    }                   
                  <FormControl 
                    placeholder="repeat password" 
                    name="rptPassword"                 
                    value={rptPassword} 
                    type={`${showPwd ? "text" : "password"}`}
                    className={c.form_input}
                    onChange={(event) => handleRptPwdInput(event, setRptPassword, pwdMutch, 
                                                           pwdValid, setRptPwdChecked, rptPwdChecked, 
                                                           password, setPwdMutch
                                                           )}
                    onBlur={() => handleRptPwdOnBlure(password, rptPassword, setPwdMutch)}
                    required
                  />
                </div>
               {!pwdMutch &&
                 <Form.Label className={`${c.lable_lower_txt} ${c.alert_notice}`}>
                   Passwords are not mutching
                 </Form.Label> }
            </Form.Group>

    </motion.div>
  )
}

export default NewUserScreen2