import { Form, FormControl } from 'react-bootstrap';
import c from './login.module.css'
import { motion } from 'framer-motion/dist/framer-motion';
import { IconEyeOpen, IconEyeShut } from '../Icons'

const LoginScreen1 = (props) => {

    const { password, setPassword, email, setEmail, showPwd, setShowPwd } = props;

  return (
    <motion.div
        key='screen_1'
        animate={{x:"0%", y: "0%", opacity: 1, scale: 1}}
        initial={{ x:"-100%", y: "0%", opacity: 0, scale: 1}}
        exit={{x:"100%", y: "0%", transition: { duration: 0.8}}}
        transition={{ duration: 0.8, ease: "easeOut"}}
        className={c.screen_box}
    >
         <Form.Group className={c.form_group}>
                <Form.Label className={c.lable_txt}>
                User email
                </Form.Label>
                <FormControl 
                    placeholder="email" 
                    name="email"
                    value={email} 
                    type="email"
                    className={c.form_input}                  
                    onChange={(event) => {setEmail(event.target.value)}}
                />
            </Form.Group>

            <Form.Group className={`${c.rel_div} ${c.form_group}`}>
                <div className={c.abs_div} onClick={() => setShowPwd(prev => !prev)}>
                    {showPwd ? IconEyeShut(25, '#21130d') : IconEyeOpen(25, '#21130d') }
                </div>
                <Form.Label className={c.lable_txt}>
                  User Password
                </Form.Label>
                <FormControl 
                    placeholder="password" 
                    name="password"                 
                    value={password} 
                    type={`${showPwd ? "text" : "password"}`}
                    className={c.form_input}
                    onChange={(event) => {setPassword(event.target.value)}}
                    required
                /> 
            </Form.Group>
    </motion.div>
  )
}

export default LoginScreen1