import React, { useState, useEffect } from 'react'
import ScreenCard from '../Cards/ScreenCard'
import { Button } from 'react-bootstrap';
import c from './login.module.css'
import { ArrowRightIcon } from '../Icons'
import { motion } from 'framer-motion/dist/framer-motion';
import { useNavigate } from "react-router-dom";
import LoginScreen1 from './LoginScreen1';
import NewUserScreen3 from './NewUserScreen3';
import LoginScreen3 from './LoginScreen3';

import  { useDispatch } from 'react-redux'
import { setCredentials } from '../../Redux/features/auth/authSlice';
import { useLoginMutation } from '../../Redux/features/auth/authApiSlice';
//import usePersist from '../../Hooks/usePersist'
import jwtDecode from 'jwt-decode'


const Login = () => {

    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('');
    const [showPwd, setShowPwd] = useState(false)
    const [noCaptcha, setNoCaptcha] = useState(true)
    const [submitBlocked, setSubmitBlocked] = useState(true)
    const [screenSwitch, setScreenSwitch] = useState(1)
    const [disableNext, setDisableNext] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [rcvdUserName, setRcvdUserName] = useState('')
    //const [persist, setPersist] = usePersist()
   

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [login, { isLoading }] = useLoginMutation()

    useEffect(() => {
        if(password && email && ! noCaptcha) {
            setSubmitBlocked(false)
        } else {
            setSubmitBlocked(true)
        }
        // eslint-disable-next-line 
     },[password, email, noCaptcha])

     useEffect(() => {
        setDisableNext(false) 
        if(screenSwitch === 1) {
            password?.length < 8 && setDisableNext(true)
            email === '' && setDisableNext(true)
        } 
        // eslint-disable-next-line 
     },[screenSwitch, email, password])

    //  useEffect(() => {      
    //     if(!login) {
    //        console.log('no login -', login)
    //     } else {
    //         console.log('login -', login)
    //     }
    //     // eslint-disable-next-line 
    //  },[login])

   
     useEffect(() => {
        if(rcvdUserName) {
         setTimeout(() => {
                navigate('/')
                }, 8000)
        }   
 // eslint-disable-next-line 
      },[rcvdUserName])




     const handleFormSubmit = async () => {// Add actions to pass data to the backend
        setNoCaptcha(true)
        setSubmitBlocked(true)
        setScreenSwitch(prev => prev + 1)
       
        try {             
           const { accessToken } = await login({ email, password }).unwrap()           
           dispatch(setCredentials({ accessToken })) 
           setPassword('')
           setEmail('')
         const decoded = jwtDecode(accessToken)        
         const { username } = decoded.UserInfo
         setRcvdUserName(username)
            console.log(decoded)
       
        } catch (error) {
            //console.log(error, error.message, error.data?.message)
            if(!error.status) {
                setErrMsg('No Server Response')
            } else if(error.status === 401) {
                setErrMsg(error.data?.message ? error.data?.message : 'Wrong Username or Password')
            } else if(error.status === 400) {
                setErrMsg('Missing Username or Password')
            } else {
                setErrMsg(error.data?.message) 
            }         
        }
        // Later, Clear input fields when New User Created Confirmation will be rcvd
     }

  return (
    <ScreenCard >
        <div className={c.login_wrapper}>
            <div className={c.header_txt}>
                Login into your account
            </div>
         <div className={c.inputs_section}>
     {screenSwitch === 1 &&
           <LoginScreen1 
             password={password}
             setPassword={setPassword}
             email={email}
             setEmail={setEmail}
             showPwd={showPwd}
             setShowPwd={setShowPwd}             
           /> 
     }
     {screenSwitch === 2 &&
           <NewUserScreen3 
             setNoCaptcha={setNoCaptcha}
           />
      }

      {screenSwitch === 3 &&
           <LoginScreen3 
             errMsg={errMsg}
             isLoading={isLoading}
             username={rcvdUserName}
           />
       }

            <div className={c.btn_next_prev}>
            {screenSwitch === 1 &&
               <Button 
                    variant='success' 
                    className={`${c.login_btn} ${c.btn_prev}`}
                    disabled={disableNext}
                    onClick={() => setScreenSwitch(prev => prev + 1)}
                >
                    Next
                </Button> 
            }

    
     
   

             {screenSwitch === 2 &&
             <>
              <motion.div 
                className={c.btn_box} 
                key='screen_1'
                animate={{x:"0%", y: "0%", opacity: 1, scale: 1}}
                initial={{ x:"-120%", y: "0%",  scale: 1}}
                exit={{x:"100%", y: "0%"}}
                transition={{ duration: 0.1 }}
               >
          
                 <Button 
                     variant='outline-light' 
                     className={`${c.login_btn} ${c.btn_prev}`}
                     disabled={screenSwitch === 1}
                     onClick={() => setScreenSwitch(prev => prev - 1)}
                 >
                     Previous
                 </Button>
            
               </motion.div> 
               <Button variant='success' 
                   className={c.login_btn} 
                   disabled={submitBlocked}
                   onClick={handleFormSubmit}
               >
                Login
               </Button> 
             </> 
            }
            </div>
        </div>

     
       
    

        <div className={c.horiz_line} />

         <div className={c.login_lowerblock}>
             <div>
                <div className={c.lable_txt}>
                 Forgot Your Password?
                </div>
                <motion.div
                    className={c.link}
                    whileHover={{
                    originX: 0,
                    scale: 1.03,
                    textShadow: '0px 0px 2px rgb(255,255,255)',
                    color: '#f8e112',                        
                    }}
                    onClick={() => navigate('/users/restore_password')}
                    transition={{ type: 'spring', stiffness: 300 }}
                >
                    Restore the Password  <span className='ml-3'>{ArrowRightIcon()}</span>
                </motion.div>

             </div>

             <div>
                 <div className={c.lable_txt}>
                  Do not have an account yet?
                </div>
                <motion.div
                    className={c.link}
                    whileHover={{
                    originX: 0,
                    scale: 1.03,
                    textShadow: '0px 0px 2px rgb(255,255,255)',
                    color: '#f8e112',                        
                    }}
                    onClick={() => navigate('/new_user')}
                    transition={{ type: 'spring', stiffness: 300 }}
                >
                    Register a new User  <span className='ml-3'>{ArrowRightIcon()}</span>
                </motion.div>
                <div className={`${c.link} ${c.pdg_btm}`}>
                    Why to Register?
                </div>
             </div>
            
           
          </div> 

     </div>
     
    </ScreenCard>
  
  )
}

export default Login