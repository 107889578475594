import {useState, useEffect} from 'react';
import c from './use_bg.module.css';
import  { useSelector } from 'react-redux'


const useBg = () => {

const bg = useSelector((state) => state.bg.bg)
const [oliveBg, setOliveBg] = useState(c.bg_olive);
const [greenBtn, setGreenBtn] = useState(c.bg_green);
const [blueBtn, setBlueBtn] = useState(c.bg_blue);
const [rozeBtn, setRozeBtn] = useState(c.bg_roze);
const [orangeBtn, setOrangeBtn] = useState(c.bg_orange);
const [yellowBtn, setYellowBtn] = useState(c.bg_yellow);

const [inputBg, setInputBg] = useState(c.training_input_bg);
const [vertLineBg, setVertLineBg] =useState(c.shade_scr_vert_line_bg1);
const [activClass, setActivClass] = useState(c.bg_1);
const [ yellowTxt, setYellowTxt] = useState('#ffffff00');
const [ whiteTxt, setWhiteTxt] = useState(c.white_txt);
const [ iconColr, setIconColr] = useState('var(--wh-text)');
const [ starColr, setStarColr] = useState('var(--yellow-txt)');
 

useEffect(() => {
    if(bg === '1') {
     setBlueBtn(c.bg_blue);
     setRozeBtn(c.bg_roze);
     setOrangeBtn(c.bg_orange);
     setYellowBtn(c.bg_yellow);
     setGreenBtn(c.bg_green);
     setOliveBg(c.bg_olive);

     setInputBg(c.training_input_bg);

     setVertLineBg(c.shade_scr_vert_line_bg1);

     setActivClass(c.bg_1);
     setYellowTxt(c.yellow_txt);
     setWhiteTxt(c.white_txt);
     setIconColr('var(--wh-text)');
     setStarColr('var(--yellow-txt)');

    } else if(bg === '2') {
     setBlueBtn(c.bg_blue2);
     setRozeBtn(c.bg_roze2);
     setOrangeBtn(c.bg_orange2);
     setYellowBtn(c.bg_yellow2);
     setGreenBtn(c.bg_green);
     setOliveBg(c.bg_olive);

     setInputBg(c.training_input_bg);

     setVertLineBg(c.shade_scr_vert_line_bg2);

     setActivClass(c.bg_2);
     setYellowTxt(c.yellow_txt);
     setWhiteTxt(c.white_txt);
     setIconColr('var(--wh-text)');
     setStarColr('var(--yellow-txt)');
    } else if(bg === '3') {
     setBlueBtn(c.bg_blue3);
     setRozeBtn(c.bg_roze3);
     setOrangeBtn(c.bg_orange3);
     setYellowBtn(c.bg_yellow3);
     setGreenBtn(c.bg_green3);
     setOliveBg(c.bg_olive3);

     setInputBg(c.training_input_bg3);
     
     setVertLineBg(c.shade_scr_vert_line_bg3);

     setActivClass(c.bg_3);
     setYellowTxt(c.yellow_txt);
     setWhiteTxt(c.white_txt);
     setIconColr('var(--wh-text)');
     setStarColr('var(--yellow-txt)');
    }  else if(bg === '4') {
        setBlueBtn(c.bg_blue4);
        setRozeBtn(c.bg_roze4);
        setOrangeBtn(c.bg_orange4);
        setYellowBtn(c.bg_yellow4);
        setGreenBtn(c.bg_green4);
        setOliveBg(c.bg_olive4);
   
        setInputBg(c.training_input_bg3); 

        setVertLineBg(c.shade_scr_vert_line_bg4);

        setActivClass(c.bg_4);
        setYellowTxt(c.yellow_txt);
        setWhiteTxt(c.white_txt);
        setIconColr('var(--wh-text)');
        setStarColr('var(--yellow-txt)');
       } else if(bg === '5') {
        setBlueBtn(c.bg_blue3);
        setRozeBtn(c.bg_roze3);
        setOrangeBtn(c.bg_orange3);
        setYellowBtn(c.bg_yellow3);
        setGreenBtn(c.bg_green3);
        setOliveBg(c.bg_olive5);
   
        setInputBg(c.training_input_bg3); 

        setVertLineBg(c.shade_scr_vert_line_bg5);

        setActivClass(c.bg_5);
        setYellowTxt(c.yellow_txt);
        setWhiteTxt(c.white_txt);
        setIconColr('var(--wh-text)');
        setStarColr('var(--yellow-txt)');
       } else if(bg === '6') {
        setBlueBtn(c.bg_blue6);
        setRozeBtn(c.bg_roze6);
        setOrangeBtn(c.bg_orange6);
        setYellowBtn(c.bg_yellow6);
        setGreenBtn(c.bg_green6);
        setOliveBg(c.bg_olive6);
   
        setInputBg(c.training_input_bg); 

        setVertLineBg(c.shade_scr_vert_line_bg6);

        setActivClass(c.bg_6);
        setYellowTxt(c.yellow_txt);
        setWhiteTxt(c.white_txt);
        setIconColr('var(--wh-text)');
        setStarColr('var(--yellow-txt)');
       } else if(bg === '7') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg7);
        setActivClass(c.bg_7);
        setYellowTxt(c.yellow_txt);
        setWhiteTxt(c.white_txt);
        setIconColr('var(--wh-text)');
        setStarColr('var(--yellow-txt)');
       } else if(bg === '8') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg8);
        setActivClass(c.bg_8);
        setYellowTxt(c.yellow_txt);
        setWhiteTxt(c.white_txt);
        setIconColr('var(--wh-text)');
        setStarColr('var(--yellow-txt)');
       }  else if(bg === '9') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg9);
        setActivClass(c.bg_9);
        setYellowTxt(c.yellow_txt);
        setWhiteTxt(c.white_txt);
        setIconColr('var(--wh-text)');
        setStarColr('var(--yellow-txt)');
       }  else if(bg === '10') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg10);
        setActivClass(c.bg_10);
        setYellowTxt(c.blue_txt);
        setWhiteTxt(c.navy_txt);
        setIconColr('navy');
        setStarColr('var(--yellow-txt)');
        setStarColr('#ff006a');
       }  else if(bg === '11') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg11);
        setActivClass(c.bg_11);

        setYellowTxt(c.blue_txt);
        setWhiteTxt(c.navy_txt);
        setIconColr('navy');
        setStarColr('#c53069');

       }  else if(bg === '12') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg12);
        setActivClass(c.bg_12);
        setYellowTxt(c.dred_txt);
        setWhiteTxt(c.navy_txt);
        setIconColr('navy');
        setStarColr('#ff006a');
       }  else if(bg === '13') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg13);
        setActivClass(c.bg_13);
        setYellowTxt(c.dred_txt);
        setWhiteTxt(c.navy_txt);
        setIconColr('navy');
        setStarColr('#ff006a');
       }  else if(bg === '14') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg14);
        setActivClass(c.bg_14);
        setYellowTxt(c.yellow_txt);
        setWhiteTxt(c.white_txt);
        setIconColr('var(--wh-text)');
        setStarColr('var(--yellow-txt)');
       }  else if(bg === '15') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg15);
        setActivClass(c.bg_15);
        setYellowTxt(c.yellow_txt);
        setWhiteTxt(c.white_txt);
        setIconColr('var(--wh-text)');
        setStarColr('var(--yellow-txt)');
       }  else if(bg === '16') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg16);
        setActivClass(c.bg_16);
        setYellowTxt(c.yellow_txt);
        setWhiteTxt(c.white_txt);
        setIconColr('var(--wh-text)');
        setStarColr('var(--yellow-txt)');
       }  else if(bg === '17') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg17);
        setActivClass(c.bg_17);
        setYellowTxt(c.yellow_txt);
        setWhiteTxt(c.white_txt);
        setIconColr('var(--wh-text)');
        setStarColr('var(--yellow-txt)');
       }  else if(bg === '18') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg18);
        setActivClass(c.bg_18);
        setYellowTxt(c.yellow_txt);
        setWhiteTxt(c.white_txt);
        setIconColr('var(--wh-text)');
        setStarColr('var(--yellow-txt)');
       }  else if(bg === '19') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg19);
        setActivClass(c.bg_19);
        setYellowTxt(c.yellow_txt);
        setWhiteTxt(c.white_txt);
        setIconColr('var(--wh-text)');
        setStarColr('var(--yellow-txt)');
       }  else if(bg === '20') {
        setBlueBtn(c.bg_blue);
        setRozeBtn(c.bg_roze);
        setOrangeBtn(c.bg_orange);
        setYellowBtn(c.bg_yellow);
        setGreenBtn(c.bg_green);
        setOliveBg(c.bg_olive);
   
        setInputBg(c.training_input_bg);
        setVertLineBg(c.shade_scr_vert_line_bg20);
        setActivClass(c.bg_20);
        setYellowTxt(c.dred_txt);
        setWhiteTxt(c.white);
        setIconColr('var(--wh-text)');
        setStarColr('#ff006a');
       }  
   },[bg]);


    return {
        oliveBg, 
        greenBtn, 
        blueBtn,
        rozeBtn, 
        orangeBtn, 
        yellowBtn,

        inputBg,

        vertLineBg,

        activClass,
        yellowTxt,
        whiteTxt,
        iconColr,
        starColr,
    };
};
export default useBg;