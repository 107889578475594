import { createSlice } from "@reduxjs/toolkit";


const exrsToRe = JSON.parse(localStorage.getItem(`LatexercToRe`));
//console.log(exrsToRe, exrsToRe.length, 'rptSlice')
const initialState = {
    rpt: exrsToRe?.length || 0
}
 
export const rptSlice = createSlice({
    name: 'rpt',
    initialState,
    reducers: {
        increment: (state) => {
            state.rpt += 1;
        },
        decrement: (state) => {
            state.rpt -= 1;
        },
        toggleRpt: (state, action) => {
           state.rpt = action.payload;          
        },
       
        // update: (state, action) => {
        //     state.soundOff = action.payload.sound;
        //    // localStorage.setItem('soundOff', action.payload.sound);
        // },
    },
});
  
export const { increment, decrement, toggleRpt } = rptSlice.actions;
export default rptSlice.reducer;

// Connect state to the Store!!!