import React, { 
                useState, 
                useEffect, 
                useContext 
              } from 'react';
import { useNavigate } from "react-router-dom";
import PostLessonNotification from '../views/PostLessonNotification';
import { ExercContext } from '../Context/ExercContext';
import { 
         handleStarsCount,  
         handleLessExercId, 
         changePageHeader, 
         findRptExerc,
        } from '../Functions';
 
import { playSound } from '../Components/PlaySound';
import sound from '../assets/bells_old.mp3';
import fanfars from '../assets/fanfars.mp3';
import { toggleRpt } from '../Redux/rptSlice'
import  { useSelector, useDispatch } from 'react-redux'


const ExercEndController = () => {
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const volumeOn = useSelector((state) => state.sound.soundOn)

  const { 
          mistakesCount, setMistakesCount,
          starsCount, setStarsCount,
          setCursPos,
          typesPerMinute, 
          lessonCount, setLessonCount,
          exerciseCount, setExerciseCount,
          setLessonStarted,
          lessonExercPassed, setLessonExercPassed,
          lesson,
          //numberOfExerc,
        }  = useContext(ExercContext);

const triger = useSelector((state) => state.triger.triger)
const keyBoardLayOut = useSelector((state) => state.layout.layout)
///////////////////////////////////////////////////////////////////
/// Розраховуємо та записуємо NoTypos
// const totalNoTypos = localStorage.getItem('totalNoTypos') ? localStorage.getItem('totalNoTypos') : 0;
// const [noTyposEarned, setNoTyposEarned] = useState(0);
// const totalTypes = localStorage.getItem('exrsLenght');

//  useEffect(() => {
//   let data = (+totalTypes - +mistakesCount) * (+typesPerMinute / 100); 
//   setNoTyposEarned(data.toFixed(0));  
//  },[totalTypes, mistakesCount, typesPerMinute]);


/////////////////////////////////////////////////////////////////

  useEffect(() => {
    handleStarsCount(mistakesCount, setStarsCount);
  // eslint-disable-next-line
  }, []);

  const keyFunction = (event) => {    
    if(event.key === 'Enter') {           
        handleContinue();       
    } 
 };
 ////////////////////////////////////
  useEffect(() => {
      document.addEventListener('keydown', keyFunction);  
    return () => {
      document.removeEventListener('keydown', keyFunction);
    }        
// eslint-disable-next-line 
}, []);

/////////////////// NT's Count ///////////////////////
const totalNoTypos = localStorage.getItem('totalNoTypos') ?  localStorage.getItem('totalNoTypos') : 0;
const [nTsEarned, setNTsEarned] = useState(0)
const [totalNTs, setTotalNTs] = useState(+totalNoTypos)
const standardNTsPerExerc = 60;
// Plays sound. Also, counts NTs
useEffect(() => {
  if(typesPerMinute >= 50 && typesPerMinute <= 100) {
    setNTsEarned(standardNTsPerExerc)   
  } else if(typesPerMinute > 100 && typesPerMinute <= 199) {
    setNTsEarned(standardNTsPerExerc * 1.5)
  } else if(typesPerMinute >= 200) {
    setNTsEarned(standardNTsPerExerc * 2)
  } else {
    setNTsEarned(standardNTsPerExerc * 0.5)
  }
if(+mistakesCount === 0 && typesPerMinute > 50) {
  volumeOn && playSound(fanfars);
} else {
  volumeOn && playSound(sound);
} 
// eslint-disable-next-line
}, []);
useEffect(() => {
  setTotalNTs(prev => prev + nTsEarned)  
 // eslint-disable-next-line
 },[nTsEarned]);


// const lessonResults = localStorage.getItem(`${keyBoardLayOut}Lesson${lessonCount}Res`) 
//                                             ? 
//                                             JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lessonCount}Res`)) 
//                                             : 
//                                              [];


//const [newResultsData, setNewResultsData] = useState('');
///////////////// Фільтруємо старі данні, та Записуємо нові. Переходимо до нового уроку


  const handleContinue = () => {     
       setCursPos(0);

    let nTsData = +totalNoTypos + +nTsEarned; 
    localStorage.setItem('totalNoTypos', nTsData); 

    if(+exerciseCount < 9) { 
          if(+lessonExercPassed < parseInt(`${lessonCount}0${+exerciseCount + 1}`)) {      
            localStorage.setItem('lessonExcercPassed', `${lessonCount}0${+exerciseCount + 1}`);
            setLessonExercPassed(`${lessonCount}0${+exerciseCount + 1}`);
          }
      } else if(+exerciseCount === 10) {  
          localStorage.setItem('lessonExcercPassed', `${+lessonCount + 1}01`);
          setLessonExercPassed(`${+lessonCount + 1}01`);
      } else {        
          if(+lessonExercPassed < parseInt(`${lessonCount}${+exerciseCount + 1}`)) {     
            localStorage.setItem('lessonExcercPassed', `${lessonCount}${+exerciseCount + 1}`);
            setLessonExercPassed(`${lessonCount}${+exerciseCount + 1}`);          
          }    
      }  
  
    if(+exerciseCount === +lesson[+lessonCount - 1].length) {   
      setExerciseCount(1);
      localStorage.setItem('exerciseCount', 1);
      localStorage.setItem('lessonComplete', 'no');
      setLessonCount(prev => +prev + 1);
      localStorage.setItem('lessonCount', +lessonCount + 1);   
    } else {   
      setExerciseCount(prev => +prev + 1);
      localStorage.setItem('exerciseCount', +exerciseCount + 1);
      localStorage.setItem('lessonComplete', 'no');
    }
      setMistakesCount(0);
      localStorage.setItem('mistakeCounter', 0);

///////////////////////////////////////// Saving Exerc data to Local Storage //////////////
      let data = {id: handleLessExercId(lessonCount, exerciseCount), stars: starsCount, tpm: typesPerMinute,};   
////////////// Retreiving data fm the Local Storage
     let existingLocStorData = JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lessonCount}Res`));//Reg each Lesson
     let existingAllLessonsData = JSON.parse(localStorage.getItem(`${keyBoardLayOut}AllLessonsRes`)); // Reg All lessons data
  
          let checkAgainst = +data.id?.slice(0, 1) * 100 + 99; 
         ////////////// Sort and Filter Exerc data. Preparing for saving 
          let lessonRecordsFiltered = existingLocStorData?.filter(obj => obj.id !== data.id)                        
                              .filter(obj => +obj.id < +checkAgainst)
                              .filter(obj => +obj.id > +checkAgainst-100);
          let allLessonsRecordsFiltered = existingAllLessonsData?.filter(obj => obj.id !== data.id)                        
                              // .filter(obj => +obj.id < +checkAgainst)
                              // .filter(obj => +obj.id > +checkAgainst-100);               
         //////////////////////////////////////////////////////////////////////////////

    if(!existingLocStorData) { // Case of first Exers in the Lesson
           localStorage.setItem(`${keyBoardLayOut}Lesson${lessonCount}Res`, JSON.stringify([data]));
        if(!allLessonsRecordsFiltered) { /// Case of first exercise (101)
           localStorage.setItem(`${keyBoardLayOut}AllLessonsRes`, JSON.stringify([data]));
        } else {  
             let newDataToSave = [...allLessonsRecordsFiltered, data]
                             .sort((a, b) => a.id.localeCompare(b.id));  
             localStorage.setItem(`${keyBoardLayOut}AllLessonsRes`, JSON.stringify(newDataToSave));
          ////////////////////// Let's find exersises with low results   
             findRptExerc(keyBoardLayOut,  allLessonsRecordsFiltered, dispatch, toggleRpt);
            
        }
      navigate('/training');
     } else {        
        let newLessonDataToSave = [...lessonRecordsFiltered, data]
                           .sort((a, b) => a.id.localeCompare(b.id));
        let newTotalDataToSave = [...allLessonsRecordsFiltered, data]
                           .sort((a, b) => a.id.localeCompare(b.id));
     
        localStorage.setItem(`${keyBoardLayOut}Lesson${lessonCount}Res`, JSON.stringify(newLessonDataToSave)); 
        localStorage.setItem(`${keyBoardLayOut}AllLessonsRes`, JSON.stringify(newTotalDataToSave));

        findRptExerc(keyBoardLayOut, allLessonsRecordsFiltered, dispatch, toggleRpt);
       
      navigate('/training');
     }
  };


  const handleRptExrc = () => {
    setCursPos(0);
    setMistakesCount(0);
    setLessonStarted(true); 
    localStorage.setItem('lessonComplete', 'no'); 
    
    let data = +totalNoTypos + +nTsEarned; 
    localStorage.setItem('totalNoTypos', data); 

    navigate('/training');  
  };
  //
  
  useEffect(() => {  
    changePageHeader(triger, dispatch, 'Your Result', 'Твій Результат', 'Your Result')
    // eslint-disable-next-line
  }, [triger]);

  return ( 
    <>   
      <PostLessonNotification
        typesPerMin={typesPerMinute}
        mistakesCount={mistakesCount}
        starsCount={starsCount} 
        handleRptExrc={handleRptExrc}
        handleContinue={handleContinue} 
        nTsEarned={nTsEarned}
        totalNTs={totalNTs}
      />
    </>
  )
}

export default ExercEndController