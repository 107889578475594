import _ from "lodash";
import { togglePageHeader } from '../Redux/pageHeaderSlice'

import s from '../views/css/graph.module.css'
import {        
  WinkIcon,
  UpSideDownIcon,
  DoingGreatIcon,
  BrowsUpIcon,
  DizzyIcon,
  StillIcon,
      } from '../Components/Icons';

import { nanoid } from '@reduxjs/toolkit';

export const handleStarsCount = (mistakesCount, setStarsCount) => {
  //console.log('mistakesCount', mistakesCount)
    if(+mistakesCount === 0) {
        setStarsCount(5);
        localStorage.setItem('starsCount', 5);
      } else if(+mistakesCount === 1) {
        setStarsCount(4);
        localStorage.setItem('starsCount', 4);
      } else if(+mistakesCount === 2) {
        setStarsCount(3);
         localStorage.setItem('starsCount', 3);
      } else if(+mistakesCount === 3) {
        setStarsCount(2);
         localStorage.setItem('starsCount', 2);
      } else if(+mistakesCount === 4) {
        setStarsCount(1);
         localStorage.setItem('starsCount', 1);
      } else if(+mistakesCount > 4) {
        setStarsCount(0);
         localStorage.setItem('starsCount', 0);
      }
};

export const handleTPM = (rawData, setTypesPerMinute) => {
  localStorage.removeItem('tpm');
  let data = rawData.toFixed(0);
  setTypesPerMinute(data);
  localStorage.setItem('tpm', data);
//console.log('tpm fm function -', data);
};

export const handleLessExercId = (lessonCount, exerciseCount) => {
   let lessExercId = null;
    if(+exerciseCount < 10) {
        lessExercId = `${lessonCount}0${+exerciseCount}`;
      } else {
        lessExercId = `${lessonCount}${+exerciseCount}`;
      }
 return lessExercId;
};

export const handleGrandData = (lessonResults, data) => {
   
    if(lessonResults === []) {
        let grandData = data;
        return grandData;
      } else if(lessonResults?.length > 0) {
        let grandData = lessonResults.push(data);
        return grandData;
      }
};

export const calculateStars = arg => {
    let result = _.sum(
       arg.map(item => ( +item.stars )) );/////////////////////// Суммировать св-во sum в массиве выбранных объектов 
   return result;
 };




 export const calculateTPM = arg => {
    let result = _.sum(
       arg.map(item => ( +item.tpm )) );/////////////////////// Суммировать св-во sum в массиве выбранных объектов 
    
       return +result / +arg.length;
 };

 export const findUnder4Stars = items => {
    let data = items.filter(function(item) {
        return +item.stars < 4 }); 
    let result = data?.map(i => i.id.slice(1, 3)) ;      
     return result;
 };
 
////////////////////////////////////////////
//Used in RptExercEndController.js (l.126) and ExercEndController.js (l.158,171)
export const findRptExerc = (data, existingAllLessonsData, dispatch, toggleRpt) => {  
    
  if(existingAllLessonsData?.length > 4) {
    let tpmSum = _.sum(existingAllLessonsData.map(item => ( +item.tpm )) );/////////////////////// Суммировать св-во sum в массиве выбранных объектов 
    let meanTpm = Math.round(+tpmSum / +existingAllLessonsData.length)     
    //console.log('meanTpm -', meanTpm)
    let limitTpm = Math.round(+meanTpm - (+meanTpm / 3))
   // console.log('limitTpm -', limitTpm)
    let exercToRe = existingAllLessonsData
                .filter(function(item) {return +item.tpm < limitTpm || +item.stars < 3});
  
    localStorage.setItem(`${data}exercToRe`, JSON.stringify(exercToRe));

    dispatch(toggleRpt(+exercToRe.length))   //Updates Redux state for number of exers to Redo
  }
}
/////////////////////////////////////////////////////////////////////


///////// Let's calculate number of true/false inputs (used in TrainingScreen.js (l.107))
export const saveToTotalRec = (keyBoardLayOut, ltrsRes) => {
  //let allLtrs = ltrsTrueFalse.map(item => item.ltr)
 const existingData = JSON.parse(localStorage.getItem(`${keyBoardLayOut}TotalRecords`)) || [];
 const combined = [...existingData, ...ltrsRes];
 
    const newArray = combined.reduce((acc, item) => {
      const existing = acc.find(i => i.ltr === item.ltr);
      if (existing) {
        existing.t += item.t;
        existing.f += item.f;
      } else {
        acc.push({...item});
      }
      return acc;
     }, []);

 const dataToSave = newArray.sort((a, b) => a.ltr.localeCompare(b.ltr));
 localStorage.setItem(`${keyBoardLayOut}TotalRecords`, JSON.stringify(dataToSave));
 //console.log("dataToSave-", dataToSave)
}
/////////////////////////////////////////////////////////////////////

export const handleStarsDesc = (item) => {
    if(+item === 0) {
        return 'зірочок';
     } else if(+item === 1) {
        return 'зірочка';
     } else if(+item > 1) {
        return 'зірочки';
     }
};

export function getStars (lesNum, exNum, keyBoardLayOut) {
 // console.log(lesNum, exNum, keyBoardLayOut);
  if(JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lesNum}Res`))) {
    let data = JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lesNum}Res`));
     //console.log(data, exNum, data[exNum].stars);
     if(data[exNum]) {
      let stars = {stars: data[exNum].stars, tpm: data[exNum].tpm};    
      return stars;
     } else {return}
     
  } else {return ;} 
};

export function checkLang (key, charCode, timeCount, setTimeCount, setTrigerErrorMsg, cursPos) {
  if (     
    /^[a-zA-Z!@#$%^&*{}:;"'`~(),.?<>0-9]$/.test(key) ||
        charCode === 8 ||    
        charCode === 13 || 
        charCode === 16 || 
        charCode === 17 || 
        charCode === 32 ||
        charCode === 187 ||
        charCode === 189 ||
        charCode === 191 ||
        charCode === 192 ||
        charCode === 219 ||
        charCode === 220 ||
        charCode === 221 || 
        charCode === 223 
      ) {
    //console.log('English language mode');      
    if(!timeCount && cursPos !== 0) {setTimeCount(true);}      
  } else {      
     setTimeCount(false);    
     setTrigerErrorMsg(true);
  }
};

export function checkLangForMsg (
               triger, setDesc, setHeader, dispatch,
               exerciseDescEN, setTrigerErrorMsg, lessonHeaderEN,
               exerciseDesc, lessonHeader, exerciseDescAR,
               lessonHeaderAR, trigerErrorMsg, rpt, lessonStarted
               ) {
  if (triger === 'en') {
    setDesc(exerciseDescEN);
    setHeader(lessonHeaderEN);
    if (rpt === true) {
      dispatch(togglePageHeader('Repete the Exercise'));
    } else {
      lessonStarted ?
      dispatch(togglePageHeader('Train Your Super Power')) :
      dispatch(togglePageHeader('Get Ready To Train!'));
    }    
  } else if (triger === 'ua') {
    setDesc(exerciseDesc);
    setHeader(lessonHeader);    
    if (rpt === true) {
      dispatch(togglePageHeader('Повторюємо Вправу'));
    } else {
      lessonStarted ?
      dispatch(togglePageHeader('Тренеруй нову Супер Здібність')) :
      dispatch(togglePageHeader('Приготуйся до тренерування!'));
    } 
  }  else if (triger === 'ar') {
    setDesc(exerciseDescAR);
    setHeader(lessonHeaderAR);    
    if (rpt === true) {
      dispatch(togglePageHeader('Repete the Exercise'));
    } else {
      dispatch(togglePageHeader('تدريب'));
    } 
  }  
  if (trigerErrorMsg && triger === 'ar') {
    alert('الرجاء التحويل للغه الانجليزيه');
    setTrigerErrorMsg(false);
  } else if (trigerErrorMsg && triger === 'ua') {
    alert('Будь ласка, перейдіть на Англійську розкладку клавіатури');
    setTrigerErrorMsg(false);
  } else if (trigerErrorMsg && triger === 'en') {
    alert('Please switch to the English keyboard layout');
    setTrigerErrorMsg(false);
  }
}

export const handleUnPause = (setPaused, cursPos, setTimeCount) => {  
  setPaused(false);
  cursPos > 0 && setTimeCount(true);
};

export function changePageHeader ( triger, dispatch, 
                      messageEn, messageUa, messageAr) {
  if (triger === 'en') {
  dispatch(togglePageHeader(messageEn))
  } else if (triger === 'ua') {
  dispatch(togglePageHeader(messageUa));
  } else {
  dispatch(togglePageHeader(messageAr)) 
 } 
}

export function changeLessonDescription ( triger, setDesc, setHeader,
                           exerciseDescEN, lessonHeaderEN, exerciseDesc, 
                           lessonHeader, exerciseDescAR, lessonHeaderAR ) {
    if (triger === 'en') { 
      setDesc(exerciseDescEN);
      setHeader(lessonHeaderEN);
   } else if (triger === 'ua') {
      setDesc(exerciseDesc);
      setHeader(lessonHeader);
   }  else if (triger === 'ar') {
       setDesc(exerciseDescAR);
       setHeader(lessonHeaderAR);
   } 
}
//////////////// Create Account Validation ////////////
export function validateName(name, setNameValid) {
  name?.length > 4 && /[a-zA-Z]/.test(name) ? 
  setNameValid(true) : setNameValid(false);
 }


export function onPwdChange(e, setPassword,                            
                            setRptPwdChecked,  pwdValid, rptPassword,
                            password, setPwdMutch
                           ) {
  setPassword(e.target.value)
  // if(rptPwdChecked) {
  //   setRptPwdChecked(e.target.value === rptPassword)
  // } else {
  //   setRptPwdChecked(rptPwdConfirmed(pwdMutch, pwdValid))
  // }
  if(rptPassword !== '') {
    pwdMutching(password, rptPassword, setPwdMutch)
    setRptPwdChecked(rptPwdConfirmed(checkPwdMutch(e.target.value, rptPassword), pwdValid))
  }
}

export function validatePwd(pwd, setPwdValid, pwdMutch, password, rptPassword, setPwdMutch, setRptPwdChecked, pwdValid) {
 pwd?.length > 7 && /[a-z]/.test(pwd) && /[A-Z]/.test(pwd) &&
 /[0-9]/.test(pwd) ? setPwdValid(true) : setPwdValid(false);
 if(!pwdMutch) {
  handleRptPwdOnBlure(password, rptPassword, setPwdMutch)
 }
 if(rptPassword !== '') {
  pwdMutching(password, rptPassword, setPwdMutch)
  setRptPwdChecked(rptPwdConfirmed(checkPwdMutch(pwd, rptPassword), pwdValid))
} else {
  setPwdMutch(true)
  setRptPwdChecked(false)
}
}

export function pwdMutching(pwd, rptPwd, setPwdMutch) {
  pwd === rptPwd ? setPwdMutch(true) : setPwdMutch(false);
 }
function checkPwdMutch(pwd, rptPwd) {
  if(pwd === rptPwd) {
    return true
  } else {return false}  
}

export function isSubmitAllowed(name, nameValid, email, pwd, rptPassword, pwdValid, pwdMutch, setAllowSubmit) {
  if(name !== '' && email !== '' & pwd !== '' && rptPassword !== '' && nameValid && pwdValid && pwdMutch) {
    setAllowSubmit(false)
  } else { setAllowSubmit(true)}  
}

export function generatePwd(setPassword, setPwdValid) {
  const randomPwd = nanoid().slice(0, 10)
  if(/[a-z]/.test(randomPwd) && /[A-Z]/.test(randomPwd) && /[0-9]/.test(randomPwd)) {
    setPassword(randomPwd)
    setPwdValid(true)
  } else {generatePwd(setPassword, setPwdValid)} 
   
}

export function handleRptPwdInput(e, setRptPassword, pwdMutch, pwdValid, setRptPwdChecked, rptPwdChecked, password, setPwdMutch) {
  setRptPassword(e.target.value)
  setRptPwdChecked(rptPwdConfirmed(checkPwdMutch(password, e.target.value), pwdValid)) 
  if(rptPwdChecked) {
    setRptPwdChecked(false)
  } else {
    setRptPwdChecked(rptPwdConfirmed(pwdMutch, pwdValid))
  } 
  if(e.target.value === '') {
    setPwdMutch(true)
  }
}

export function rptPwdConfirmed(pwdMutch, pwdValid) {
  if(pwdMutch && pwdValid) return true;
  if(!pwdMutch || !pwdValid) return false
  //pwdMutch && pwdValid ? setRptPwdChecked(true) : setRptPwdChecked(false)
}



export function handleRptPwdOnBlure(password, rptPassword, setPwdMutch) {
  pwdMutching(password, rptPassword, setPwdMutch)
  //setRptPwdChecked(rptPwdConfirmed(pwdMutch, pwdValid)) 
  // if(rptPwdChecked) {
  //   setRptPwdChecked(false)
  // } else {
  //   setRptPwdChecked(rptPwdConfirmed(pwdMutch, pwdValid))
  // }  
}
//////////////////////////////////////////////////////

export const createNewUser = (user, setServerRes) => {
  //console.log('front', user)
  
    fetch(process.env.REACT_APP_USERS_DOMAIN, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  })
  .then(res => setServerRes(res.status))
    .then(response => response.json())
    .then(data => {
      console.log('Response from server:', data);
    })
    .catch(error => {
      console.error('Error:', error, error.message);
      setServerRes(error.message)
    });
}

export const resetPassword = (email, setServerRes) => {  
  
   fetch(process.env.REACT_APP_USERS_DOMAIN, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(email)
  })
  .then(res => setServerRes(res.status))
    .then(response => response.json())
    .then(data => {
      console.log('Response from server:', data);
    })
    .catch(error => {
      console.error('Error:', error, error.message);
      setServerRes(error.message)
    });
}

export const sendMail = (msg, setSentWithSuccess) => {
  console.log(msg, process.env.REACT_APP_MAIL_DOMAIN, JSON.stringify(msg))
  // console.log('front', msg)
  // console.log(process.env.REACT_APP_MAIL_DOMAIN)

  fetch(process.env.REACT_APP_MAIL_DOMAIN, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(msg)
  })
    .then(res => {res.status === 201 ? setSentWithSuccess(true) : setSentWithSuccess(false)})
    .then(response => response.json())
    .then(data => {
      console.log('Response from server:', data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
}



export const recommend = (ltrT, ltrF) => {
  let res = +(100 - (+ltrF / (+ltrT + +ltrF))*100).toFixed(1)
  //console.log(res, typeof res)
  if(+res === 100) {
   return (
       <div className={s.reactions}>
           <div className={s.face}>
             {DoingGreatIcon(26, 'greenyellow') }
           </div>
           <div className={`${s.description} ${s.green}`}>
               You have nailed it!
           </div>
       </div>        
   )
  } else if(+res > 89.9 && +res < 100) {
   return (
       <div className={s.reactions}>
           <div className={s.face}>
             {WinkIcon(26, 'rgb(226, 219, 85)')}
           </div>
           <div className={`${s.description} ${s.orange}`}>
           It's good, but <br/>let's training more for perfection
           </div>
       </div>      
   )
  } else if(+res > 69.9 && +res < 90) {
   return (
       <div className={s.reactions}>
           <div className={s.face}>
             { UpSideDownIcon(26, 'rgb(255, 159, 99)')}
           </div>
           <div className={`${s.description} ${s.yellow}`}>
            Let's training some more for better
           </div>
       </div>      
   )
  } else if(+res > 59.9 && +res < 70) {
   return (
       <div className={s.reactions}>
           <div className={s.face}>
             { BrowsUpIcon(26, 'rgb(235, 20, 255)')}
           </div>
           <div className={`${s.description} ${s.traintrain}`}>
           Train! Train! And train again!
           </div>
       </div>      
   )
  } else if(+res > 39.9 && +res < 60) {
   return (
       <div className={s.reactions}>
           <div className={s.face}>
             { DizzyIcon(26, 'deeppink')}
           </div>
           <div className={`${s.description} ${s.serious}`}>
            <b>Some serious training is required here</b>
           </div>
       </div>      
   )
  } else if(+res < 40) {
   return (
       <div className={s.reactions}>
           <div className={s.face}>
             { StillIcon()}
           </div>
           <div className={`${s.description} `}>
            Ok, I've got it. <br/> You are doing this in purpose, right?
           </div>
       </div>      
   )
  }
}