import React, { useState, useEffect } from "react";
import { useGetUsersQuery } from "../../Redux/features/users/usersApiSlice";
import User from "./User";

const AllUsersController = () => {
  const [content, setContent] = useState(<p>Loading...</p>);

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUsersQuery("User", {
    //Lables up useGetUsersQuery hook
    pollingInterval: 600000, // 10min in mil.sec
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isLoading) setContent(<p>Loading...</p>);

    if (isError) {
      setContent(
        <div>
          <p>{error?.data?.message}</p>
          <b className="pt-5">Sorry, this page is still under development</b>
        </div>
       

      );
    }

    if (isSuccess) {
      const { ids } = users;

      const tableContent = ids?.length
        ? ids.map((userId) => <User key={userId} userId={userId} />)
        : null;

      setContent(
        <table>
          <thead>
            <tr>
              <th>User Name</th>
              <th>Roles</th>
              <th>Email</th>
              <th>User ID</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>{tableContent}</tbody>
        </table>
      );
    }
    // eslint-disable-next-line
  }, [isLoading, isError, isSuccess]);

  console.log(content);
  return <div>{content}</div>;
};

export default AllUsersController;
