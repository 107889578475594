import { Form, FormControl } from 'react-bootstrap';
import c from './login.module.css'
import { motion } from 'framer-motion/dist/framer-motion';

const NewUserScreen1 = (props) => {

 const { name, setName, setNameValid, nameValid, email, setEmail, validateName} = props;

  return (
    <motion.div
        key='screen_1'
        animate={{x:"0%", y: "0%", opacity: 1, scale: 1}}
        initial={{ x:"-100%", y: "0%", opacity: 0, scale: 1}}
        exit={{x:"100%", y: "0%", transition: { duration: 0.8}}}
        transition={{ duration: 0.8, ease: "easeOut"}}
        className={c.screen_box}
    >
        <Form.Group className={c.form_group}>
                <Form.Label className={c.lable_txt}>
                User Name
                </Form.Label>
                <FormControl 
                    placeholder="User Name" 
                    name="name"
                    value={name} 
                    type="name"
                    autoComplete='off'
                    className={c.form_input}
                    onChange={(event) => {setName(event.target.value)}}
                    onBlur={() => validateName(name, setNameValid)}
                />
                <Form.Label className={`${c.lable_lower_txt} ${nameValid ? '' : c.alert_notice}`}>
                 min. 5 symb., Latin alphabet
                </Form.Label>
            </Form.Group>
          
            <Form.Group className={c.form_group}>
                <Form.Label className={c.lable_txt}>
                User email
                </Form.Label>
                <FormControl 
                    placeholder="email" 
                    name="email"
                    value={email} 
                    type="email"
                    autoComplete='off'
                    className={c.form_input}
                    onChange={(event) => {setEmail(event.target.value)}}
                />
            </Form.Group> 
    </motion.div>
  )
}

export default NewUserScreen1